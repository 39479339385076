import { IHttpApi } from '@cian/http-api/shared/http';
import {
  fetchDeactivateUserCalltracking,
  IDeactivateUserCalltrackingRequest,
  IDeactivateUserCalltrackingResponse,
} from '../repositories/calltracking-core/v1/deactivate-user-calltracking';
import { fetchGetUsers, IGetUsersRequest, IGetUsersResponse } from '../repositories/calltracking-core/v1/get-users';
import {
  fetchActivateUserCalltracking,
  IActivateUserCalltrackingRequest,
  IActivateUserCalltrackingResponse,
} from '../repositories/calltracking-core/v2/activate-user-calltracking';

import { isResponseContainsKey } from '../utils/response';
import { IServerError } from './types';

export async function getCalltrackingUsers(httpApi: IHttpApi, parameters: IGetUsersRequest) {
  try {
    let { response } = await fetchGetUsers({ httpApi, parameters });

    // Иногда с сервера приходит json с заголовком Content-Type: text/html
    if (typeof response === 'string') {
      try {
        response = JSON.parse(response || '[]'); // Если в ответ пришел не json, выведем ответ как ошибку
      } catch (e) {
        throw [{ code: 'notJson', key: '', message: ((response as unknown) as string).substring(0, 300) }];
      }
    }

    if (isResponseContainsKey<IServerError, IGetUsersResponse>(response, 'errors')) {
      throw response.errors;
    }

    // TS 2.5.3 не учитывает проверку выше и непрваильно определяет тип
    return (response as IGetUsersResponse).users;
  } catch (error) {
    throw Array.isArray(error) ? error : [{ code: 'exception', key: '', message: error }];
  }
}

export async function requestCalltrackingConnect(httpApi: IHttpApi, parameters: IActivateUserCalltrackingRequest) {
  try {
    let { response } = await fetchActivateUserCalltracking({ httpApi, parameters });

    // Иногда с сервера приходит json с заголовком Content-Type: text/html
    if (typeof response === 'string') {
      try {
        response = JSON.parse(response || '[]'); // Если в ответ пришел не json, выведем ответ как ошибку
      } catch (e) {
        throw [{ code: 'notJson', key: '', message: (response as string).substring(0, 300) }];
      }
    }

    if (isResponseContainsKey<IServerError, IActivateUserCalltrackingResponse>(response, 'errors')) {
      throw response.errors;
    }

    // TS 2.5.3 не учитывает проверку выше и непрваильно определяет тип
    return response as IActivateUserCalltrackingResponse;
  } catch (error) {
    throw Array.isArray(error) ? error : [{ code: 'exception', key: '', message: error }];
  }
}

export async function requestCalltrackingDisconnect(httpApi: IHttpApi, parameters: IDeactivateUserCalltrackingRequest) {
  try {
    let { response } = await fetchDeactivateUserCalltracking({ httpApi, parameters });

    // Иногда с сервера приходит json с заголовком Content-Type: text/html
    if (typeof response === 'string') {
      try {
        response = JSON.parse(response || '[]'); // Если в ответ пришел не json, выведем ответ как ошибку
      } catch (e) {
        throw [{ code: 'notJson', key: '', message: (response as string).substring(0, 300) }];
      }
    }

    if (isResponseContainsKey<IServerError, IDeactivateUserCalltrackingResponse>(response, 'errors')) {
      throw response.errors;
    }

    // TS 2.5.3 не учитывает проверку выше и непрваильно определяет тип
    return response as IDeactivateUserCalltrackingResponse;
  } catch (error) {
    throw Array.isArray(error) ? error : [{ code: 'exception', key: '', message: error }];
  }
}
