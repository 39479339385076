import * as React from 'react';

import { mergeStyles } from '@cian/utils';
import * as styles from './close.css';

interface ICloseProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  size?: number;
  containerStyle?: string;
}

export class Close extends React.Component<ICloseProps, object> {
  public render() {
    const { size, onClick, containerStyle } = this.props;
    const closeSize = {
      height: size || 13,
      width: size || 13,
    };

    return (
      <div {...mergeStyles(styles['close'], containerStyle)}>
        <button className={styles['close-button']} style={closeSize} onClick={onClick} />
      </div>
    );
  }
}
