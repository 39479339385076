import { IHttpApi } from '@cian/http-api/shared/http';
import { isResponseContainsKey } from '../utils/response';
import {
  fetchSearchUsers,
  ISearchUsersRequest,
  ISearchUsersResponse,
  ISearchUsersResponseError,
} from '../repositories/calltracking-core/v1/search-users';
import {
  fetchSearchOffers,
  ISearchOffersRequest,
  ISearchOffersResponse,
  ISearchOffersResponseError,
} from '../repositories/calltracking-core/v1/search-offers';

export async function searchUsers(httpApi: IHttpApi, parameters: ISearchUsersRequest) {
  try {
    const { response } = await fetchSearchUsers({
      httpApi,
      parameters,
      config: { requestConfig: { withCredentials: true } },
    });

    if (isResponseContainsKey<ISearchUsersResponseError, ISearchUsersResponse>(response, 'errors')) {
      throw response.errors;
    }

    return response;
  } catch (error) {
    throw Array.isArray(error) ? error : [{ code: 'exception', key: '', message: error }];
  }
}

export async function searchOffers(httpApi: IHttpApi, parameters: ISearchOffersRequest, cookies?: string) {
  try {
    const { response } = await fetchSearchOffers({ httpApi, parameters });

    if (isResponseContainsKey<ISearchOffersResponseError, ISearchOffersResponse>(response, 'errors')) {
      throw response.errors;
    }

    return response;
  } catch (error) {
    throw Array.isArray(error) ? error : [{ code: 'exception', key: '', message: error }];
  }
}
