/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';
import { IResponseItemSchemaArrayWrapper } from '../../entities/get_phones_link_history/ResponseItemSchemaArrayWrapper';

export type TGetPhonesLinkHistoryModel = IModel<IGetPhonesLinkHistoryRequest, TGetPhonesLinkHistoryResponse>;

export interface IGetPhonesLinkHistoryRequest {
  /** Подменный номер **/
  calltrackingPhone?: string | null;
  /** Номер страницы **/
  page?: number | null;
  /** Размер страницы **/
  pageSize?: number | null;
}

export type TGetPhonesLinkHistoryResponse = IGetPhonesLinkHistoryResponse200 | IGetPhonesLinkHistoryResponse400;

export interface IGetPhonesLinkHistoryResponse200 extends IModelResponse<IGetPhonesLinkHistoryResponse> {
  statusCode: 200;
}

export interface IGetPhonesLinkHistoryResponse400 extends IModelResponse<IGetPhonesLinkHistoryResponseError> {
  statusCode: 400;
}

export interface IGetPhonesLinkHistoryResponse {
  /** undefined **/
  items: IResponseItemSchemaArrayWrapper[][];
  /** Провайдер **/
  provider?: EProvider | null;
  /** Номер страницы **/
  totalItems: number;
  /** Количество страниц **/
  totalPages: number;
}

export interface IGetPhonesLinkHistoryResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetPhonesLinkHistoryResponse | IGetPhonesLinkHistoryResponseError;

export enum EProvider {
  /** beeline **/
  Beeline = 'beeline',
  /** mts **/
  Mts = 'mts',
  /** mtt **/
  Mtt = 'mtt',
  /** Тестовый провайдер **/
  Qa = 'qa',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetPhonesLinkHistoryResponse): TResponse200;
  400(response: IGetPhonesLinkHistoryResponseError): TResponse400;
}
