import { IHttpApi } from '@cian/http-api/shared/http';
import { isResponseContainsKey } from '../utils/response';
import {
  fetchGetUserPhonesLinks,
  IGetUserPhonesLinksRequest,
  IGetUserPhonesLinksResponse,
  IGetUserPhonesLinksResponseError,
} from '../repositories/calltracking-core/v1/get-user-phones-links';

export async function getUserPhonesLinks(
  httpApi: IHttpApi,
  parameters: IGetUserPhonesLinksRequest,
): Promise<IGetUserPhonesLinksResponse> {
  try {
    const { response } = await fetchGetUserPhonesLinks({ httpApi, parameters });

    if (isResponseContainsKey<IGetUserPhonesLinksResponseError, IGetUserPhonesLinksResponse>(response, 'errors')) {
      throw response.errors;
    }

    return response;
  } catch (error) {
    throw Array.isArray(error) ? error : [{ code: 'exception', key: '', message: error }];
  }
}
