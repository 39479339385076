/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetCalltrackingRegionsModel,
  IGetCalltrackingRegionsRequest,
  IMappers,
  IGetCalltrackingRegionsResponse,
  IGetCalltrackingRegionsResponseError,
  TGetCalltrackingRegionsResponse,
} from './types';

const defaultConfig: TGetCalltrackingRegionsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/get-calltracking-regions/',
  hostType: 'api',
} as TGetCalltrackingRegionsModel;

function createGetCalltrackingRegionsModel(parameters: IGetCalltrackingRegionsRequest): TGetCalltrackingRegionsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetCalltrackingRegionsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetCalltrackingRegionsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetCalltrackingRegions<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetCalltrackingRegionsOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetCalltrackingRegionsResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetCalltrackingRegionsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetCalltrackingRegionsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetCalltrackingRegionsResponseError);
    }
  }

  return { response, statusCode } as TGetCalltrackingRegionsResponse;
}

export { defaultConfig, createGetCalltrackingRegionsModel, fetchGetCalltrackingRegions };
