import { IUserSchema } from '../../repositories/calltracking-core/entities/entities/UserSchema';
import { IAppState } from '../types';

export interface ICalltrackingUsersPageMeta {
  count: number;
}

export interface ICalltrackingUsersFilterParams {
  userId?: number;
  calltrackingStatus?: string;
}

export interface ICalltrackingUsersFilterCollections {
  lists?: ICalltrackingUsersFilterList[];
}

export interface ICalltrackingUsersFilterList {
  label: string;
  value: string;
}

export interface ICalltrackingUsersState {
  list: IUserSchema[];
  meta: ICalltrackingUsersPageMeta;
  filter: ICalltrackingUsersFilterParams;
}

export interface ISetCalltrackingUsersAction {
  users: IUserSchema[];
  type: 'calltracking_users/SET_CALLTRACKING_USERS';
}

export interface ISetCalltrackingUsersFilterAction {
  type: 'calltracking_users/SET_CALLTRACKING_USERS_FILTER';
  filter: ICalltrackingUsersFilterParams;
}

export interface ISetCalltrackingUsersEntitiesCountAction {
  type: 'calltracking_users/SET_ENTITIES_COUNT';
  count: string;
}

export function setCalltrackingUsers(users: IUserSchema[]): ISetCalltrackingUsersAction {
  return {
    type: 'calltracking_users/SET_CALLTRACKING_USERS',
    users,
  };
}

export function setCalltrackingUsersFilters(filter: ICalltrackingUsersFilterParams): ISetCalltrackingUsersFilterAction {
  return {
    filter,
    type: 'calltracking_users/SET_CALLTRACKING_USERS_FILTER',
  };
}

export function setCalltrackingUsersEntitiesCount(count: string): ISetCalltrackingUsersEntitiesCountAction {
  return {
    count,
    type: 'calltracking_users/SET_ENTITIES_COUNT',
  };
}

export type TCalltrackingUserActions =
  | ISetCalltrackingUsersFilterAction
  | ISetCalltrackingUsersEntitiesCountAction
  | ISetCalltrackingUsersAction;

export function calltrackingUsersReducer(state: IAppState, action: TCalltrackingUserActions) {
  switch (action.type) {
    case 'calltracking_users/SET_CALLTRACKING_USERS_FILTER':
      return {
        ...state,
        calltrackingUsers: state.calltrackingUsers && {
          ...state.calltrackingUsers,
          filter: action.filter,
        },
      };

    case 'calltracking_users/SET_ENTITIES_COUNT':
      return {
        ...state,
        calltrackingUsers: state.calltrackingUsers && {
          ...state.calltrackingUsers,
          filter: state.calltrackingUsers ? state.calltrackingUsers.filter : {},
        },
      };

    case 'calltracking_users/SET_CALLTRACKING_USERS':
      return {
        ...state,
        calltrackingUsers: state.calltrackingUsers && {
          ...state.calltrackingUsers,
          list: action.users,
        },
      };

    default:
      return state || {};
  }
}
