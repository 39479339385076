import { IAppState, IThunkExtraArgument, TActions } from '../redux';
import { blockUserPhone } from '../redux/userPhone';
import { toggleBlockModal } from '../redux/modals';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ConfirmModalWithInput } from '../components/confirm_modal/ConfirmModalWithInput';

interface IStateProps {
  phone?: string;
  show: boolean;
}

interface IDispatchProps {
  onConfirmClick(reason: string, phone: string): void;

  onCancelClick(): void;
}

export function mapStateToProps(state: IAppState): IStateProps {
  const { phone, show } = state.blockModal;

  return {
    phone,
    show,
  };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>): IDispatchProps {
  return {
    onConfirmClick: (reason: string, phone: string) => dispatch(blockUserPhone(reason, phone)),
    onCancelClick: () => dispatch(toggleBlockModal({ show: false })),
  };
}

export function mergeProps(stateProps: IStateProps, dispatchProps: IDispatchProps) {
  const { phone } = stateProps;
  const { onConfirmClick, onCancelClick } = dispatchProps;

  return {
    title: `Заблокировать оригинальный номер клиента ${phone}`,
    text:
      'Вы блокирует оригинальный номер клиента. На этот номер больше не будет выдаваться подменный номер,' +
      ' он исключается из коллтрекинга',
    inputLabel: 'Причина',
    btnConfirmText: 'Заблокировать',
    onConfirmClick: (reason: string) => {
      if (phone) {
        onConfirmClick(reason, phone);
      }
    },
    onCancelClick,
  };
}

export const BlockPhoneModalContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(ConfirmModalWithInput);
