import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../redux/types';

import {
  TActions,
  clearBlockedCalltrackingPhoneAction,
  IThunkExtraArgument,
  ISearchFilterParams,
  searchOffersAction,
} from '../redux';
import {
  CalltrackingPhoneBlockedModal,
  ICalltrackingPhoneBlockedModalProps,
} from '../components/search_offers_result_presenter/CalltrackingPhoneBlockedModal';
import { makePhone } from '../utils/search';

export interface ICalltrackingPhoneBlockedModalStateProps {
  offersFilterParams: ISearchFilterParams;
  blockedCalltrackingPhone: string | null;
}

export interface ICalltrackingPhoneBlockedModalDispatchProps {
  clearBlockedCalltrackingPhone(): void;
  searchOffers(params: ISearchFilterParams): void;
}

export function mapStateToProps(state: IAppState): ICalltrackingPhoneBlockedModalStateProps {
  const { offersFilterParams, blockedCalltrackingPhone } = state.search;

  return { offersFilterParams, blockedCalltrackingPhone };
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>,
): ICalltrackingPhoneBlockedModalDispatchProps {
  return {
    clearBlockedCalltrackingPhone: () => dispatch(clearBlockedCalltrackingPhoneAction()),
    searchOffers: (params: ISearchFilterParams) => dispatch(searchOffersAction(params)),
  };
}

export function mergeProps(
  stateProps: ICalltrackingPhoneBlockedModalStateProps,
  dispatchProps: ICalltrackingPhoneBlockedModalDispatchProps,
): ICalltrackingPhoneBlockedModalProps {
  const { offersFilterParams, blockedCalltrackingPhone } = stateProps;
  const { clearBlockedCalltrackingPhone, searchOffers } = dispatchProps;

  return {
    blockedCalltrackingPhone,
    onModalClose: () => {
      clearBlockedCalltrackingPhone();
      searchOffers({
        userId: offersFilterParams.userId || undefined,
        offerId: offersFilterParams.offerId || undefined,
        userPhone: makePhone(offersFilterParams.userPhone),
        calltrackingPhone: makePhone(offersFilterParams.calltrackingPhone),
      });
    },
  };
}

export const CalltrackingPhoneBlockedModalContainer = connect<
  ICalltrackingPhoneBlockedModalStateProps,
  ICalltrackingPhoneBlockedModalDispatchProps,
  {},
  ICalltrackingPhoneBlockedModalProps
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CalltrackingPhoneBlockedModal);
