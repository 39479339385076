/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetRealPhoneByCalltrackingPhoneModel,
  IGetRealPhoneByCalltrackingPhoneRequest,
  IMappers,
  IGetRealPhoneByCalltrackingPhoneResponse,
  IGetRealPhoneByCalltrackingPhoneResponseError,
  TGetRealPhoneByCalltrackingPhoneResponse,
} from './types';

const defaultConfig: TGetRealPhoneByCalltrackingPhoneModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/get-real-phone-by-calltracking-phone/',
  hostType: 'api',
} as TGetRealPhoneByCalltrackingPhoneModel;

function createGetRealPhoneByCalltrackingPhoneModel(
  parameters: IGetRealPhoneByCalltrackingPhoneRequest,
): TGetRealPhoneByCalltrackingPhoneModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetRealPhoneByCalltrackingPhoneOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetRealPhoneByCalltrackingPhoneRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetRealPhoneByCalltrackingPhone<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetRealPhoneByCalltrackingPhoneOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetRealPhoneByCalltrackingPhoneResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetRealPhoneByCalltrackingPhoneModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetRealPhoneByCalltrackingPhoneResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetRealPhoneByCalltrackingPhoneResponseError);
    }
  }

  return { response, statusCode } as TGetRealPhoneByCalltrackingPhoneResponse;
}

export { defaultConfig, createGetRealPhoneByCalltrackingPhoneModel, fetchGetRealPhoneByCalltrackingPhone };
