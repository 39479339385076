import * as React from 'react';

import { PhoneField } from '@cian/components';
import { Button, ButtonTheme } from '@cian/button-component';
import { NumberField } from '@cian/number-field-component';
import { ISearchFilterParams } from '../../redux/search';

import * as styles from './index.css';

export interface ISearchFilterProps {
  params: ISearchFilterParams;
  onParamsChange(params: ISearchFilterParams): void;
  onFilterApply(): void;
}

export class SearchFilter extends React.Component<ISearchFilterProps> {
  public render() {
    const { onFilterApply, params } = this.props;
    const { userId, offerId, userPhone, calltrackingPhone } = params;

    return (
      <div className={styles['container']}>
        <div className={styles['filters']}>
          <div className={styles['filter-column']}>
            <div className={styles['filter-param']}>
              <div className={styles['filter-label']}>ID пользователя</div>
              <div className={styles['filter-input']}>
                <NumberField disablePrettyFormating value={userId || null} onValueChange={this.onUserIdChange} />
              </div>
            </div>

            <div className={styles['filter-param']}>
              <div className={styles['filter-label']}>ID объявления</div>
              <div className={styles['filter-input']}>
                <NumberField disablePrettyFormating value={offerId || null} onValueChange={this.onOfferIdChange} />
              </div>
            </div>
          </div>

          <div className={styles['filter-column']}>
            <div className={styles['filter-param']}>
              <div className={styles['filter-label']}>Реальный номер</div>
              <div className={styles['filter-input']}>
                <PhoneField mobile={true} value={userPhone || ''} onValueChange={this.onRealPhoneChange} />
              </div>
            </div>

            <div className={styles['filter-param']}>
              <div className={styles['filter-label']}>Подменный номер</div>
              <div className={styles['filter-input']}>
                <PhoneField
                  mobile={true}
                  value={calltrackingPhone || ''}
                  onValueChange={this.onCalltrackingPhoneChange}
                />
              </div>
              <div className={styles['filter-buttons']}>
                <div className={styles['filter-button']}>
                  <Button theme={ButtonTheme.Default} onClick={onFilterApply}>
                    Найти
                  </Button>
                </div>

                <div className={styles['filter-button']}>
                  <Button theme={ButtonTheme.Ghost} onClick={this.onClearFiltersClick}>
                    Очистить
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  public componentDidMount() {
    // делаем это, чтобы компоненты PhoneField отрендерили свое содержимое
    this.forceUpdate();
  }

  private onClearFiltersClick = () => {
    const { onParamsChange } = this.props;

    onParamsChange({
      userId: undefined,
      offerId: undefined,
      userPhone: undefined,
      calltrackingPhone: undefined,
    });
  };

  private onUserIdChange = (userId?: number) => {
    const { onParamsChange, params } = this.props;
    const { offerId, userPhone, calltrackingPhone } = params;

    onParamsChange({ userId, offerId, userPhone, calltrackingPhone });
  };

  private onOfferIdChange = (offerId?: number) => {
    const { onParamsChange, params } = this.props;
    const { userId, userPhone, calltrackingPhone } = params;

    onParamsChange({ userId, offerId, userPhone, calltrackingPhone });
  };

  private onRealPhoneChange = (userPhone?: string) => {
    const { onParamsChange, params } = this.props;
    const { userId, offerId, calltrackingPhone } = params;

    onParamsChange({ userId, offerId, userPhone, calltrackingPhone });
  };

  private onCalltrackingPhoneChange = (calltrackingPhone?: string) => {
    const { onParamsChange, params } = this.props;
    const { userId, offerId, userPhone } = params;

    onParamsChange({ userId, offerId, userPhone, calltrackingPhone });
  };
}
