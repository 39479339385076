import {
  IBlockUserPhoneResponse,
  IBlockUserPhoneResponseError,
  IMappers,
} from '../../repositories/calltracking-core/v1/block-user-phone';

export interface IResponse400 {
  error: string;
}

export function mappers(): IMappers<IResponse400, {}, {}> {
  return {
    200: (response: IBlockUserPhoneResponse) => response,
    400: (response: IBlockUserPhoneResponseError) => ({ error: response.message }),
    204: () => ({}),
  };
}
