/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TEnableRegionModel,
  IEnableRegionRequest,
  IMappers,
  IEnableRegionResponse,
  IEnableRegionResponseError,
  TEnableRegionResponse,
} from './types';

const defaultConfig: TEnableRegionModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/enable-region/',
  hostType: 'api',
} as TEnableRegionModel;

function createEnableRegionModel(parameters: IEnableRegionRequest): TEnableRegionModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IEnableRegionOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IEnableRegionRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchEnableRegion<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IEnableRegionOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TEnableRegionResponse> {
  const { response, statusCode } = await httpApi.fetch(createEnableRegionModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IEnableRegionResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IEnableRegionResponseError);
    }
  }

  return { response, statusCode } as TEnableRegionResponse;
}

export { defaultConfig, createEnableRegionModel, fetchEnableRegion };
