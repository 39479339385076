/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetBlockedUserPhoneInfoModel,
  IGetBlockedUserPhoneInfoRequest,
  IMappers,
  IGetBlockedUserPhoneInfoResponse,
  IGetBlockedUserPhoneInfoResponseError,
  TGetBlockedUserPhoneInfoResponse,
} from './types';

const defaultConfig: TGetBlockedUserPhoneInfoModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/get-blocked-user-phone-info/',
  hostType: 'api',
} as TGetBlockedUserPhoneInfoModel;

function createGetBlockedUserPhoneInfoModel(
  parameters: IGetBlockedUserPhoneInfoRequest,
): TGetBlockedUserPhoneInfoModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetBlockedUserPhoneInfoOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetBlockedUserPhoneInfoRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetBlockedUserPhoneInfo<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetBlockedUserPhoneInfoOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetBlockedUserPhoneInfoResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetBlockedUserPhoneInfoModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetBlockedUserPhoneInfoResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetBlockedUserPhoneInfoResponseError);
    }
  }

  return { response, statusCode } as TGetBlockedUserPhoneInfoResponse;
}

export { defaultConfig, createGetBlockedUserPhoneInfoModel, fetchGetBlockedUserPhoneInfo };
