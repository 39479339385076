/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetUsersModel,
  IGetUsersRequest,
  IMappers,
  IGetUsersResponse,
  IGetUsersResponseError,
  TGetUsersResponse,
} from './types';

const defaultConfig: TGetUsersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/get-users/',
  hostType: 'api',
} as TGetUsersModel;

function createGetUsersModel(parameters: IGetUsersRequest): TGetUsersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetUsersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetUsersRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetUsers<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetUsersOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TGetUsersResponse> {
  const { response, statusCode } = await httpApi.fetch(createGetUsersModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetUsersResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetUsersResponseError);
    }
  }

  return { response, statusCode } as TGetUsersResponse;
}

export { defaultConfig, createGetUsersModel, fetchGetUsers };
