import * as React from 'react';

import { Link } from '@cian/link-component';
import { ModalWrapper, Modal } from '@cian/modal-component';
import { Button, ButtonTheme } from '@cian/button-component';
import { mergeStyles } from '@cian/utils';
import { ISearchOfferSchema } from '../../redux/search';

import { CalltrackingPhoneBlockedModalContainer } from '../../containers/calltrackingPhoneBlockedModalContainer';
import { formatNumber } from '../../utils/number';

import * as globalStyles from '../../index.css';
import * as tableStyles from '../data_table/index.css';
import * as styles from './SearchOffersResultPresenter.css';
import { ForceCalltrackingCheckbox } from '../force_calltracking_checkbox';
import { IModalParams } from '../../redux/modals';
import { BlockPhoneModalContainer } from '../../containers/blockPhoneModalContainer';
import { PhoneResultModal } from '../phone_result_modal/PhoneResultModal';
import { UserPhone } from './UserPhone';
import { CalltrackingProvider } from '../CalltrackingProvider';
import { IUserPhoneParams } from '../../redux/userPhone/types';

export interface ISearchOffersResultPresenterProps {
  showOfferForceCalltrackingCheckbox: boolean;
  offers: ISearchOfferSchema[];

  onBlockCalltrackingPhoneClick(calltrackingPhone: string): void;

  toggleBlockModal(params: IModalParams): void;

  blockModal: IModalParams;
  userPhoneBlockStatus: IUserPhoneParams;

  setBlockStatus(params: IUserPhoneParams): void;
}

export interface ISearchOffersResultPresenterState {
  isCalltrackingPhoneBlockModalOpen: boolean;
  calltrackingPhoneBlock: string;
}

export class SearchOffersResultPresenter extends React.Component<
  ISearchOffersResultPresenterProps,
  ISearchOffersResultPresenterState
> {
  public state = {
    isCalltrackingPhoneBlockModalOpen: false,
    calltrackingPhoneBlock: '',
  };

  public render() {
    const { offers, showOfferForceCalltrackingCheckbox, blockModal, userPhoneBlockStatus } = this.props;
    const { show, phone } = blockModal;
    const { error } = userPhoneBlockStatus;

    return (
      <div>
        {<CalltrackingPhoneBlockedModalContainer />}
        {show && <BlockPhoneModalContainer />}
        {!show && phone && (
          <PhoneResultModal
            text={error || 'Номер успешно заблокирован'}
            onButtonClick={this.hideUserBlockConfirmation}
          />
        )}
        {this.renderCalltrackingPhoneBlockConfirmModal()}
        <div className={tableStyles['table']}>
          <div className={tableStyles['th']}>
            <div>Объявление</div>
            <div>Клиент</div>
            <div>Номер клиента</div>
            <div>Подменный номер</div>
            {showOfferForceCalltrackingCheckbox && <div>Принудительно</div>}
          </div>
          {offers.map(
            ({
              cianUserId,
              userId,
              offerId,
              calltrackingPhone,
              provider,
              userPhone,
              offerForceCalltrackingSession,
            }) => (
              <div className={tableStyles['tr']} key={`${userId}-${offerId}`}>
                <div>{this.renderOfferLink(offerId)}</div>
                <div>{this.renderUserLink(cianUserId)}</div>
                <div>
                  <UserPhone userId={userId} toggleBlockModal={this.props.toggleBlockModal} userPhone={userPhone} />
                </div>
                <div>{this.renderCalltrackingPhone(calltrackingPhone, provider)}</div>
                {showOfferForceCalltrackingCheckbox && (
                  <ForceCalltrackingCheckbox forceCalltrackingSession={offerForceCalltrackingSession} />
                )}
              </div>
            ),
          )}
        </div>
      </div>
    );
  }

  private renderCalltrackingPhoneBlockConfirmModal = () => {
    const { isCalltrackingPhoneBlockModalOpen, calltrackingPhoneBlock } = this.state;

    if (!isCalltrackingPhoneBlockModalOpen) {
      return null;
    }

    return (
      <ModalWrapper onClose={this.handleModalClose}>
        <Modal minHeight={220} minWidth={500}>
          <div className={styles['modal-header']}>Заблокировать подменный номер {calltrackingPhoneBlock} ?</div>
          <div className={styles['modal-text']}>
            Этот подменный номер никогда больше не будет выдан никакому клиенту.
            <br />
            Вместо {calltrackingPhoneBlock} будет назначен новый подменный номер.
          </div>
          <div className={styles['modal-buttons']}>
            <Button className={styles['confirm-button']} onClick={this.handleBlockCalltrackingPhoneClick}>
              Заблокировать
            </Button>
            <Button theme={ButtonTheme.Ghost} onClick={this.handleModalClose}>
              Отменить
            </Button>
          </div>
        </Modal>
      </ModalWrapper>
    );
  };

  private handleBlockCalltrackingPhoneClick = () => {
    const { onBlockCalltrackingPhoneClick } = this.props;
    const { calltrackingPhoneBlock } = this.state;

    onBlockCalltrackingPhoneClick(calltrackingPhoneBlock);
    this.handleModalClose();
  };

  private handleModalClose = () => {
    this.setState({
      isCalltrackingPhoneBlockModalOpen: false,
      calltrackingPhoneBlock: '',
    });
  };

  private hideUserBlockConfirmation = () => {
    this.props.setBlockStatus({ error: '' });
    this.props.toggleBlockModal({ show: false });
  };

  private renderCalltrackingPhone = (calltrackingPhone?: string | null, provider?: string) => {
    if (!calltrackingPhone) {
      return null;
    }

    return (
      <span>
        <span>{this.renderCalltrackingPhoneLink(calltrackingPhone)}</span>
        <span>
          <CalltrackingProvider provider={provider} />
        </span>
        <span
          {...mergeStyles(globalStyles['link'], styles['calltracking-phone-block'])}
          onClick={() => {
            this.handleCalltrackingPhoneBlockConfirmClick(calltrackingPhone);
          }}
        >
          Заблокировать подменный номер
        </span>
      </span>
    );
  };

  private handleCalltrackingPhoneBlockConfirmClick = (calltrackingPhone: string) => {
    this.setState({
      isCalltrackingPhoneBlockModalOpen: true,
      calltrackingPhoneBlock: calltrackingPhone,
    });
  };

  private renderUserLink = (userId: number) => {
    return (
      <span>
        <Link href={`http://service.cian.ru/customers/view/${userId}`} target="_blank">
          {userId}
        </Link>
      </span>
    );
  };

  private renderOfferLink = (offerId: number) => {
    return <span>{offerId}</span>;
  };

  private renderCalltrackingPhoneLink = (phone: string) => {
    return (
      <span>
        <Link href={`/replaced_phone_history/?phone=%2B${phone.substr(-11)}`} target="_blank">
          {formatNumber(phone)}
        </Link>
      </span>
    );
  };
}
