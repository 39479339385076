import { IHttpApi } from '@cian/http-api/shared/http';
import {
  fetchGetUserForceCalltrackingActiveSession,
  IGetUserForceCalltrackingActiveSessionRequest,
  IGetUserForceCalltrackingActiveSessionResponse,
  IGetUserForceCalltrackingActiveSessionResponseError,
} from '../repositories/moderation/v1/get-user-force-calltracking-active-session';
import { isResponseContainsKey } from '../utils/response';

export async function getUserForceCalltrackingActiveSession(
  httpApi: IHttpApi,
  parameters: IGetUserForceCalltrackingActiveSessionRequest,
): Promise<IGetUserForceCalltrackingActiveSessionResponse> {
  try {
    const { response } = await fetchGetUserForceCalltrackingActiveSession({
      httpApi,
      parameters,
      config: { requestConfig: { withCredentials: true } },
    });

    if (
      isResponseContainsKey<
        IGetUserForceCalltrackingActiveSessionResponseError,
        IGetUserForceCalltrackingActiveSessionResponse
      >(response, 'errors')
    ) {
      throw response.errors;
    }

    return response;
  } catch (error) {
    throw Array.isArray(error) ? error : [{ code: 'exception', key: '', message: error }];
  }
}
