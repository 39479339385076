import * as React from 'react';

import { Link } from '@cian/link-component';

import { makeUrl } from '../../utils/search';
import { formatNumber } from '../../utils/number';
import { IPhoneSchema } from '../../redux';
import { CheckPhoneTool } from './CheckPhoneTool';

import * as tableStyles from '../data_table/index.css';
import { CalltrackingProvider } from '../CalltrackingProvider';

const PHONE_STATUSES: { [index: string]: string } = {
  deactivated: 'Связка отключена',
  deleted: 'Связка удалена',
  inSump: 'В отстойнике',
  providerOk: 'Связка зарегистрирована у оператора сотовой связи',
  published: 'Связка активна',
  readyForProvider: 'Требует регистрации у оператора сотовой связи',
};

export interface IPhonesProps {
  phones: IPhoneSchema[];
  onCheckPhoneClick(id: number, calltrackingPhone: string): void;
}

export function Phones(props: IPhonesProps) {
  const { phones, onCheckPhoneClick } = props;

  if (!phones.length) {
    return null;
  }

  return (
    <div className={tableStyles['table']}>
      <div className={tableStyles['th']}>
        <div>Номер клиента</div>
        <div>Подменный номер</div>
        <div>Объявлений</div>
        <div>Статус</div>
        <div>Переадресация</div>
      </div>

      {phones.map(
        ({
          id,
          userPhone,
          calltrackingPhone,
          provider,
          offersCount,
          status,
          isProviderRedirectPhoneRequested,
          isProviderRedirectPhoneFetching,
          isValid,
          actualPhone,
        }) => (
          <div className={tableStyles['tr']} key={`${id}`}>
            <div>{formatNumber(userPhone)}</div>
            <div>
              <Link href={`/replaced_phone_history/?phone=%2B${calltrackingPhone.substr(-11)}`} target="_blank">
                {formatNumber(calltrackingPhone)}
              </Link>
              <CalltrackingProvider provider={provider} />
            </div>
            <div>
              <Link href={makeUrl('offers', { userPhone, calltrackingPhone })} target="_blank">
                {offersCount}
              </Link>
            </div>
            <div>{PHONE_STATUSES[status]}</div>
            <div>
              {provider === 'mts' && (
                <CheckPhoneTool
                  onClick={onCheckPhoneClick}
                  id={id}
                  calltrackingPhone={calltrackingPhone}
                  isProviderRedirectPhoneRequested={isProviderRedirectPhoneRequested}
                  isProviderRedirectPhoneFetching={isProviderRedirectPhoneFetching}
                  isValid={isValid}
                  actualPhone={actualPhone}
                />
              )}
            </div>
          </div>
        ),
      )}
    </div>
  );
}
