import * as React from 'react';

import { ModalWrapper, Modal } from '@cian/modal-component';
import { Button } from '@cian/button-component';

import * as styles from './CalltrackingXmlToggledModal.css';
import { ECalltrackingXmlStatus } from '../../repositories/calltracking-core/entities/entities/UserSchema';

export interface ICalltrackingXmlToggledModalProps {
  calltrackingXmlUser: string | number | null;
  calltrackingXmlStatus: ECalltrackingXmlStatus | null;
  onModalClose(): void;
}

export class CalltrackingXmlToggledModal extends React.Component<ICalltrackingXmlToggledModalProps> {
  public render() {
    const { calltrackingXmlUser, calltrackingXmlStatus, onModalClose } = this.props;

    if (!calltrackingXmlUser) {
      return null;
    }

    const actionText = calltrackingXmlStatus === 'active' ? 'подключен' : 'отключен';

    return (
      <ModalWrapper onClose={onModalClose}>
        <Modal minHeight={220} minWidth={500}>
          <div className={styles['modal-header']}>
            Коллтрекинг в XML {actionText} для клиента {calltrackingXmlUser}
          </div>
          <div className={styles['modal-buttons']}>
            <Button className={styles['confirm-button']} onClick={onModalClose}>
              Готово
            </Button>
          </div>
        </Modal>
      </ModalWrapper>
    );
  }
}
