import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState, IThunkExtraArgument, TActions, disableRegion, enableRegion } from '../redux';
import { ToggleRegionPage } from '../components/toggle_region';
import { setRegionId } from '../redux/toggle_region';

export interface IStateProps {
  regionId: number | null;
  result: string | null;
}

export function mapStateToProps(state: IAppState): IStateProps {
  const { regionId, result } = state.toggleRegion;

  return { regionId, result };
}

export interface IDispatchProps {
  setRegionId(regionId?: number): void;
  disableRegion(): void;
  enableRegion(): void;
}

export function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>): IDispatchProps {
  return {
    setRegionId: (regionId?: number) => dispatch(setRegionId(regionId !== undefined ? regionId : null)),
    disableRegion: () => dispatch(disableRegion()),
    enableRegion: () => dispatch(enableRegion()),
  };
}

export const ToggleRegionPageContainer = connect<IStateProps, IDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ToggleRegionPage);
