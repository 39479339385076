import { IGetBlockedUserPhoneInfoResponse } from '../../repositories/calltracking-core/v1/get-blocked-user-phone-info';
import {
  EUserPhoneActionType,
  IBlockUserPhoneAction,
  IHideUserPhoneDataAction,
  ISetBlockedUserPhoneDataAction,
  ISetErrorAction,
  IUnblockUserPhoneAction,
  IUserPhoneParams,
} from './types';

export function setBlockStatus(payload: IUserPhoneParams): IBlockUserPhoneAction {
  return {
    type: EUserPhoneActionType.Block,
    payload,
  };
}

export function setUnblockStatus(payload: IUserPhoneParams): IUnblockUserPhoneAction {
  return {
    type: EUserPhoneActionType.Unblock,
    payload,
  };
}

export function setUserPhoneData(payload: IGetBlockedUserPhoneInfoResponse): ISetBlockedUserPhoneDataAction {
  return {
    type: EUserPhoneActionType.SetData,
    payload,
  };
}

export function setError(message: string): ISetErrorAction {
  return {
    type: EUserPhoneActionType.SetError,
    message,
  };
}

export function hideUserPhoneData(): IHideUserPhoneDataAction {
  return {
    type: EUserPhoneActionType.HideData,
  };
}

export type TUserPhoneActions =
  | IUnblockUserPhoneAction
  | IBlockUserPhoneAction
  | ISetBlockedUserPhoneDataAction
  | ISetErrorAction
  | IHideUserPhoneDataAction;
