/* eslint-disable max-lines */
import { ThunkAction } from 'redux-thunk';

import { IAppState, IThunkExtraArgument } from '../types';
import { IServerErrorItem } from '../../api/types';
import { getUserPhonesLinks } from '../../api/phones';
import { checkPhone } from '../../api/phone_check';
import { EProvider } from '../../repositories/calltracking-core/v1/get-real-phone-by-calltracking-phone';
import {
  EStatus,
  IPhoneLinkSchema,
} from '../../repositories/calltracking-core/entities/get_user_phone_links/PhoneLinkSchema';
import { IGetUserPhonesLinksRequest } from '../../repositories/calltracking-core/v1/get-user-phones-links';

export interface IFilterParams {
  userId?: number;
  page?: number;
  pageSize?: number;
}

export interface IPhoneSchema {
  id: number;
  userPhone: string;
  calltrackingPhone: string;
  provider?: string;
  offersCount: number;
  status: EStatus;
  isProviderRedirectPhoneRequested?: boolean;
  isProviderRedirectPhoneFetching?: boolean;
  isValid?: boolean;
  actualPhone?: string;
}

export interface IPhonesState {
  isFetching: boolean;
  filterParams: IFilterParams;
  errors: IServerErrorItem[] | null;
  phones: IPhoneSchema[];
  total: number;
}

// action types

export const REQUEST_PHONES = 'phones/REQUEST_PHONES';
export const RECEIVE_PHONES = 'phones/RECEIVE_PHONES';
export const REQUEST_PROVIDER_REDIRECT_PHONE = 'phones/REQUEST_PROVIDER_REDIRECT_PHONE';
export const RECEIVE_PROVIDER_REDIRECT_PHONE = 'phones/RECEIVE_PROVIDER_REDIRECT_PHONE';
export const CHANGE_PAGE_SIZE = 'phone/CHANGE_PAGE_SIZE';
export const CHANGE_PAGE = 'phone/CHANGE_PAGE';
export const HANDLE_ERRORS = 'phones/HANDLE_ERRORS';
export const CLEAR_ERRORS = 'phones/CLEAR_ERRORS';

// actions

export interface IRequestPhonesAction {
  type: typeof REQUEST_PHONES;
  params: IFilterParams;
}

export interface IReceivePhonesAction {
  type: typeof RECEIVE_PHONES;
  phones: IPhoneLinkSchema[];
  total: number;
  provider?: string;
}

export interface IRequestProviderRedirectPhoneAction {
  type: typeof REQUEST_PROVIDER_REDIRECT_PHONE;
  id: number;
}

export interface IReceiveProviderRedirectPhoneAction {
  type: typeof RECEIVE_PROVIDER_REDIRECT_PHONE;
  id: number;
  phone?: string;
}

export interface IChangePageSizeAction {
  type: typeof CHANGE_PAGE_SIZE;
  pageSize: number;
}

export interface IChangePageAction {
  type: typeof CHANGE_PAGE;
  page: number;
}

export interface IHandleErrorsAction {
  type: typeof HANDLE_ERRORS;
  errors: IServerErrorItem[];
}

export interface IClearErrorsAction {
  type: typeof CLEAR_ERRORS;
}

export type TPhonesAction =
  | IRequestPhonesAction
  | IReceivePhonesAction
  | IRequestProviderRedirectPhoneAction
  | IReceiveProviderRedirectPhoneAction
  | IChangePageSizeAction
  | IChangePageAction
  | IHandleErrorsAction
  | IClearErrorsAction;

// action creators

export function requestPhones(params: IFilterParams): IRequestPhonesAction {
  return {
    type: REQUEST_PHONES,
    params,
  };
}

export function receivePhones(phones: IPhoneLinkSchema[], total: number, provider?: string): IReceivePhonesAction {
  return {
    type: RECEIVE_PHONES,
    phones,
    total,
    provider,
  };
}

export function requestProviderRedirectPhone(id: number): IRequestProviderRedirectPhoneAction {
  return {
    type: REQUEST_PROVIDER_REDIRECT_PHONE,
    id,
  };
}

export function receiveProviderRedirectPhone(id: number, phone?: string): IReceiveProviderRedirectPhoneAction {
  return {
    type: RECEIVE_PROVIDER_REDIRECT_PHONE,
    id,
    phone,
  };
}

export function changePageSize(pageSize: number): IChangePageSizeAction {
  return {
    type: CHANGE_PAGE_SIZE,
    pageSize,
  };
}

export function changePage(page: number): IChangePageAction {
  return {
    type: CHANGE_PAGE,
    page,
  };
}

export function handleErrors(errors: IServerErrorItem[]): IHandleErrorsAction {
  return {
    type: HANDLE_ERRORS,
    errors,
  };
}

export function clearErrors(): IClearErrorsAction {
  return {
    type: CLEAR_ERRORS,
  };
}

// thunk action creators

export function fetchPhones(params: IFilterParams): ThunkAction<void, IAppState, IThunkExtraArgument, TPhonesAction> {
  return (dispatch, getState, { httpApi }) => {
    if (!params.userId) {
      return dispatch(receivePhones([], 0));
    }

    const request: IGetUserPhonesLinksRequest = {
      userId: params.userId,
      page: params.page ? params.page : 1,
      pageSize: params.pageSize ? params.pageSize : 20,
    };

    dispatch(requestPhones(request as IFilterParams));

    return getUserPhonesLinks(httpApi, request)
      .then(({ phoneLinks, totalCount }) => {
        return dispatch(receivePhones(phoneLinks, totalCount));
      })
      .catch(errors => {
        return dispatch(handleErrors(errors));
      });
  };
}

export function fetchProviderPhoneRedirect(
  id: number,
  calltrackingPhone: string,
): ThunkAction<void, IAppState, IThunkExtraArgument, TPhonesAction> {
  return (dispatch, getState, { httpApi }) => {
    dispatch(requestProviderRedirectPhone(id));

    checkPhone(httpApi, { calltrackingPhone, provider: EProvider.Mts })
      .then(({ realPhone }) => {
        return dispatch(receiveProviderRedirectPhone(id, realPhone ? `+7${realPhone.substr(-10)}` : 'Отсутствует'));
      })
      .catch(errors => {
        return dispatch(handleErrors(errors));
      });
  };
}

// reducer

export function phonesReducer(state: IAppState, action: TPhonesAction): IAppState {
  switch (action.type) {
    case REQUEST_PHONES:
      return Object.assign({}, state, {
        phones: {
          ...state.phones,
          isFetching: true,
          filterParams: action.params,
        },
      });

    case RECEIVE_PHONES:
      return Object.assign({}, state, {
        phones: {
          ...state.phones,
          isFetching: false,
          phones: action.phones,
          total: action.total,
        },
      });

    case REQUEST_PROVIDER_REDIRECT_PHONE:
      return Object.assign({}, state, {
        phones: {
          ...state.phones,
          phones: state.phones.phones.map(phone => {
            if (phone.id === action.id) {
              return {
                ...phone,
                isProviderRedirectPhoneRequested: true,
                isProviderRedirectPhoneFetching: true,
              };
            }

            return phone;
          }),
        },
      });

    case RECEIVE_PROVIDER_REDIRECT_PHONE:
      return Object.assign({}, state, {
        phones: {
          ...state.phones,
          phones: state.phones.phones.map(phone => {
            if (phone.id === action.id) {
              return {
                ...phone,
                isProviderRedirectPhoneFetching: false,
                actualPhone: action.phone,
                isValid: phone.userPhone === action.phone,
              };
            }

            return phone;
          }),
        },
      });

    case CHANGE_PAGE_SIZE:
      return Object.assign({}, state, {
        phones: {
          ...state.phones,
          filterParams: {
            ...state.phones.filterParams,
            pageSize: action.pageSize,
          },
        },
      });

    case CHANGE_PAGE:
      return Object.assign({}, state, {
        phones: {
          ...state.phones,
          filterParams: {
            ...state.phones.filterParams,
            page: action.page,
          },
        },
      });

    case HANDLE_ERRORS:
      return Object.assign({}, state, {
        phones: {
          ...state.phones,
          isFetching: false,
          errors: action.errors,
          phones: state.phones.phones.map(phone => {
            return {
              ...phone,
              isProviderRedirectPhoneRequested: false,
              isProviderRedirectPhoneFetching: false,
            };
          }),
        },
      });

    case CLEAR_ERRORS:
      return Object.assign({}, state, {
        phones: {
          ...state.phones,
          isFetching: false,
          errors: null,
          phones: state.phones.phones.map(phone => {
            return {
              ...phone,
              isProviderRedirectPhoneRequested: false,
              isProviderRedirectPhoneFetching: false,
            };
          }),
        },
      });

    default:
      return state;
  }
}
