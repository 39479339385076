import * as React from 'react';
import * as styles from './styles.css';
import { Button, PhoneField } from '@cian/components';

interface IProps {
  phone: string;
  text: string;
  btnText: string;
  disabled?: boolean;

  onChange(value: string): void;

  onClick(): void;
}

export function PhoneInputBlock(props: IProps) {
  const { phone, onChange, onClick, btnText, text, disabled } = props;

  return (
    <div className={styles['container']}>
      <div className={styles['phone-field']}>
        <div className={styles['phone-field_label']}>{text}</div>
        <div className={styles['phone-field_input']}>
          <PhoneField mobile={true} value={phone || ''} onValueChange={onChange} />
        </div>
      </div>

      <div className={styles['apply-button']}>
        <Button theme="button/solid" onClick={onClick} disabled={disabled}>
          {btnText}
        </Button>
      </div>
    </div>
  );
}
