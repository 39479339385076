import * as React from 'react';

import { Button, ButtonTheme, Link, mergeStyles, Modal, ModalWrapper } from '@cian/components';
import { IHttpApi } from '@cian/http-api/shared/http';

import {
  getCalltrackingUsers,
  requestCalltrackingConnect,
  requestCalltrackingDisconnect,
} from '../../api/calltracking_users';
import { IServerErrorItem } from '../../api/types';
import { CALLTRACKING_STATUSES, CalltrackingStatus } from '../calltracking_users_filter';
import { CalltrackingUsersFilterContainer } from '../calltracking_users_filter/container';
import { Close } from '../close';
import { ContentWrapper } from '../content_wrapper';
import { IGetUsersRequest } from '../../repositories/calltracking-core/v1/get-users';
import { IRegionSchema } from '../../repositories/calltracking-core/entities/entities/RegionSchema';
import { ECalltrackingXmlStatus, IUserSchema } from '../../repositories/calltracking-core/entities/entities/UserSchema';

import * as tableStyles from '../data_table/index.css';
import * as styles from './index.css';

export interface ICalltrackingUsersPageStoreProps {
  calltrackingUsers: IUserSchema[];
  filter: IGetUsersRequest;
  httpApi: IHttpApi;
}

export interface ICalltrackingUsersPageState {
  connectSuccessed: boolean | undefined;
  connectUserId: number | undefined;
  disconnectSuccessed: boolean | undefined;
  disconnectUserId: number | undefined;
  errors: IServerErrorItem[] | null;
}

export interface ICalltrackingUsersPageDispatchProps {
  onLoadUsers(users: IUserSchema[]): void;
}

export type ICalltrackingUsersPageProps = ICalltrackingUsersPageStoreProps & ICalltrackingUsersPageDispatchProps;

export class CalltrackingUsersPage extends React.Component<ICalltrackingUsersPageProps, ICalltrackingUsersPageState> {
  public constructor(props: ICalltrackingUsersPageProps) {
    super(props);

    this.state = {
      connectSuccessed: undefined,
      connectUserId: undefined,
      disconnectSuccessed: undefined,
      disconnectUserId: undefined,
      errors: null,
    };
  }

  public render() {
    const { calltrackingUsers } = this.props;
    const { connectUserId, disconnectUserId, connectSuccessed, disconnectSuccessed, errors } = this.state;
    const closeBtn = (
      <div className={styles['modal-close']} onClick={this.handleModalClose}>
        <Close />
      </div>
    );

    return (
      <ContentWrapper title="Управление коллтрекингом" errors={errors} clearErrors={this.clearErrors}>
        <CalltrackingUsersFilterContainer onFilterError={this.onFilterError} />
        {connectUserId && (
          <ModalWrapper onClose={this.handleModalClose}>
            <Modal closeBtn={closeBtn} minHeight={220} minWidth={500}>
              <div className={styles['modal-header']}>Подключить Calltracking для этого клиента?</div>
              <div className={styles['modal-buttons']}>
                <Button
                  type="button"
                  buttonStyle={styles['success-button']}
                  theme="button/solid"
                  onClick={this.handleModalConnectUserClick}
                >
                  Подключить
                </Button>
                <Button type="button" theme={ButtonTheme.BLUE} onClick={this.handleModalClose}>
                  Отменить
                </Button>
              </div>
            </Modal>
          </ModalWrapper>
        )}
        {disconnectUserId && (
          <ModalWrapper onClose={this.handleModalClose}>
            <Modal closeBtn={closeBtn} minHeight={220} minWidth={500}>
              <div className={styles['modal-header']}>Отключить Calltracking для этого клиента?</div>
              <div className={styles['modal-buttons']}>
                <Button
                  type="button"
                  buttonStyle={styles['success-button']}
                  theme="button/solid"
                  onClick={this.handleModalDisconnectUserClick}
                >
                  Отключить
                </Button>
                <Button type="button" theme={ButtonTheme.BLUE} onClick={this.handleModalClose}>
                  Отменить
                </Button>
              </div>
            </Modal>
          </ModalWrapper>
        )}
        {(connectSuccessed || disconnectSuccessed) && (
          <ModalWrapper onClose={this.handleModalClose}>
            <Modal closeBtn={closeBtn} minHeight={220} minWidth={500}>
              <div className={styles['modal-header']}>
                {connectSuccessed ? 'Подключение прошло успешно' : 'Отключение прошло успешно'}
              </div>
              <div className={styles['modal-buttons']}>
                <Button type="button" theme={ButtonTheme.BLUE} onClick={this.handleModalClose}>
                  Закрыть
                </Button>
              </div>
            </Modal>
          </ModalWrapper>
        )}
        <div {...mergeStyles(tableStyles['table'], styles['table'])}>
          <div className={tableStyles['th']}>
            <div>ID клиента</div>
            <div>Статус</div>
            <div>Телефоны</div>
            <div>Регион</div>
            <div>Название компании</div>
            <div>Действия</div>
          </div>

          {calltrackingUsers.map(user => {
            const { phoneCount, agencyName, cianUserId } = user;

            return (
              <div className={tableStyles['tr']} key={cianUserId}>
                <div>
                  <Link href={`/calls?userId=${cianUserId}`} target="_blank">
                    {cianUserId}
                  </Link>
                </div>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <div>{this.getUserStatusText((user as any).calltrackingStatus as ECalltrackingXmlStatus)}</div>
                <div>Телефонов: {phoneCount}</div>
                {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
                <div>{((user as any).regions as IRegionSchema[]).map(region => region.name).join(', ')}</div>
                <div>
                  <Link href={`/calls?userId=${cianUserId}`} target="_blank">
                    {agencyName}
                  </Link>
                </div>
                <div>
                  {this.renderConnectCalltrackingButton(user)}
                  {this.renderDisconnectCalltrackingButton(user)}
                </div>
              </div>
            );
          })}
        </div>
      </ContentWrapper>
    );
  }

  public componentDidMount() {
    const { filter } = this.props;

    if (filter.userId) {
      this.getUsers(filter);
    }
  }

  /* Подключение CT */

  private renderConnectCalltrackingButton = (user: IUserSchema) => {
    //TODO: Проверка статуса
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((user as any).calltrackingStatus !== CalltrackingStatus.NotConnect) {
      return null;
    }

    return (
      <div className={styles['connect-ct-button']}>
        <Button theme="button/solid" onClick={() => this.handleConnectButtonClick(user.userId)}>
          Подключить
        </Button>
      </div>
    );
  };

  private requestCalltrackingConnect = (id: number) => {
    const { httpApi } = this.props;

    return requestCalltrackingConnect(httpApi, { userId: id })
      .then(() => this.getUsers(this.props.filter))
      .catch((errors: IServerErrorItem[]) => {
        this.setState({ errors });

        return Promise.reject();
      });
  };

  /* Отключение CT */

  private renderDisconnectCalltrackingButton = (user: IUserSchema) => {
    //TODO: Проверка статуса
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((user as any).calltrackingStatus !== CalltrackingStatus.Connect) {
      return null;
    }

    return (
      <div className={styles['disconnect-ct-button']}>
        <Button onClick={() => this.handleDisconnectButtonClick(user.userId)}>Отключить</Button>
      </div>
    );
  };

  private requestCalltrackingDisconnect = (id: number) => {
    const { httpApi } = this.props;

    return requestCalltrackingDisconnect(httpApi, { userId: id })
      .then(() => this.getUsers(this.props.filter))
      .catch((errors: IServerErrorItem[]) => {
        this.setState({ errors });

        return Promise.reject();
      });
  };

  private getUserStatusText = (status: ECalltrackingXmlStatus) => {
    return CALLTRACKING_STATUSES[status];
  };

  private getUsers = (filter: IGetUsersRequest) => {
    const { onLoadUsers, httpApi } = this.props;

    return getCalltrackingUsers(httpApi, filter)
      .then(onLoadUsers)
      .catch((errors: IServerErrorItem[]) => this.setState({ errors }));
  };

  private handleModalDisconnectUserClick = () => {
    const { disconnectUserId } = this.state;

    if (!disconnectUserId) {
      return;
    }

    this.handleModalClose();

    this.requestCalltrackingDisconnect(disconnectUserId).then(() => this.setState({ disconnectSuccessed: true }));
  };

  private handleModalConnectUserClick = () => {
    const { connectUserId } = this.state;

    if (!connectUserId) {
      return;
    }

    this.handleModalClose();

    this.requestCalltrackingConnect(connectUserId).then(() => this.setState({ connectSuccessed: true }));
  };

  private handleConnectButtonClick = (id: number) => {
    this.setState({
      connectUserId: id,
    });
  };

  private handleDisconnectButtonClick = (id: number) => {
    this.setState({
      disconnectUserId: id,
    });
  };

  private handleModalClose = () => {
    this.setState({
      connectSuccessed: undefined,
      connectUserId: undefined,
      disconnectSuccessed: undefined,
      disconnectUserId: undefined,
    });
  };

  private onFilterError = (errors: IServerErrorItem[]) => {
    this.setState({ errors });
  };

  private clearErrors = () => {
    this.setState({ errors: null });
  };
  // tslint:disable-next-line: max-file-line-count
}
