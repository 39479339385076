/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetUserForceCalltrackingActiveSessionModel,
  IGetUserForceCalltrackingActiveSessionRequest,
  IMappers,
  IGetUserForceCalltrackingActiveSessionResponse,
  IGetUserForceCalltrackingActiveSessionResponseError,
  TGetUserForceCalltrackingActiveSessionResponse,
} from './types';

const defaultConfig: TGetUserForceCalltrackingActiveSessionModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'moderation',
  pathApi: '/v1/get-user-force-calltracking-active-session/',
  hostType: 'api',
} as TGetUserForceCalltrackingActiveSessionModel;

function createGetUserForceCalltrackingActiveSessionModel(
  parameters: IGetUserForceCalltrackingActiveSessionRequest,
): TGetUserForceCalltrackingActiveSessionModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetUserForceCalltrackingActiveSessionOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetUserForceCalltrackingActiveSessionRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetUserForceCalltrackingActiveSession<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetUserForceCalltrackingActiveSessionOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetUserForceCalltrackingActiveSessionResponse
> {
  const { response, statusCode } = await httpApi.fetch(
    createGetUserForceCalltrackingActiveSessionModel(parameters),
    config,
  );

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetUserForceCalltrackingActiveSessionResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetUserForceCalltrackingActiveSessionResponseError);
    }
  }

  return { response, statusCode } as TGetUserForceCalltrackingActiveSessionResponse;
}

export { defaultConfig, createGetUserForceCalltrackingActiveSessionModel, fetchGetUserForceCalltrackingActiveSession };
