import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { CallsPage, ICallsPageDispatchProps, ICallsPageStoreProps } from '.';
import { setCalls } from '../../redux/calls';
import { IAppState } from '../../redux/types';
import { TActions } from '../../redux/actions';
import { ICallSchema } from '../../repositories/calltracking-core/entities/entities/CallSchema';

const DEFAULT_STATE = {
  calls: [],
  filter: {
    limit: 20,
    offset: 0,
  },
  host: '',
};

export function mapStateToProps(state: IAppState): ICallsPageStoreProps {
  if (!state.calls) {
    return {
      ...DEFAULT_STATE,
      httpApi: state.httpApi,
    };
  }
  const { list, filter } = state.calls;

  return {
    calls: list,
    filter,
    host: state.config.get<string>('apiBaseUrl') || '',
    httpApi: state.httpApi,
  };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, object, TActions>): ICallsPageDispatchProps {
  return {
    onCallsSuccess: (calls: ICallSchema[]) => dispatch(setCalls(calls)),
  };
}

export const CallsPageContainer = connect<ICallsPageStoreProps, ICallsPageDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CallsPage);
