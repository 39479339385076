/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TGetRealPhoneByCalltrackingPhoneModel = IModel<
  IGetRealPhoneByCalltrackingPhoneRequest,
  TGetRealPhoneByCalltrackingPhoneResponse
>;

export interface IGetRealPhoneByCalltrackingPhoneRequest {
  /** Подмененный номер телефона из пула с кодом страны **/
  calltrackingPhone?: string | null;
  /** Провайдер **/
  provider?: EProvider | null;
}

export type TGetRealPhoneByCalltrackingPhoneResponse =
  | IGetRealPhoneByCalltrackingPhoneResponse200
  | IGetRealPhoneByCalltrackingPhoneResponse400;

export interface IGetRealPhoneByCalltrackingPhoneResponse200
  extends IModelResponse<IGetRealPhoneByCalltrackingPhoneResponse> {
  statusCode: 200;
}

export interface IGetRealPhoneByCalltrackingPhoneResponse400
  extends IModelResponse<IGetRealPhoneByCalltrackingPhoneResponseError> {
  statusCode: 400;
}

export interface IGetRealPhoneByCalltrackingPhoneResponse {
  /** Реальный номер телефона **/
  realPhone?: string | null;
  /** Статус подменного телефона в пуле **/
  status: EStatus;
}

export interface IGetRealPhoneByCalltrackingPhoneResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IGetRealPhoneByCalltrackingPhoneResponse | IGetRealPhoneByCalltrackingPhoneResponseError;

export enum EStatus {
  /** Используется **/
  Active = 'active',
  /** Заблокирован **/
  Blocked = 'blocked',
  /** Свободный **/
  Free = 'free',
  /** Отстойник **/
  Insump = 'insump',
}

export enum EProvider {
  /** beeline **/
  Beeline = 'beeline',
  /** mts **/
  Mts = 'mts',
  /** mtt **/
  Mtt = 'mtt',
  /** Тестовый провайдер **/
  Qa = 'qa',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IGetRealPhoneByCalltrackingPhoneResponse): TResponse200;
  400(response: IGetRealPhoneByCalltrackingPhoneResponseError): TResponse400;
}
