import * as React from 'react';

import { Button, NumberField } from '@cian/components';
import { IHttpApi } from '@cian/http-api/shared/http';

import { getCalltrackingUsers } from '../../api/calltracking_users';
import { IServerErrorItem } from '../../api/types';
import { ECalltrackingXmlStatus, IUserSchema } from '../../repositories/calltracking-core/entities/entities/UserSchema';
import { IGetUsersRequest } from '../../repositories/calltracking-core/v1/get-users';

import * as styles from './index.css';

export const CalltrackingStatus = {
  Connect: 'active' as ECalltrackingXmlStatus,
  NotConnect: 'inactive' as ECalltrackingXmlStatus,
};

export interface ICalltrackingStatus {
  value: ECalltrackingXmlStatus;
  label: string;
}

export const CALLTRACKING_STATUSES = {
  [CalltrackingStatus.NotConnect]: 'Не подключен КТ в XML',
  [CalltrackingStatus.Connect]: 'Подключен КТ в XML',
};

export const CALLTRACKING_STATUSES_VALUES = [
  { value: CalltrackingStatus.NotConnect, label: 'Не подключен КТ в XML' },
  { value: CalltrackingStatus.Connect, label: 'Подключен КТ в XML' },
];

export interface ICalltrackingUsersFilterStoreProps extends IGetUsersRequest {
  httpApi: IHttpApi;
}

export interface ICalltrackingUsersFilterDispatchProps {
  onFilterApply(filter: IGetUsersRequest): void;
  onUsersSuccess(users: IUserSchema[]): void;
}

export interface ICalltrackingUsersFilterOwnProps {
  onFilterError(errors: IServerErrorItem[]): void;
}

export interface ICalltrackingUsersFilterState {
  userId?: number;
}

export type ICalltrackingUsersProps = ICalltrackingUsersFilterStoreProps &
  ICalltrackingUsersFilterDispatchProps &
  ICalltrackingUsersFilterOwnProps;

export class CalltrackingUsersFilter extends React.Component<ICalltrackingUsersProps, ICalltrackingUsersFilterState> {
  public constructor(props: ICalltrackingUsersProps) {
    super(props);

    const { userId } = props;

    this.state = {
      userId: userId || undefined,
    };
  }

  public render() {
    const { userId } = this.state;

    return (
      <div className={styles['container']}>
        <div className={styles['filter-param']}>
          <div className={styles['filter-label']}>ID</div>
          <div className={styles['filter-input']}>
            <NumberField disablePrettyFormating value={userId ? userId : undefined} onValueChange={this.onIdChange} />
          </div>
        </div>

        <div className={styles['apply-filters-button']}>
          <Button theme="button/solid" onClick={this.onApplyButtonClick}>
            Показать
          </Button>
        </div>
      </div>
    );
  }

  private onIdChange = (userId: number) => {
    this.setState({
      userId,
    });
  };

  private onApplyButtonClick = () => {
    const { userId } = this.state;
    const { onFilterApply, onUsersSuccess, httpApi } = this.props;

    // let isSuccess = false;

    if (!userId) {
      this.props.onFilterError([{ key: '', code: 'userId required', message: 'Введите Id пользователя' }]);

      return;
    }

    onFilterApply({
      userId,
    });

    getCalltrackingUsers(httpApi, { userId })
      .then(users => {
        onUsersSuccess(users);
        // isSuccess = true;
      })
      .catch((errors: IServerErrorItem[]) => this.props.onFilterError(errors));
  };
}
