import { IPaginationItem } from '@cian/frontend-pagination-widget';

export function calculatePages(
  count: number,
  step: number = 20,
  current: number = 1,
  urlPrefix: string = '',
  pageParam: string = 'page',
  pageSizeParam: string = 'pageSize',
): IPaginationItem[] {
  return Array.from({ length: Math.max(Math.ceil(count / step), 1) }).map(
    (page, index): IPaginationItem => ({
      id: index + 1,
      isActive: index + 1 === current,
      text: `${index + 1}`,
      url: `${urlPrefix}${pageSizeParam}=${step}&${pageParam}=${index + 1}`,
    }),
  );
}
