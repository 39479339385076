import * as React from 'react';

import { PaginationWidget, IPaginationItem } from '@cian/frontend-pagination-widget';

import { ContentWrapper } from '../content_wrapper';
import { IServerErrorItem } from '../../api/types';
import { PhonesContainer } from '../../containers/phonesContainer';
import { IPhoneSchema, IFilterParams } from '../../redux';
import { calculatePages } from '../../utils/pagination';
import { EntitiesCountSelector } from '../entities_count_selector';
import { MetaLabel } from '../meta_label';

import * as globalStyles from '../../index.css';
import * as styles from './PhonesPage.css';

export const ENTITY_COUNT_STEPS = [
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

export const ENTITY_COUNT_MAPPING: { [key: number]: string } = {
  20: '20',
  50: '50',
  100: '100',
};

export interface IPhonesPageProps {
  total: number;
  isFetching: boolean;
  filterParams: IFilterParams;
  phones: IPhoneSchema[];
  errors: IServerErrorItem[] | null;
  clearErrors(): void;
  onPageSizeChange(pageSize: number): void;
  onPageChange(page: IPaginationItem): void;
  onComponentDidMount(): void;
}

export class PhonesPage extends React.Component<IPhonesPageProps> {
  public render() {
    const { total, phones, errors, clearErrors, isFetching, filterParams, onPageSizeChange, onPageChange } = this.props;

    return (
      <ContentWrapper title="Телефоны" errors={errors} clearErrors={clearErrors}>
        {!(isFetching || phones.length) && <div className={globalStyles['user-message']}>Нет данных</div>}
        {!!phones.length && (
          <div className={styles['meta']}>
            <MetaLabel label={'Всего телефонов'} value={total} />
            <EntitiesCountSelector
              count={ENTITY_COUNT_MAPPING[filterParams.pageSize || 20]}
              steps={ENTITY_COUNT_STEPS}
              onCountChange={(count: string) => {
                onPageSizeChange(parseInt(count, 10));
              }}
            />
          </div>
        )}
        <PhonesContainer />
        {!!phones.length && (
          <div className={styles['pagination']}>
            <PaginationWidget
              pages={calculatePages(
                total,
                filterParams.pageSize,
                filterParams.page,
                `/phones?userId=${filterParams.userId}&`,
              )}
              onSelect={onPageChange}
            />
          </div>
        )}
      </ContentWrapper>
    );
  }

  public componentDidMount() {
    const { onComponentDidMount } = this.props;

    onComponentDidMount();
  }
}
