import * as React from 'react';
import { formatNumber } from '../../utils/number';
import { mergeStyles } from '@cian/utils';
import * as globalStyles from '../../index.css';
import * as styles from './SearchOffersResultPresenter.css';
import { IModalParams } from '../../redux/modals';

interface IProps {
  userId: number;
  userPhone?: string | null;

  toggleBlockModal(params: IModalParams): void;
}

export function UserPhone(props: IProps) {
  const { userId, userPhone } = props;

  if (!userPhone) {
    return null;
  }

  return (
    <span>
      <span>{formatNumber(userPhone)}</span>
      <span
        {...mergeStyles(globalStyles['link'], styles['calltracking-phone-block'])}
        onClick={() => props.toggleBlockModal({ userId, phone: userPhone, show: true })}
        id={`${userPhone}-btn`}
      >
        Заблокировать реальный номер
      </span>
    </span>
  );
}
