import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  CalltrackingUsersFilter,
  ICalltrackingUsersFilterDispatchProps,
  ICalltrackingUsersFilterOwnProps,
  ICalltrackingUsersFilterStoreProps,
} from '.';
import { setCalltrackingUsersFilters, TActions } from '../../redux/actions';
import { ICalltrackingUsersFilterParams, setCalltrackingUsers } from '../../redux/calltracking_users';
import { IAppState } from '../../redux/types';
import { IUserSchema } from '../../repositories/calltracking-core/entities/entities/UserSchema';
import { IGetUsersRequest } from '../../repositories/calltracking-core/v1/get-users';

export function mapStateToProps(state: IAppState): ICalltrackingUsersFilterStoreProps {
  return {
    httpApi: state.httpApi,
    ...state.calltrackingUsers.filter,
  };
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<IAppState, object, TActions>,
): ICalltrackingUsersFilterDispatchProps {
  return {
    onFilterApply: (filter: IGetUsersRequest) =>
      dispatch(setCalltrackingUsersFilters(filter as ICalltrackingUsersFilterParams)),
    onUsersSuccess: (users: IUserSchema[]) => dispatch(setCalltrackingUsers(users)),
  };
}

export const CalltrackingUsersFilterContainer = connect<
  ICalltrackingUsersFilterStoreProps,
  ICalltrackingUsersFilterDispatchProps,
  ICalltrackingUsersFilterOwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(CalltrackingUsersFilter);
