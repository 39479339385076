/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TSearchForceCalltrackingSessionObjectsModel,
  ISearchForceCalltrackingSessionObjectsRequest,
  IMappers,
  ISearchForceCalltrackingSessionObjectsResponse,
  ISearchForceCalltrackingSessionObjectsResponseError,
  TSearchForceCalltrackingSessionObjectsResponse,
} from './types';

const defaultConfig: TSearchForceCalltrackingSessionObjectsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'moderation',
  pathApi: '/v1/search-force-calltracking-session-objects/',
  hostType: 'api',
} as TSearchForceCalltrackingSessionObjectsModel;

function createSearchForceCalltrackingSessionObjectsModel(
  parameters: ISearchForceCalltrackingSessionObjectsRequest,
): TSearchForceCalltrackingSessionObjectsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISearchForceCalltrackingSessionObjectsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISearchForceCalltrackingSessionObjectsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchSearchForceCalltrackingSessionObjects<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISearchForceCalltrackingSessionObjectsOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TSearchForceCalltrackingSessionObjectsResponse
> {
  const { response, statusCode } = await httpApi.fetch(
    createSearchForceCalltrackingSessionObjectsModel(parameters),
    config,
  );

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISearchForceCalltrackingSessionObjectsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ISearchForceCalltrackingSessionObjectsResponseError);
    }
  }

  return { response, statusCode } as TSearchForceCalltrackingSessionObjectsResponse;
}

export { defaultConfig, createSearchForceCalltrackingSessionObjectsModel, fetchSearchForceCalltrackingSessionObjects };
