import { configureSentry } from './sentry';
import { getConfig } from '@cian/config/browser';
import { initDependencies, setupDependencies } from './app';

require('@cian/components/lib/index.css');

setupDependencies();
initDependencies();

const config = getConfig();

configureSentry(config, {
  matchUrl: url => {
    const projectName = config.get<string>('projectName');

    if (!projectName) {
      throw new Error('Project name not found');
    }

    return url.indexOf(projectName) >= 0;
  },
});

const { runApp } = require('./services/runApp');
runApp();

// tslint:disable-next-line: no-any
if ((module as any).hot) {
  // tslint:disable-next-line: no-any
  (module as any).hot.accept();
}
