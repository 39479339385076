/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TDeactivateUserCalltrackingModel,
  IDeactivateUserCalltrackingRequest,
  IMappers,
  IDeactivateUserCalltrackingResponse,
  IDeactivateUserCalltrackingResponseError,
  TDeactivateUserCalltrackingResponse,
} from './types';

const defaultConfig: TDeactivateUserCalltrackingModel = {
  apiType: 'public',
  assertStatusCodes: [400, 200, 204],
  method: 'POST',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/deactivate-user-calltracking/',
  hostType: 'api',
} as TDeactivateUserCalltrackingModel;

function createDeactivateUserCalltrackingModel(
  parameters: IDeactivateUserCalltrackingRequest,
): TDeactivateUserCalltrackingModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IDeactivateUserCalltrackingOptions<TResponse400, TResponse200, TResponse204> {
  httpApi: IHttpApi;
  parameters: IDeactivateUserCalltrackingRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400, TResponse200, TResponse204>;
}

async function fetchDeactivateUserCalltracking<TResponse400, TResponse200, TResponse204>({
  httpApi,
  parameters,
  config,
  mappers,
}: IDeactivateUserCalltrackingOptions<TResponse400, TResponse200, TResponse204>): Promise<
  TResponse400 | TResponse200 | TResponse204 | TDeactivateUserCalltrackingResponse
> {
  const { response, statusCode } = await httpApi.fetch(createDeactivateUserCalltrackingModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as IDeactivateUserCalltrackingResponseError);
    }

    if (statusCode === 200) {
      return mappers[200](response as IDeactivateUserCalltrackingResponse);
    }

    if (statusCode === 204) {
      return mappers[204]();
    }
  }

  return { response, statusCode } as TDeactivateUserCalltrackingResponse;
}

export { defaultConfig, createDeactivateUserCalltrackingModel, fetchDeactivateUserCalltracking };
