/* eslint-disable */
//tslint:disable

export interface IUserSchema {
  /** Название компании **/
  agencyName?: string | null;
  /** Статус коллтрекинга для выгрузок **/
  calltrackingXmlStatus?: ECalltrackingXmlStatus | null;
  /** Статус коллтрекинга может быть изменён **/
  calltrackingXmlStatusCanBeChanged: boolean;
  /** CIAN ID пользователя **/
  cianUserId: number;
  /** Кол-во телефонов **/
  phoneCount: number;
  /** Realty ID пользователя **/
  userId: number;
}

export enum ECalltrackingXmlStatus {
  /** Коллтрекинг доступен **/
  Active = 'active',
  /** Коллтрекинг не доступен **/
  Inactive = 'inactive',
}
