/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TSearchUsersModel,
  ISearchUsersRequest,
  IMappers,
  ISearchUsersResponse,
  ISearchUsersResponseError,
  TSearchUsersResponse,
} from './types';

const defaultConfig: TSearchUsersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/search-users/',
  hostType: 'api',
} as TSearchUsersModel;

function createSearchUsersModel(parameters: ISearchUsersRequest): TSearchUsersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISearchUsersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISearchUsersRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchSearchUsers<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISearchUsersOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TSearchUsersResponse> {
  const { response, statusCode } = await httpApi.fetch(createSearchUsersModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISearchUsersResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ISearchUsersResponseError);
    }
  }

  return { response, statusCode } as TSearchUsersResponse;
}

export { defaultConfig, createSearchUsersModel, fetchSearchUsers };
