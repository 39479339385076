import { connect } from 'react-redux';
import { BlockedUserPhoneInfo } from '../components/BlockedUserPhoneInfo/BlockedUserPhoneInfo';
import { IAppState } from '../redux';
import { IBlockedUserPhoneInfoParams } from '../mappers/transformBlockedUserPhoneInfoResponse';

interface IStateProps {
  userPhoneData: IBlockedUserPhoneInfoParams;
}

export const mapStateToProps = (state: IAppState) => ({
  userPhoneData: state.userPhoneData,
});

export const mergeProps = (stateProps: IStateProps) => ({
  userPhoneData: stateProps.userPhoneData,
});

export const BlockedUserPhoneInfoContainer = connect(mapStateToProps, null, mergeProps)(BlockedUserPhoneInfo);
