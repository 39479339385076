import * as moment from 'moment';
import * as React from 'react';

import { IPaginationItem, PaginationWidget } from '@cian/frontend-pagination-widget';
import { IHttpApi } from '@cian/http-api/shared/http';
import { Link, mergeStyles, Modal, ModalWrapper } from '../../../../node_modules/@cian/components';

import { checkPhone } from '../../api/phone_check';
import { getReplacedPhonesHistory } from '../../api/replaced_phone_history';
import { IServerErrorItem } from '../../api/types';
import { IReplacedPhoneHistoryFilterParams, IReplacedPhoneHistoryMeta } from '../../redux/replaced_phones';
import { formatNumber } from '../../utils/number';
import { Close } from '../close/close';
import { ContentWrapper } from '../content_wrapper';
import { EntitiesCountSelector } from '../entities_count_selector';
import { MetaLabel } from '../meta_label';
import { CalltrackingProvider } from '../CalltrackingProvider';
import { EProvider } from '../../repositories/calltracking-core/v1/get-real-phone-by-calltracking-phone';
import { IResponseItemSchemaArrayWrapper } from '../../repositories/calltracking-core/entities/get_phones_link_history/ResponseItemSchemaArrayWrapper';

import * as tableStyles from '../data_table/index.css';
import * as styles from './index.css';

const REPLACED_PHONES_STATUSES: { [index: string]: string } = {
  deactivated: 'Связка отключена',
  deleted: 'Связка удалена',
  inSump: 'В отстойнике',
  providerOk: 'Связка зарегистрирована у оператора сотовой связи',
  published: 'Связка активна',
  readyForProvider: 'Требует регистрации у оператора сотовой связи',
};

export const ENTITY_COUNT_STEPS = [
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

export interface IReplacedPhonesStoreProps {
  replacedPhoneActions: IResponseItemSchemaArrayWrapper[][];
  filter: IReplacedPhoneHistoryFilterParams;
  meta: IReplacedPhoneHistoryMeta;
  httpApi: IHttpApi;
}

export interface IReplacedPhonesDispatchProps {
  onActionsSuccess(actions: IResponseItemSchemaArrayWrapper[][]): void;
}

export interface IReplacedPhonesState {
  pages: IPaginationItem[];
  pageSize: string;
  totalItems: number;
  provider?: string;
  phoneCheckStatus?: 'free' | 'active';
  realPhone?: string;
  errors: IServerErrorItem[] | null;
}

export type IReplacedPhonesProps = IReplacedPhonesStoreProps & IReplacedPhonesDispatchProps;

export class ReplacedPhoneHistory extends React.Component<IReplacedPhonesProps, IReplacedPhonesState> {
  public constructor(props: IReplacedPhonesProps) {
    super(props);

    const {
      filter: { pageSize, page },
      meta: { totalItems },
    } = this.props;

    this.state = {
      errors: null,
      pages: this.calculatePages(totalItems, +pageSize, page),
      pageSize: '20',
      totalItems,
    };
  }

  public componentDidMount() {
    const { filter, onActionsSuccess, httpApi } = this.props;
    const { pageSize } = this.state;

    getReplacedPhonesHistory(httpApi, { ...filter, pageSize: +pageSize, page: 1 }).then(
      ({ items, totalItems, provider }) => {
        this.setState({
          pages: this.calculatePages(totalItems, +pageSize, 1),
          totalItems,
          provider: provider as string,
        });

        onActionsSuccess(items);
      },
    );
  }

  public render() {
    const {
      replacedPhoneActions,
      filter: { calltrackingPhone },
    } = this.props;
    const { pages, pageSize, totalItems, realPhone, provider, phoneCheckStatus, errors } = this.state;

    const closeBtn = (
      <div onClick={this.handleModalClose}>
        <Close />
      </div>
    );

    return (
      <ContentWrapper
        title={`История подменного номера ${formatNumber(calltrackingPhone)}`}
        showCheckButton={provider === 'mts'}
        onCheckButtonClick={this.onCheckButtonClick}
        errors={errors}
        clearErrors={this.clearErrors}
      >
        {phoneCheckStatus === 'free' && (
          <ModalWrapper onClose={this.handleModalClose}>
            <Modal closeBtn={closeBtn} minHeight={180} minWidth={400}>
              <div className={styles['modal-header']}>Номер свободен</div>
            </Modal>
          </ModalWrapper>
        )}
        {phoneCheckStatus === 'active' && (
          <ModalWrapper onClose={this.handleModalClose}>
            <Modal closeBtn={closeBtn} minHeight={180} minWidth={400}>
              <div className={styles['modal-header']}>{`Номер занят: ${formatNumber(realPhone || '')}`}</div>
            </Modal>
          </ModalWrapper>
        )}
        <div className={styles['meta']}>
          <MetaLabel label={'Провайдер'} value={<CalltrackingProvider provider={provider} />} />
        </div>
        <div className={styles['meta']}>
          <MetaLabel label={'Всего записей'} value={totalItems || 0} />
          <EntitiesCountSelector
            count={pageSize}
            steps={ENTITY_COUNT_STEPS}
            onCountChange={this.onEntitiesCountChange}
          />
        </div>
        <div {...mergeStyles(tableStyles['table'], styles['table'])}>
          <div className={tableStyles['th']}>
            <div>Действие</div>
            <div>Дата</div>
            <div>Клиент</div>
            <div>Номер клиента</div>
          </div>

          {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
          {replacedPhoneActions.map(({ statusV2, updatedAt, userPhone, cianUserId }: any) => (
            <div className={tableStyles['tr']} key={`${cianUserId}-${statusV2}-${updatedAt}`}>
              <div>{this.getActionText(statusV2)}</div>
              <div>{this.renderDate(updatedAt)}</div>
              <div>{this.renderClientLink(cianUserId)}</div>
              <div>{this.renderClientNumber(userPhone)}</div>
            </div>
          ))}
        </div>
        <div className={styles['pagination']}>
          <PaginationWidget pages={pages} onSelect={this.onPageChange} />
        </div>
      </ContentWrapper>
    );
  }

  private handleModalClose = () => {
    this.setState({
      phoneCheckStatus: undefined,
      realPhone: undefined,
    });
  };

  private getActionText = (action: string) => {
    return REPLACED_PHONES_STATUSES[action];
  };

  private renderDate = (date: string) => {
    return moment(new Date(date)).format('DD.MM.YYYY HH:mm:ss');
  };

  private renderClientLink = (client: number) => {
    return (
      <span>
        <Link href={`http://service.cian.ru/customers/view/${client}`} target="_blank">
          {client}
        </Link>
      </span>
    );
  };

  private renderClientNumber = (clientPhone: string) => {
    return formatNumber(clientPhone);
  };

  private onCheckButtonClick = () => {
    const { filter, httpApi } = this.props;

    checkPhone(httpApi, { calltrackingPhone: filter.calltrackingPhone, provider: EProvider.Mts })
      .then(({ status, realPhone }) => {
        if (status === 'free') {
          this.setState({
            phoneCheckStatus: status,
          });
        }

        if (status === 'active') {
          this.setState({
            phoneCheckStatus: status,
            realPhone: realPhone as string | undefined,
          });
        }
      })
      .catch((errors: IServerErrorItem[]) => this.setState({ errors }));
  };

  private onPageChange = (nextPage: IPaginationItem) => {
    const { filter, onActionsSuccess, httpApi } = this.props;
    const { pageSize } = this.state;

    getReplacedPhonesHistory(httpApi, { ...filter, pageSize: +pageSize, page: nextPage.id })
      .then(({ items, totalItems }) => {
        this.setState({
          pages: this.calculatePages(totalItems, +pageSize, nextPage.id),
          totalItems,
        });

        onActionsSuccess(items);
      })
      .catch((errors: IServerErrorItem[]) => this.setState({ errors }));
  };

  private onEntitiesCountChange = (pageSize: string) => {
    const { httpApi, filter, onActionsSuccess } = this.props;

    getReplacedPhonesHistory(httpApi, { ...filter, pageSize: +pageSize, page: 1 })
      .then(({ items, totalItems }) => {
        this.setState({
          pages: this.calculatePages(totalItems, +pageSize, 1),
          pageSize,
          totalItems,
        });

        onActionsSuccess(items);
      })
      .catch((errors: IServerErrorItem[]) => this.setState({ errors }));
  };

  private calculatePages = (count: number, step: number = 20, current: number = 1) => {
    return Array.from({ length: Math.max(Math.ceil(count / step), 1) }).map(
      (page, index): IPaginationItem => ({
        id: index + 1,
        isActive: index + 1 === current,
        text: `${index + 1}`,
        url: `page=${index + 1}`,
      }),
    );
  };

  private clearErrors = () => {
    this.setState({ errors: null });
  };
}
