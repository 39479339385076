import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../../redux/types';
import { ISearchFilterParams, TSearchType } from '../../redux/search';
import {
  setSearchTypeAction,
  setUsersSearchFilterParamsAction,
  setOffersSearchFilterParamsAction,
  TActions,
} from '../../redux/actions';

import {
  ISearchTypeSelectorStoreProps,
  ISearchTypeSelectorDispatchProps,
  ISearchTypeSelectorOwnProps,
  SearchTypeSelector,
} from '.';

export function mapStateToProps(state: IAppState): ISearchTypeSelectorStoreProps {
  const { searchType, usersFilterParams, offersFilterParams } = state.search;

  return { searchType, usersFilterParams, offersFilterParams };
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<IAppState, object, TActions>,
): ISearchTypeSelectorDispatchProps {
  return {
    onSearchTypeChange: (searchType: TSearchType) => dispatch(setSearchTypeAction(searchType)),
    onUsersFilterParamsChange: (params: ISearchFilterParams) => dispatch(setUsersSearchFilterParamsAction(params)),
    onOffersFilterParamsChange: (params: ISearchFilterParams) => dispatch(setOffersSearchFilterParamsAction(params)),
  };
}

export const SearchTypeSelectorContainer = connect<
  ISearchTypeSelectorStoreProps,
  ISearchTypeSelectorDispatchProps,
  ISearchTypeSelectorOwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(SearchTypeSelector);
