import { IAppState, IThunkExtraArgument, TActions } from '../redux';
import { connect } from 'react-redux';
import { PhoneCheckPage } from '../components/PhoneCheck/PhoneCheck';
import { ThunkDispatch } from 'redux-thunk';
import { checkUserPhone, hideUserPhoneData } from '../redux/userPhone';
import * as React from 'react';
import { BlockedUserPhoneInfoContainer } from './BlockedUserPhoneInfoContainer';

interface IDispatchProps {
  onClickCheckButton(phone: string): void;
  hideUserPhoneData(): void;
}

interface IStateProps {
  showUserPhoneData: boolean;
  errorMessage: string;
}

export function mapStateToProps(state: IAppState) {
  return {
    showUserPhoneData: state.showUserPhoneData,
    errorMessage: state.errorMessage,
  };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>): IDispatchProps {
  return {
    onClickCheckButton: (phone: string) => dispatch(checkUserPhone(phone)),
    hideUserPhoneData: () => dispatch(hideUserPhoneData()),
  };
}

export function mergeProps(stateProps: IStateProps, dispatchProps: IDispatchProps) {
  const { onClickCheckButton, hideUserPhoneData } = dispatchProps;
  const { errorMessage, showUserPhoneData } = stateProps;

  return {
    checkPhone: onClickCheckButton,
    blockerData: showUserPhoneData ? <BlockedUserPhoneInfoContainer /> : null,
    errorMessage: errorMessage && <div>{errorMessage}</div>,
    hideUserPhoneData,
  };
}

export const PhoneCheckPageContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(PhoneCheckPage);
