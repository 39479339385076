import * as React from 'react';

import { mergeStyles } from '@cian/utils';

import { SearchFilter } from '../search_filter';
import { ISearchFilterParams, TSearchType } from '../../redux/search';

import * as styles from './index.css';
import * as globalStyles from '../../index.css';

export interface ISearchTypeSelectorStoreProps {
  searchType: TSearchType;
  usersFilterParams: ISearchFilterParams;
  offersFilterParams: ISearchFilterParams;
}

export interface ISearchTypeSelectorDispatchProps {
  onSearchTypeChange(searchType: TSearchType): void;
  onUsersFilterParamsChange(params: ISearchFilterParams): void;
  onOffersFilterParamsChange(params: ISearchFilterParams): void;
}

export interface ISearchTypeSelectorOwnProps {
  onUsersFilterApply(): void;
  onOffersFilterApply(): void;
}

export type ISearchTypeSelectorProps = ISearchTypeSelectorStoreProps &
  ISearchTypeSelectorDispatchProps &
  ISearchTypeSelectorOwnProps;

export class SearchTypeSelector extends React.Component<ISearchTypeSelectorProps> {
  public render() {
    const {
      searchType,
      usersFilterParams,
      offersFilterParams,
      onSearchTypeChange,
      onUsersFilterParamsChange,
      onOffersFilterParamsChange,
      onUsersFilterApply,
      onOffersFilterApply,
    } = this.props;

    return (
      <div className={styles['container']}>
        <div className={styles['tabs']}>
          <div
            {...mergeStyles(styles['tab'], globalStyles['link'], searchType === 'users' && styles['tab-active'])}
            onClick={() => {
              onSearchTypeChange('users');
            }}
          >
            Пользователи
          </div>
          <div
            {...mergeStyles(styles['tab'], globalStyles['link'], searchType === 'offers' && styles['tab-active'])}
            onClick={() => {
              onSearchTypeChange('offers');
            }}
          >
            Объявления
          </div>
        </div>
        {searchType === 'users' && (
          <SearchFilter
            params={usersFilterParams}
            onParamsChange={onUsersFilterParamsChange}
            onFilterApply={onUsersFilterApply}
          />
        )}
        {searchType === 'offers' && (
          <SearchFilter
            params={offersFilterParams}
            onParamsChange={onOffersFilterParamsChange}
            onFilterApply={onOffersFilterApply}
          />
        )}
      </div>
    );
  }
}
