import * as React from 'react';

import { IModalParams } from '../../redux/modals';
import { UnblockPhoneModalContainer } from '../../containers/unblockPhoneModalContainer';
import { PhoneResultModal } from '../phone_result_modal/PhoneResultModal';
import { PhoneInputBlock } from '../PhoneInputBlock/PhoneInputBlock';
import { IUserPhoneParams } from '../../redux/userPhone/types';

export interface IPhoneUnblockProps {
  unblockModal: IModalParams;
  userPhoneUnblockStatus: IUserPhoneParams;

  toggleUnblockModal(params: IModalParams): void;

  setUnblockStatus(params: IUserPhoneParams): void;
}

export interface IPhoneUnblockState {
  userPhone: string;
}

export class PhoneUnblockPage extends React.Component<IPhoneUnblockProps, IPhoneUnblockState> {
  public constructor(props: IPhoneUnblockProps) {
    super(props);

    this.state = {
      userPhone: '',
    };
  }

  public render() {
    const { userPhone } = this.state;
    const { unblockModal, userPhoneUnblockStatus } = this.props;
    const { show, phone } = unblockModal;
    const { error } = userPhoneUnblockStatus;

    const isUserButtonDisabled = userPhone ? userPhone.length < 11 : true;
    const showUnblockResultModal = !show && phone;

    return (
      <React.Fragment>
        {show && <UnblockPhoneModalContainer />}
        {showUnblockResultModal && (
          <PhoneResultModal
            text={error || `Номер ${phone} успешно разблокирован`}
            onButtonClick={this.hideUserUnblockConfirmation}
          />
        )}
        <PhoneInputBlock
          phone={userPhone}
          text="Разблокировать"
          btnText="Разблокировать"
          onChange={this.onUserPhoneChange}
          onClick={this.toggleUnblockModal}
          disabled={isUserButtonDisabled}
        />
      </React.Fragment>
    );
  }

  private toggleUnblockModal = () => {
    const { userPhone } = this.state;
    this.props.toggleUnblockModal({ show: true, phone: `+${userPhone}` });
  };

  private hideUserUnblockConfirmation = () => {
    if (!this.props.userPhoneUnblockStatus.error) {
      this.setState({ userPhone: '' });
    }
    this.props.setUnblockStatus({ error: '' });
    this.props.toggleUnblockModal({ show: false });
  };

  private onUserPhoneChange = (phone: string) => {
    this.setState({
      userPhone: phone,
    });
  };
}
