import { TSearchType, ISearchFilterParams } from '../redux';

export function makePhone(phone?: string) {
  return !phone || phone === '7' ? undefined : `+${phone.substr(-11)}`;
}

export function makeUrl(searchType: TSearchType, params: ISearchFilterParams) {
  return `/?searchType=${searchType}${serializeFilterParams(params)}`;
}

export function serializeFilterParams(params: ISearchFilterParams) {
  const { userId, offerId, userPhone, calltrackingPhone } = params;
  const qs = [];

  if (userId) {
    qs.push(`userId=${userId}`);
  }
  if (offerId) {
    qs.push(`offerId=${offerId}`);
  }
  if (userPhone && userPhone !== '7') {
    qs.push(`userPhone=${userPhone.substr(-11)}`);
  }
  if (calltrackingPhone && calltrackingPhone !== '7') {
    qs.push(`calltrackingPhone=${calltrackingPhone.substr(-11)}`);
  }

  return qs.length ? `&${qs.join('&')}` : '';
}
