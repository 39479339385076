import * as React from 'react';
import * as styles from './styles.css';

interface IProps {
  provider?: string;
}

export function CalltrackingProvider(props: IProps) {
  const { provider } = props;

  if (!provider) {
    return null;
  }

  if (provider === 'mtt') {
    return <span className={styles['provider-mtt']}>&nbsp;МТТ&nbsp;</span>;
  }

  if (provider === 'beeline') {
    return <span className={styles['provider-beeline']}>&nbsp;Билайн&nbsp;</span>;
  }

  return <span className={styles['provider-mts']}>&nbsp;МТС&nbsp;</span>;
}
