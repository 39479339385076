/* eslint-disable max-lines */
import { IAppState, IThunkExtraArgument } from '../types';

import { searchUsers, searchOffers } from '../../api/search';
import { ThunkAction } from 'redux-thunk';
import { TActions } from '../actions';
import { IServerErrorItem } from '../../api/types';
import { ISearchForceCalltrackingSessionObjectsResponse } from '../../repositories/moderation/v1/search-force-calltracking-session-objects';
import { removeUserPhone } from '../../api/phone_block';
import { requestCalltrackingConnect, requestCalltrackingDisconnect } from '../../api/calltracking_users';
import { getUserForceCalltrackingActiveSession } from '../../api/force_calltracking';
import { searchForceCalltrackingSessionObjects } from '../../api/offer_force_calltracking';
import { ISessionModel } from '../../../node/repositories/moderation/entities/ForceCallTracking/SessionModel';
import { ISessionObjectModel } from '../../repositories/moderation/entities/ForceCallTracking/SessionObjectModel';
import { IGetUserForceCalltrackingActiveSessionResponse200 } from '../../repositories/moderation/v1/get-user-force-calltracking-active-session';
import { ECalltrackingXmlStatus, IUserSchema } from '../../repositories/calltracking-core/entities/entities/UserSchema';
import { ISearchUsersRequest } from '../../repositories/calltracking-core/v1/search-users';
import { IOfferSchema } from '../../repositories/calltracking-core/entities/entities/OfferSchema';
import { IBlockPoolPhoneRequest } from '../../repositories/calltracking-core/v1/block-pool-phone';
import { ISearchOffersRequest } from '../../repositories/calltracking-core/v1/search-offers';

export interface ISearchFilterParams {
  userId?: number;
  offerId?: number;
  userPhone?: string;
  calltrackingPhone?: string;
}

export declare type TSearchType = 'users' | 'offers';

export interface ISearchUserSchema extends IUserSchema {
  forceCalltrackingSession: boolean;
}

export interface ISearchOfferSchema extends IOfferSchema {
  offerForceCalltrackingSession: boolean;
}

export interface ISearchState {
  searchType: TSearchType;
  users: ISearchUserSchema[];
  offers: ISearchOfferSchema[];
  usersFilterParams: ISearchFilterParams;
  offersFilterParams: ISearchFilterParams;
  usersHaveBeenSearched: boolean;
  offersHaveBeenSearched: boolean;
  showForceCalltrackingCheckbox: boolean;
  showOfferForceCalltrackingCheckbox: boolean;
  currentErrors: IServerErrorItem[] | null;
  blockedCalltrackingPhone: string | null;
  calltrackingXmlUser: string | number | null;
  calltrackingXmlStatus: ECalltrackingXmlStatus | null;
}

export interface ISetSearchTypeAction {
  searchType: TSearchType;
  type: 'search/SET_SEARCH_TYPE';
}

export interface ISetUsersAction {
  users: ISearchUserSchema[];
  type: 'search/SET_USERS';
}

export interface IShowOfferForceCalltrackingCheckboxAction {
  show: boolean;
  type: 'search/TOGGLE_SHOW_OFFER_FORCE_CALLTRACKING_CHECKBOX';
}
export interface IShowForceCalltrackingCheckboxAction {
  show: boolean;
  type: 'search/TOGGLE_SHOW_FORCE_CALLTRACKING_CHECKBOX';
}

export interface ISetSearchCurrentErrorsAction {
  errors: IServerErrorItem[];
  type: 'search/SET_SEARCH_CURRENT_ERRORS';
}

export interface IClearSearchCurrentErrorsAction {
  type: 'search/CLEAR_SEARCH_CURRENT_ERRORS';
}

export interface ISetOffersAction {
  offers: ISearchOfferSchema[];
  type: 'search/SET_OFFERS';
}

export interface ISetUsersSearchFilterParamsAction {
  params: ISearchFilterParams;
  type: 'search/SET_USERS_FILTER_PARAMS';
}

export interface ISetOffersSearchFilterParamsAction {
  params: ISearchFilterParams;
  type: 'search/SET_OFFERS_FILTER_PARAMS';
}

export interface ISetBlockedCalltrackingPhoneAction {
  phone: string;
  type: 'search/SET_BLOCKED_CALLTRACKING_PHONE';
}

export interface IClearBlockedCalltrackingPhoneAction {
  type: 'search/CLEAR_BLOCKED_CALLTRACKING_PHONE';
}

export interface ISetCalltrackingXmlStatusAction {
  user: string | number;
  status: ECalltrackingXmlStatus;
  type: 'search/SET_CALLTRACKING_XML_STATUS';
}

export interface IClearCalltrackingXmlStatusAction {
  type: 'search/CLEAR_CALLTRACKING_XML_STATUS';
}

export interface ISetForceCalltrackingSessionParams {
  userId: number;
  forceCalltrackingSession: boolean;
}

export interface ISetOfferForceCalltrackingSessionParams {
  offerId: number;
  offerForceCalltrackingSession: boolean;
}

export interface ISetForceCalltrackingSessionAction {
  params: ISetForceCalltrackingSessionParams;
  type: 'search/SET_USER_CALLTRACKING_SESSION';
}

export interface ISetOfferForceCalltrackingSessionAction {
  params: ISetOfferForceCalltrackingSessionParams;
  type: 'search/SET_OFFER_CALLTRACKING_SESSION';
}

export type TSearchAction =
  | ISetSearchTypeAction
  | ISetUsersAction
  | ISetOffersAction
  | ISetSearchCurrentErrorsAction
  | IClearSearchCurrentErrorsAction
  | ISetUsersSearchFilterParamsAction
  | ISetOffersSearchFilterParamsAction
  | ISetCalltrackingXmlStatusAction
  | IClearCalltrackingXmlStatusAction
  | ISetBlockedCalltrackingPhoneAction
  | IClearBlockedCalltrackingPhoneAction
  | ISetForceCalltrackingSessionAction
  | IShowForceCalltrackingCheckboxAction
  | IShowOfferForceCalltrackingCheckboxAction
  | ISetOfferForceCalltrackingSessionAction;

export function setCalltrackingXmlStatus(
  user: string | number,
  status: ECalltrackingXmlStatus,
): ISetCalltrackingXmlStatusAction {
  return {
    user,
    status,
    type: 'search/SET_CALLTRACKING_XML_STATUS',
  };
}

export function clearCalltrackingXmlStatusAction(): IClearCalltrackingXmlStatusAction {
  return {
    type: 'search/CLEAR_CALLTRACKING_XML_STATUS',
  };
}

export function setBlockedCalltrackingPhoneAction(phone: string): ISetBlockedCalltrackingPhoneAction {
  return {
    phone,
    type: 'search/SET_BLOCKED_CALLTRACKING_PHONE',
  };
}

export function clearBlockedCalltrackingPhoneAction(): IClearBlockedCalltrackingPhoneAction {
  return {
    type: 'search/CLEAR_BLOCKED_CALLTRACKING_PHONE',
  };
}

export function setSearchTypeAction(searchType: TSearchType): ISetSearchTypeAction {
  return {
    searchType,
    type: 'search/SET_SEARCH_TYPE',
  };
}

export function setUsersAction(users: ISearchUserSchema[]): ISetUsersAction {
  return {
    users,
    type: 'search/SET_USERS',
  };
}

export function showOfferForceCalltrackingCheckboxAction(show: boolean): IShowOfferForceCalltrackingCheckboxAction {
  return {
    show,
    type: 'search/TOGGLE_SHOW_OFFER_FORCE_CALLTRACKING_CHECKBOX',
  };
}

export function showForceCalltrackingCheckboxAction(show: boolean): IShowForceCalltrackingCheckboxAction {
  return {
    show,
    type: 'search/TOGGLE_SHOW_FORCE_CALLTRACKING_CHECKBOX',
  };
}

export function setSearchCurrentErrorsAction(errors: IServerErrorItem[]): ISetSearchCurrentErrorsAction {
  return {
    errors,
    type: 'search/SET_SEARCH_CURRENT_ERRORS',
  };
}

export function clearSearchCurrentErrorsAction(): IClearSearchCurrentErrorsAction {
  return {
    type: 'search/CLEAR_SEARCH_CURRENT_ERRORS',
  };
}

export function setOffersAction(offers: ISearchOfferSchema[]): ISetOffersAction {
  return {
    offers,
    type: 'search/SET_OFFERS',
  };
}

export function setUserForceCalltrackingSession(
  params: ISetForceCalltrackingSessionParams,
): ISetForceCalltrackingSessionAction {
  return {
    params,
    type: 'search/SET_USER_CALLTRACKING_SESSION',
  };
}

export function setOfferForceCalltrackingSession(
  params: ISetOfferForceCalltrackingSessionParams,
): ISetOfferForceCalltrackingSessionAction {
  return {
    params,
    type: 'search/SET_OFFER_CALLTRACKING_SESSION',
  };
}

export const blockCalltrackingPhoneAction = (
  params: IBlockPoolPhoneRequest,
): ThunkAction<void, IAppState, IThunkExtraArgument, TActions> => {
  return (dispatch, getState, { httpApi }) => {
    return removeUserPhone(httpApi, params)
      .then(() => {
        return dispatch(setBlockedCalltrackingPhoneAction(params.calltrackingPhone));
      })
      .catch(errors => {
        return dispatch(setSearchCurrentErrorsAction(errors));
      });
  };
};

export const searchUsersAction = (
  params: ISearchUsersRequest,
): ThunkAction<void, IAppState, IThunkExtraArgument, TActions> => {
  return async (dispatch, getState, { httpApi }) => {
    try {
      const { users } = await searchUsers(httpApi, params);

      if (users && users.length) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errors: any[] = [];
        const sessions = await Promise.all(
          users.map((user: ISearchUserSchema) => {
            const { cianUserId } = user;

            return getUserForceCalltrackingActiveSession(httpApi, { userId: cianUserId }).catch(error =>
              errors.push(error),
            );
          }),
        );

        dispatch(setUsersAction(users));

        if (errors && errors.length) {
          return dispatch(showForceCalltrackingCheckboxAction(false));
        }

        dispatch(showForceCalltrackingCheckboxAction(true));

        sessions.forEach((forceSession: IGetUserForceCalltrackingActiveSessionResponse200['response']) => {
          const { session } = forceSession;

          if (session) {
            return dispatch(
              setUserForceCalltrackingSession({ userId: session.userId as number, forceCalltrackingSession: true }),
            );
          }

          return;
        });
      } else {
        dispatch(setUsersAction(users));

        return;
      }

      return;
    } catch (errors) {
      return dispatch(setSearchCurrentErrorsAction(errors));
    }
  };
};

export const addForceCalltrackingSessionToOffersAction = (
  params: ISearchOffersRequest,
  offers: ISearchOfferSchema[],
): ThunkAction<void, IAppState, IThunkExtraArgument, TActions> => {
  return async (dispatch, getState, { httpApi }) => {
    let errors = false;
    let response: ISearchForceCalltrackingSessionObjectsResponse = { sessions: [] };
    try {
      response = await searchForceCalltrackingSessionObjects(httpApi, {
        userId: params.userId,
        objectId: params.offerId,
        phone: params.calltrackingPhone,
        sourcePhone: params.userPhone,
      });
    } catch (error) {
      errors = true;
    }

    dispatch(setOffersAction(offers));

    if (errors) {
      return dispatch(showOfferForceCalltrackingCheckboxAction(false));
    }

    dispatch(showOfferForceCalltrackingCheckboxAction(true));

    response.sessions.forEach(({ sessionObjects }: Pick<ISessionModel, 'sessionObjects'>) => {
      if (sessionObjects && sessionObjects.length) {
        sessionObjects.forEach(({ objectId }: Pick<ISessionObjectModel, 'objectId'>) =>
          dispatch(
            setOfferForceCalltrackingSession({
              offerId: objectId,
              offerForceCalltrackingSession: true,
            }),
          ),
        );
      }
    });

    return;
  };
};

export const searchOffersAction = (
  params: ISearchOffersRequest,
): ThunkAction<void, IAppState, IThunkExtraArgument, TActions> => {
  return async (dispatch, getState, { httpApi }) => {
    try {
      const { offers } = await searchOffers(httpApi, params);

      if (offers && offers.length) {
        return dispatch(addForceCalltrackingSessionToOffersAction(params, offers));
      } else {
        return dispatch(setOffersAction(offers));
      }
    } catch (errors) {
      return dispatch(setSearchCurrentErrorsAction(errors));
    }
  };
};

export const activateCalltrackingXmlAction = (
  userId: number,
  userName?: string,
): ThunkAction<void, IAppState, IThunkExtraArgument, TActions> => {
  return (dispatch, getState, { httpApi }) => {
    return requestCalltrackingConnect(httpApi, { userId })
      .then(() => {
        return dispatch(setCalltrackingXmlStatus(userName || userId, ECalltrackingXmlStatus.Active));
      })
      .catch(errors => {
        return dispatch(setSearchCurrentErrorsAction(errors));
      });
  };
};

export const deactivateCalltrackingXmlAction = (
  userId: number,
  userName?: string,
): ThunkAction<void, IAppState, IThunkExtraArgument, TActions> => {
  return (dispatch, getState, { httpApi }) => {
    return requestCalltrackingDisconnect(httpApi, { userId })
      .then(() => {
        return dispatch(setCalltrackingXmlStatus(userName || userId, ECalltrackingXmlStatus.Inactive));
      })
      .catch(errors => {
        return dispatch(setSearchCurrentErrorsAction(errors));
      });
  };
};

export function setUsersSearchFilterParamsAction(params: ISearchFilterParams): ISetUsersSearchFilterParamsAction {
  return {
    params,
    type: 'search/SET_USERS_FILTER_PARAMS',
  };
}

export function setOffersSearchFilterParamsAction(params: ISearchFilterParams): ISetOffersSearchFilterParamsAction {
  return {
    params,
    type: 'search/SET_OFFERS_FILTER_PARAMS',
  };
}

export function searchReducer(state: IAppState, action: TSearchAction) {
  switch (action.type) {
    case 'search/SET_SEARCH_TYPE':
      return {
        ...state,
        search: {
          ...state.search,
          searchType: action.searchType,
        },
      };

    case 'search/SET_USERS':
      return {
        ...state,
        search: {
          ...state.search,
          users: action.users,
          usersHaveBeenSearched: true,
        },
      };

    case 'search/SET_OFFERS':
      return {
        ...state,
        search: {
          ...state.search,
          offers: action.offers,
          offersHaveBeenSearched: true,
        },
      };

    case 'search/SET_USERS_FILTER_PARAMS':
      return {
        ...state,
        search: {
          ...state.search,
          usersFilterParams: action.params,
        },
      };

    case 'search/SET_OFFERS_FILTER_PARAMS':
      return {
        ...state,
        search: {
          ...state.search,
          offersFilterParams: action.params,
        },
      };

    case 'search/SET_SEARCH_CURRENT_ERRORS':
      return {
        ...state,
        search: {
          ...state.search,
          currentErrors: action.errors,
        },
      };

    case 'search/CLEAR_SEARCH_CURRENT_ERRORS':
      return {
        ...state,
        search: {
          ...state.search,
          currentErrors: null,
        },
      };

    case 'search/SET_BLOCKED_CALLTRACKING_PHONE':
      return {
        ...state,
        search: {
          ...state.search,
          blockedCalltrackingPhone: action.phone,
        },
      };

    case 'search/CLEAR_BLOCKED_CALLTRACKING_PHONE':
      return {
        ...state,
        search: {
          ...state.search,
          blockedCalltrackingPhone: null,
        },
      };

    case 'search/SET_CALLTRACKING_XML_STATUS':
      return {
        ...state,
        search: {
          ...state.search,
          calltrackingXmlUser: action.user,
          calltrackingXmlStatus: action.status,
        },
      };

    case 'search/CLEAR_CALLTRACKING_XML_STATUS':
      return {
        ...state,
        search: {
          ...state.search,
          calltrackingXmlUser: null,
          calltrackingXmlStatus: null,
        },
      };

    case 'search/SET_USER_CALLTRACKING_SESSION':
      const { userId, forceCalltrackingSession } = action.params;

      return {
        ...state,
        search: {
          ...state.search,
          users: state.search.users.map(user =>
            user.userId === userId ? { ...user, forceCalltrackingSession } : user,
          ),
        },
      };

    case 'search/SET_OFFER_CALLTRACKING_SESSION':
      const { offerId, offerForceCalltrackingSession } = action.params;

      return {
        ...state,
        search: {
          ...state.search,
          offers: state.search.offers.map(offer =>
            offer.offerId === offerId ? { ...offer, offerForceCalltrackingSession } : offer,
          ),
        },
      };

    case 'search/TOGGLE_SHOW_FORCE_CALLTRACKING_CHECKBOX':
      return {
        ...state,
        search: {
          ...state.search,
          showForceCalltrackingCheckbox: action.show,
        },
      };

    case 'search/TOGGLE_SHOW_OFFER_FORCE_CALLTRACKING_CHECKBOX':
      return {
        ...state,
        search: {
          ...state.search,
          showOfferForceCalltrackingCheckbox: action.show,
        },
      };

    default:
      return state || {};
  }
}
