import { ISetRegionIdAction, EToggleRegionActionType, IShowResultAction } from './types';

export function setRegionId(regionId: number | null): ISetRegionIdAction {
  return {
    type: EToggleRegionActionType.setRegionId,
    regionId,
  };
}

export function showResult(result: string | null): IShowResultAction {
  return {
    type: EToggleRegionActionType.showResult,
    result,
  };
}
