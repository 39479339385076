import { IAppState } from '../redux';
import { connect } from 'react-redux';
import { PhoneBlockPage } from '../components/phone_block/PhoneBlock';
import { Dispatch } from 'redux';
import { IModalParams, toggleBlockModal } from '../redux/modals';
import { setBlockStatus } from '../redux/userPhone';
import { IUserPhoneParams } from '../redux/userPhone/types';

export function mapStateToProps(state: IAppState) {
  return {
    httpApi: state.httpApi,
    blockModal: state.blockModal,
    userPhoneBlockStatus: state.userPhoneBlockStatus,
  };
}

export function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleBlockModal: (params: IModalParams) => dispatch(toggleBlockModal(params)),
    setBlockStatus: (params: IUserPhoneParams) => dispatch(setBlockStatus(params)),
  };
}

export const PhoneBlockContainer = connect(mapStateToProps, mapDispatchToProps)(PhoneBlockPage);
