/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TEnableRegionModel = IModel<IEnableRegionRequest, TEnableRegionResponse>;

export interface IEnableRegionRequest {
  /** ID региона **/
  regionId: number;
}

export type TEnableRegionResponse = IEnableRegionResponse200 | IEnableRegionResponse400;

export interface IEnableRegionResponse200 extends IModelResponse<IEnableRegionResponse> {
  statusCode: 200;
}

export interface IEnableRegionResponse400 extends IModelResponse<IEnableRegionResponseError> {
  statusCode: 400;
}

export interface IEnableRegionResponse {
  /** Статус ответа **/
  status: EStatus;
}

export interface IEnableRegionResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IEnableRegionResponse | IEnableRegionResponseError;

export enum EStatus {
  /** Регион не выключен **/
  NotDisabled = 'notDisabled',
  /** Регион включен **/
  Ok = 'ok',
  /** Для выполнения действия нужна роль `Cian.CalltrackingExpert` **/
  RoleRequired = 'roleRequired',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IEnableRegionResponse): TResponse200;
  400(response: IEnableRegionResponseError): TResponse400;
}
