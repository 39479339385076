import * as React from 'react';
import { IBlockedUserPhoneInfoParams } from '../../mappers/transformBlockedUserPhoneInfoResponse';
import * as moment from 'moment';

interface IProps {
  userPhoneData: IBlockedUserPhoneInfoParams;
}

export const BlockedUserPhoneInfo: React.FunctionComponent<IProps> = ({ userPhoneData }) => {
  let reason = null;
  if (userPhoneData.isBlocked && userPhoneData.data && userPhoneData.data.reason) {
    const blockDate = moment(userPhoneData.data.createdAt).format('DD.MM.YYYY HH:mm');
    reason = (
      <div>
        <div>Дата: {blockDate}</div>
        <div>Причина: {userPhoneData.data.reason}</div>
      </div>
    );
  }

  return (
    <div>
      <div>{userPhoneData.isBlocked ? 'Заблокирован' : 'Не заблокирован'}</div>
      {reason}
    </div>
  );
};
