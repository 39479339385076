/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TBlockPoolPhoneModel,
  IBlockPoolPhoneRequest,
  IMappers,
  IBlockPoolPhoneResponse,
  IBlockPoolPhoneResponseError,
  TBlockPoolPhoneResponse,
} from './types';

const defaultConfig: TBlockPoolPhoneModel = {
  apiType: 'public',
  assertStatusCodes: [400, 200, 204],
  method: 'POST',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/block-pool-phone/',
  hostType: 'api',
} as TBlockPoolPhoneModel;

function createBlockPoolPhoneModel(parameters: IBlockPoolPhoneRequest): TBlockPoolPhoneModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IBlockPoolPhoneOptions<TResponse400, TResponse200, TResponse204> {
  httpApi: IHttpApi;
  parameters: IBlockPoolPhoneRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400, TResponse200, TResponse204>;
}

async function fetchBlockPoolPhone<TResponse400, TResponse200, TResponse204>({
  httpApi,
  parameters,
  config,
  mappers,
}: IBlockPoolPhoneOptions<TResponse400, TResponse200, TResponse204>): Promise<
  TResponse400 | TResponse200 | TResponse204 | TBlockPoolPhoneResponse
> {
  const { response, statusCode } = await httpApi.fetch(createBlockPoolPhoneModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as IBlockPoolPhoneResponseError);
    }

    if (statusCode === 200) {
      return mappers[200](response as IBlockPoolPhoneResponse);
    }

    if (statusCode === 204) {
      return mappers[204]();
    }
  }

  return { response, statusCode } as TBlockPoolPhoneResponse;
}

export { defaultConfig, createBlockPoolPhoneModel, fetchBlockPoolPhone };
