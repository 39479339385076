import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IReplacedPhonesDispatchProps, IReplacedPhonesStoreProps, ReplacedPhoneHistory } from '.';
import { setReplacedPhoneActions } from '../../redux/replaced_phones/index';
import { IAppState } from '../../redux/types';
import { TActions } from '../../redux';
import { IResponseItemSchemaArrayWrapper } from '../../repositories/calltracking-core/entities/get_phones_link_history/ResponseItemSchemaArrayWrapper';

export function mapStateToProps(state: IAppState): IReplacedPhonesStoreProps {
  if (!state.replacedPhoneActions) {
    return {
      filter: {
        calltrackingPhone: '',
        page: 1,
        pageSize: 20,
      },
      httpApi: state.httpApi,
      meta: {
        totalItems: 0,
        totalPages: 0,
      },
      replacedPhoneActions: [],
    };
  }

  const { list, filter, meta } = state.replacedPhoneActions;

  return {
    filter,
    httpApi: state.httpApi,
    meta,
    replacedPhoneActions: list,
  };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, object, TActions>): IReplacedPhonesDispatchProps {
  return {
    onActionsSuccess: (actions: IResponseItemSchemaArrayWrapper[][]) => dispatch(setReplacedPhoneActions(actions)),
  };
}

export const ReplacedPhoneHistoryContainer = connect<IReplacedPhonesStoreProps, IReplacedPhonesDispatchProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(ReplacedPhoneHistory);
