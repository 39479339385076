import * as React from 'react';

import { ContentWrapper } from '../content_wrapper';
import { SearchTypeSelectorContainer } from '../search_type_selector/container';
import { SearchResultPresenterContainer } from '../search_result_presenter/container';
import { ISearchFilterParams, TSearchType } from '../../redux/search';
import { IServerErrorItem } from '../../api/types';
import { serializeFilterParams } from '../../utils/search';

export interface ISearchPageProps {
  searchType: TSearchType;
  usersFilterParams: ISearchFilterParams;
  offersFilterParams: ISearchFilterParams;
  currentErrors: IServerErrorItem[] | null;
  searchUsers(): void;
  searchOffers(): void;
  clearErrors(): void;
}

export class SearchPage extends React.Component<ISearchPageProps> {
  public render() {
    const { currentErrors, clearErrors, searchUsers, searchOffers } = this.props;

    return (
      <ContentWrapper title="Поиск" errors={currentErrors} clearErrors={clearErrors}>
        <SearchTypeSelectorContainer onUsersFilterApply={searchUsers} onOffersFilterApply={searchOffers} />
        <SearchResultPresenterContainer />
      </ContentWrapper>
    );
  }

  public componentDidMount() {
    const { searchType, usersFilterParams, offersFilterParams, searchUsers, searchOffers } = this.props;

    if (searchType === 'users' && serializeFilterParams(usersFilterParams)) {
      searchUsers();
    }

    if (searchType === 'offers' && serializeFilterParams(offersFilterParams)) {
      searchOffers();
    }
  }
}
