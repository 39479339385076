/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TSearchOffersModel,
  ISearchOffersRequest,
  IMappers,
  ISearchOffersResponse,
  ISearchOffersResponseError,
  TSearchOffersResponse,
} from './types';

const defaultConfig: TSearchOffersModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/search-offers/',
  hostType: 'api',
} as TSearchOffersModel;

function createSearchOffersModel(parameters: ISearchOffersRequest): TSearchOffersModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ISearchOffersOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: ISearchOffersRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchSearchOffers<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: ISearchOffersOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TSearchOffersResponse> {
  const { response, statusCode } = await httpApi.fetch(createSearchOffersModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as ISearchOffersResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as ISearchOffersResponseError);
    }
  }

  return { response, statusCode } as TSearchOffersResponse;
}

export { defaultConfig, createSearchOffersModel, fetchSearchOffers };
