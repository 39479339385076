import declareCianSentryConfiguration from '@cian/sentry/lib';
import { IConfig } from '@cian/config/shared';

export function configureSentry(
  config: IConfig,
  params: {
    currentState?(): object;
    matchUrl(url: string): boolean;
  },
): Promise<void> {
  const dsn = config.get<string>('sentry.browserDsn');
  const release = config.get<string>('sentry.release');
  const environment = config.get<string>('sentry.environment');
  const enabled = config.get<boolean>('sentry.enabled');

  if (enabled && dsn && release && environment) {
    declareCianSentryConfiguration({
      dsn,
      environment,
      extra: {
        config: config.dump(),
        cookie: document.cookie,
        currentState: params.currentState && {
          toJSON: () => params.currentState && params.currentState(),
        },
      },
      matchUrl: params.matchUrl,
      release,
    });

    return new Promise<void>(resolve => setTimeout(resolve, 0));
  }

  return Promise.resolve();
}
