/* eslint-disable */

//tslint:disable

import { IModel, IModelResponse } from '@cian/http-api/shared/model';

export type TDisableRegionModel = IModel<IDisableRegionRequest, TDisableRegionResponse>;

export interface IDisableRegionRequest {
  /** ID региона **/
  regionId: number;
}

export type TDisableRegionResponse = IDisableRegionResponse200 | IDisableRegionResponse400;

export interface IDisableRegionResponse200 extends IModelResponse<IDisableRegionResponse> {
  statusCode: 200;
}

export interface IDisableRegionResponse400 extends IModelResponse<IDisableRegionResponseError> {
  statusCode: 400;
}

export interface IDisableRegionResponse {
  /** Статус ответа **/
  status: EStatus;
}

export interface IDisableRegionResponseError {
  errors: any[];
  message: string;
}

export type TResponses = IDisableRegionResponse | IDisableRegionResponseError;

export enum EStatus {
  /** Регион уже выключен **/
  AlreadyDisabled = 'alreadyDisabled',
  /** Регион выключен **/
  Ok = 'ok',
  /** Для выполнения действия нужна роль `Cian.CalltrackingExpert` **/
  RoleRequired = 'roleRequired',
}

export interface IMappers<TResponse200, TResponse400> {
  200(response: IDisableRegionResponse): TResponse200;
  400(response: IDisableRegionResponseError): TResponse400;
}
