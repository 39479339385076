import * as React from 'react';
import { Modal, ModalWrapper } from '@cian/modal-component';
import * as styles from './styles.css';
import { Button, ButtonTheme } from '@cian/button-component';

export interface IConfirmModalProps {
  onConfirmClick(): void;

  onCancelClick(): void;

  title: string;
  text: string;
  isConfirmDisabled?: boolean;
  btnConfirmText: string;
}

export class ConfirmModal extends React.Component<IConfirmModalProps, {}> {
  public render() {
    const { title, text, btnConfirmText, isConfirmDisabled } = this.props;

    return (
      <ModalWrapper>
        <Modal minHeight={220} minWidth={500}>
          <div className={styles['modal-header']}>{title}</div>
          <div className={styles['modal-text']}>{text}</div>
          {this.props.children}
          <div className={styles['modal-buttons']}>
            <Button
              disabled={isConfirmDisabled}
              className={styles['confirm-button']}
              onClick={this.props.onConfirmClick}
            >
              {btnConfirmText}
            </Button>
            <Button theme={ButtonTheme.Ghost} onClick={this.props.onCancelClick}>
              Отменить
            </Button>
          </div>
        </Modal>
      </ModalWrapper>
    );
  }
}
