import * as React from 'react';

import { ModalWrapper, Modal } from '@cian/modal-component';
import { Button } from '@cian/button-component';

import { formatNumber } from '../../utils/number';

import * as styles from './CalltrackingPhoneBlockedModal.css';

export interface ICalltrackingPhoneBlockedModalProps {
  blockedCalltrackingPhone: string | null;
  onModalClose(): void;
}

export class CalltrackingPhoneBlockedModal extends React.Component<ICalltrackingPhoneBlockedModalProps> {
  public render() {
    const { blockedCalltrackingPhone, onModalClose } = this.props;

    if (!blockedCalltrackingPhone) {
      return null;
    }

    return (
      <ModalWrapper onClose={onModalClose}>
        <Modal minHeight={220} minWidth={500}>
          <div className={styles['modal-header']}>Номер {formatNumber(blockedCalltrackingPhone)} заблокирован</div>
          <div className={styles['modal-buttons']}>
            <Button className={styles['confirm-button']} onClick={onModalClose}>
              Готово
            </Button>
          </div>
        </Modal>
      </ModalWrapper>
    );
  }
}
