import * as React from 'react';

import { TSearchType, ISearchUserSchema, ISearchOfferSchema } from '../../redux/search';
import { SearchUsersResultPresenter } from '../search_users_result_presenter';
import { SearchOffersResultPresenter } from '../search_offers_result_presenter';

import * as globalStyles from '../../index.css';
import { IModalParams } from '../../redux/modals';
import { IUserPhoneParams } from '../../redux/userPhone/types';

export interface ISearchResultStoreProps {
  searchType: TSearchType;
  users: ISearchUserSchema[];
  offers: ISearchOfferSchema[];
  usersHaveBeenSearched: boolean;
  offersHaveBeenSearched: boolean;
  showForceCalltrackingCheckbox: boolean;
  showOfferForceCalltrackingCheckbox: boolean;
  blockModal: IModalParams;
  userPhoneBlockStatus: IUserPhoneParams;
}

export interface ISearchResultDispatchProps {
  blockCalltrackingPhone(calltrackingPhone: string): void;
  activateCalltrackingXml(userId: number, userName?: string): void;
  deactivateCalltrackingXml(userId: number, userName?: string): void;
  toggleBlockModal(params: IModalParams): void;
  setBlockStatus(params: IUserPhoneParams): void;
}

export type ISearchResultProps = ISearchResultStoreProps & ISearchResultDispatchProps;

export class SearchResultPresenter extends React.Component<ISearchResultProps> {
  public render() {
    return <div>{this.renderContainer()}</div>;
  }

  private renderContainer = () => {
    const {
      usersHaveBeenSearched,
      offersHaveBeenSearched,
      searchType,
      users,
      offers,
      blockCalltrackingPhone,
      activateCalltrackingXml,
      deactivateCalltrackingXml,
      showForceCalltrackingCheckbox,
      showOfferForceCalltrackingCheckbox,
      toggleBlockModal,
      blockModal,
      setBlockStatus,
      userPhoneBlockStatus,
    } = this.props;

    let welcomeText = 'Поиск в коллтрекинге.';
    let isEmpty = true;
    let isInitial = true;

    if (searchType === 'users') {
      welcomeText = 'Поиск пользователей коллтрекинга.';
      isEmpty = users.length === 0;
      isInitial = !usersHaveBeenSearched;
    }

    if (searchType === 'offers') {
      welcomeText = 'Поиск объявлений с подключенным коллтрекингом.';
      isEmpty = offers.length === 0;
      isInitial = !offersHaveBeenSearched;
    }

    if (isInitial) {
      return <div className={globalStyles['user-message']}>{welcomeText} Нажмите Найти, чтобы выполнить поиск.</div>;
    }

    if (isEmpty) {
      return (
        <div className={globalStyles['user-message']}>Ничего не найдено. Обновите критерии поиска и нажмите Найти.</div>
      );
    }

    if (searchType === 'users') {
      return (
        <SearchUsersResultPresenter
          showForceCalltrackingCheckbox={showForceCalltrackingCheckbox}
          users={users}
          onActivateCalltrackingXmlClick={activateCalltrackingXml}
          onDeactivateCalltrackingXmlClick={deactivateCalltrackingXml}
        />
      );
    }

    if (searchType === 'offers') {
      return (
        <SearchOffersResultPresenter
          setBlockStatus={setBlockStatus}
          userPhoneBlockStatus={userPhoneBlockStatus}
          toggleBlockModal={toggleBlockModal}
          blockModal={blockModal}
          showOfferForceCalltrackingCheckbox={showOfferForceCalltrackingCheckbox}
          offers={offers}
          onBlockCalltrackingPhoneClick={blockCalltrackingPhone}
        />
      );
    }

    return null;
  };
}
