import * as React from 'react';
import { Provider } from 'react-redux';
import { Store } from 'redux';

import { ILogger } from '@cian/logger';

import { IAppState } from './redux/types';

import { CallsPageContainer } from './components/calls/container';
import { CalltrackingUsersPageContainer } from './components/calltracking_users/container';
import { HeaderContainer } from './components/header';
import { NoPhoneForHistory } from './components/no_phone_for_history';
import { PoolPage } from './components/pool';
import { ReplacedPhoneHistoryContainer } from './components/replaced_phone_history/container';

import * as styles from './index.css';
import { SearchPageContainer } from './components/search/container';
import { PhonesPageContainer } from './containers/phonesPageContainer';
import { LoginPage } from './components/login';
import { ToggleRegionPageContainer } from './containers/ToggleRegionPageContainer';
import { PhoneActionsContainer } from './containers/PhoneActionsContainer';
import { IConfig } from '@cian/config/shared';

export interface IAppContainerState {
  currentPage: string;
}

export interface IAppProps {
  config?: IConfig;
  logger?: ILogger;
  store: Store<IAppState>;
}

export class App extends React.Component<IAppProps, object> {
  public render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <div className={styles['root']}>
          <HeaderContainer />

          {this.getPage()}
        </div>
      </Provider>
    );
  }

  private getPage = () => {
    const { currentPage, httpApi, regions, config, user } = this.props.store.getState();

    const host = config.get<string>('apiBaseUrl') || ('' as string);

    if (!user.isAuthenticated) {
      return <LoginPage />;
    }

    switch (currentPage) {
      case 'search':
        return <SearchPageContainer />;

      case 'phones':
        return <PhonesPageContainer />;

      case 'calls':
        return <CallsPageContainer />;

      case 'calltracking_users':
        return <CalltrackingUsersPageContainer />;

      case 'phone_actions':
        return <PhoneActionsContainer />;

      case 'pool':
        return <PoolPage httpApi={httpApi} regions={regions} host={host} />;

      case 'replaced_phone_history':
        return <ReplacedPhoneHistoryContainer />;

      case 'no_phone_for_history':
        return <NoPhoneForHistory />;

      case 'toggle_region':
        return <ToggleRegionPageContainer />;

      default:
        return null;
    }
  };
}
