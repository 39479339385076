import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { getConfig } from '@cian/config/browser';
import { getLogger } from '@cian/logger/browser';
import { IAppStore } from '../../../shared/redux';
import { App } from '../../../shared';

interface IDependencies {
  store: IAppStore;
}

export function renderApplication(dependencies: IDependencies) {
  const config = getConfig();
  const logger = getLogger();

  const { store } = dependencies;
  const projectName = config.get<string>('projectName') as string;

  const rootElement = document.getElementById(projectName);

  if (!rootElement) {
    throw new Error(`Unable to find element #${projectName}`);
  }

  ReactDOM.hydrate(<App config={config} logger={logger} store={store} />, rootElement);
}
