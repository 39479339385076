import * as React from 'react';

import * as styles from './index.css';

export class LoginPage extends React.Component {
  public render() {
    return (
      <div className={styles['container']}>
        Требуется авторизация через{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://service.cian.ru/">
          service.cian.ru
        </a>{' '}
        и{' '}
        <a target="_blank" rel="noopener noreferrer" href="http://cian.ru/">
          cian.ru
        </a>
      </div>
    );
  }
}
