import * as React from 'react';
import { Modal, ModalWrapper } from '@cian/modal-component';
import * as styles from '../search_offers_result_presenter/CalltrackingPhoneBlockedModal.css';
import { Button } from '@cian/button-component';

interface IProps {
  text: string;
  onButtonClick(): void;
}

export function PhoneResultModal(props: IProps) {
  return (
    <ModalWrapper>
      <Modal minHeight={220} minWidth={300}>
        <div className={styles['modal-header']}>{props.text}</div>
        <div className={styles['modal-buttons']}>
          <Button className={styles['confirm-button']} onClick={props.onButtonClick}>
            Готово
          </Button>
        </div>
      </Modal>
    </ModalWrapper>
  );
}
