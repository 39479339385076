import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { SearchPage, ISearchPageProps } from '.';
import { TActions, IThunkExtraArgument } from '../../redux';
import { IAppState } from '../../redux/types';
import {
  ISearchFilterParams,
  searchUsersAction,
  searchOffersAction,
  clearSearchCurrentErrorsAction,
  TSearchType,
} from '../../redux/search';
import { IServerErrorItem } from '../../api/types';
import { makePhone, makeUrl } from '../../utils/search';

export interface ISearchPageStateProps {
  searchType: TSearchType;
  usersFilterParams: ISearchFilterParams;
  offersFilterParams: ISearchFilterParams;
  currentErrors: IServerErrorItem[] | null;
}

export interface ISearchPageDispatchProps {
  searchUsers(params: ISearchFilterParams): void;
  searchOffers(params: ISearchFilterParams): void;
  clearErrors(): void;
}

export function mapStateToProps(state: IAppState): ISearchPageStateProps {
  const { searchType, usersFilterParams, offersFilterParams, currentErrors } = state.search;

  return {
    searchType,
    usersFilterParams,
    offersFilterParams,
    currentErrors,
  };
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>,
): ISearchPageDispatchProps {
  return {
    searchUsers: (params: ISearchFilterParams) => dispatch(searchUsersAction(params)),
    searchOffers: (params: ISearchFilterParams) => dispatch(searchOffersAction(params)),
    clearErrors: () => dispatch(clearSearchCurrentErrorsAction()),
  };
}

export function mergeProps(
  stateProps: ISearchPageStateProps,
  dispatchProps: ISearchPageDispatchProps,
): ISearchPageProps {
  const { usersFilterParams, offersFilterParams } = stateProps;
  const { searchUsers, searchOffers } = dispatchProps;

  return {
    ...stateProps,
    ...dispatchProps,
    searchUsers: () => {
      searchUsers({
        userId: usersFilterParams.userId || undefined,
        offerId: usersFilterParams.offerId || undefined,
        userPhone: makePhone(usersFilterParams.userPhone),
        calltrackingPhone: makePhone(usersFilterParams.calltrackingPhone),
      });
      window.history.pushState('', '', makeUrl('users', usersFilterParams));
    },
    searchOffers: () => {
      searchOffers({
        userId: offersFilterParams.userId || undefined,
        offerId: offersFilterParams.offerId || undefined,
        userPhone: makePhone(offersFilterParams.userPhone),
        calltrackingPhone: makePhone(offersFilterParams.calltrackingPhone),
      });
      window.history.pushState('', '', makeUrl('offers', offersFilterParams));
    },
  };
}

export const SearchPageContainer = connect<ISearchPageStateProps, ISearchPageDispatchProps, {}, ISearchPageProps>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(SearchPage);
