import * as React from 'react';

import * as moment from 'moment';
import {
  Button,
  Link,
  mergeStyles,
  ModalWrapper,
  Modal,
  MultilineTextField,
} from '../../../../node_modules/@cian/components';

import { getCalls } from '../../api/calls';
import { IServerErrorItem } from '../../api/types';
import { formatNumber } from '../../utils/number';
import { CallsFilterContainer } from '../calls_filter/container';
import { ContentWrapper } from '../content_wrapper';
import { EntitiesCountSelector } from '../entities_count_selector';
import { MetaLabel } from '../meta_label';
import { Close } from '../close';
import { CalltrackingProvider } from '../CalltrackingProvider';

import { IPaginationItem, PaginationWidget } from '@cian/frontend-pagination-widget';
import { IHttpApi } from '@cian/http-api/shared/http';

import * as tableStyles from '../data_table/index.css';
import * as styles from './index.css';
import * as globalStyles from '../../index.css';
import { EStatus, ICallSchema } from '../../repositories/calltracking-core/entities/entities/CallSchema';
import { IGetUserCallsRequest } from '../../repositories/calltracking-core/v1/get-user-calls';

export const ENTITY_COUNT_STEPS = [
  { label: '20', value: '20' },
  { label: '50', value: '50' },
  { label: '100', value: '100' },
];

export const CALL_STATUS_MAP: { [index: string]: string } = {
  busy: 'Занято',
  hgup: 'Сбросили',
  hgupAb: 'Вызываемый абонент сбросил',
  hgupOp: 'Звонящий положил трубку',
  noAnswer: 'Без ответа',
  success: 'Успешный',
  techProblem: 'Техническая ошибка',
  turnedOff: 'Выключен',
  unknown: 'Неизвестно',
};

export interface ICallsPageStoreProps {
  calls: ICallSchema[];
  filter: IGetUserCallsRequest;
  host: string;
  httpApi: IHttpApi;
}

export interface ICallsPageDispatchProps {
  onCallsSuccess(calls: ICallSchema[]): void;
}

export type ICallsProps = ICallsPageStoreProps & ICallsPageDispatchProps;

export interface ICallsState {
  pages: IPaginationItem[];
  pageSize: number;
  totalItems: number;
  errors: IServerErrorItem[] | null;
  isCdrLogModalOpen: boolean;
  cdrLogModalMessage: string;
}

export class CallsPage extends React.Component<ICallsProps, ICallsState> {
  public constructor(props: ICallsProps) {
    super(props);

    this.state = {
      errors: null,
      pages: this.calculatePages(0, 20, 1),
      pageSize: 20,
      totalItems: 0,
      isCdrLogModalOpen: false,
      cdrLogModalMessage: '',
    };
  }

  public render() {
    const { host } = this.props;
    const { pages, pageSize, totalItems, errors, isCdrLogModalOpen, cdrLogModalMessage } = this.state;
    const closeBtn = (
      <div className={styles['modal-close']} onClick={this.handleCdrLogModalClose}>
        <Close />
      </div>
    );

    return (
      <ContentWrapper title="История звонков" errors={errors} clearErrors={this.clearErrors}>
        {isCdrLogModalOpen && (
          <ModalWrapper onClose={this.handleCdrLogModalClose}>
            <Modal
              contentStyle={{ className: styles['modal-container'], style: {} }}
              closeBtn={closeBtn}
              minHeight={220}
              minWidth={500}
            >
              <div className={styles['modal-header']}>CDR лог звонка</div>
              <div className={styles['modal-description']}>
                CDR лог – это текстовая запись хронологии звонка при
                <br />
                помощи условных обозначений. Передайте CDR лог звонка
                <br />
                сотрудникам МТС в случае возникновения проблем для
                <br />
                анализа ситуации.
              </div>
              <div className={styles['modal-textarea']}>
                <MultilineTextField
                  value={cdrLogModalMessage}
                  onValueChange={() => {}}
                  placeholder={'CDR лог звонка не доступен'}
                  minRows={6}
                  inputStyle={styles['modal-textarea_input']}
                />
              </div>
              <div className={styles['modal-buttons']}>
                <Button type="button" theme="button/solid" onClick={this.handleCdrLogModalClose}>
                  Готово
                </Button>
              </div>
            </Modal>
          </ModalWrapper>
        )}
        <CallsFilterContainer
          getCalls={this.getCalls}
          onCallsSuccess={this.onCallsSuccess}
          onFilterError={this.onFilterError}
        />
        <div className={styles['meta']}>
          <MetaLabel label={'Всего записей'} value={totalItems || 0} />
          <EntitiesCountSelector
            count={pageSize.toString()}
            steps={ENTITY_COUNT_STEPS}
            onCountChange={this.onEntitiesCountChange}
          />
        </div>
        <div {...mergeStyles(tableStyles['table'], styles['table'])}>
          <div className={tableStyles['th']}>
            <div>Время</div>
            <div>Входящий</div>
            <div>Подменный</div>
            <div>Оригинальный</div>
            <div>Статус</div>
            <div>Разговор</div>
            <div>CDR лог звонка</div>
          </div>

          {this.props.calls.map(call => (
            <div className={tableStyles['tr']} key={call.id}>
              <div>{moment(call.date).format('DD.MM.YYYY HH:mm')}</div>
              <div>{formatNumber(call.caller)}</div>
              <div>
                <Link
                  target="_blank"
                  href={`/replaced_phone_history/?phone=${call.calltrackingPhone.replace('+', '%2B')}`}
                >
                  {formatNumber(call.calltrackingPhone)}
                </Link>
                <CalltrackingProvider provider={call.provider} />
              </div>
              <div>{formatNumber(call.userPhone)}</div>
              <div>{CALL_STATUS_MAP[call.status as EStatus]}</div>
              <div>
                <audio className={styles['audio']} controls>
                  <source src={`${host}${call.recordUrl}`} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
              </div>
              {call.provider === 'mts' && (
                <div
                  className={globalStyles['link']}
                  onClick={() => {
                    this.onShowCdrLogClick(call.cdrLog || '');
                  }}
                >
                  Показать
                </div>
              )}
            </div>
          ))}
        </div>
        <div className={styles['pagination']}>
          <PaginationWidget pages={pages} onSelect={this.onPageChange} />
        </div>
      </ContentWrapper>
    );
  }

  private onShowCdrLogClick = (cdrLog: string) => {
    this.setState({
      cdrLogModalMessage: cdrLog,
      isCdrLogModalOpen: true,
    });
  };

  private handleCdrLogModalClose = () => {
    this.setState({
      isCdrLogModalOpen: false,
    });
  };

  private onFilterError = (errors: IServerErrorItem[]) => {
    this.setState({ errors });
  };

  private getCalls = ({
    userId,
    phone,
    requiredLimit,
    offset = 0,
  }: {
    userId?: number;
    phone?: string;
    requiredLimit?: number;
    offset?: number;
  }) => {
    const { httpApi } = this.props;

    const { pageSize } = this.state;
    const limit = typeof requiredLimit === 'number' ? requiredLimit : pageSize;

    if (!userId) {
      return Promise.reject([{ key: '', code: 'userId required', message: 'Введите Id пользователя' }]);
    }

    return getCalls(httpApi, { userId, phone, limit, offset } as IGetUserCallsRequest);
  };

  private onCallsSuccess = (calls: ICallSchema[], totalCount: number, pageSize?: number, current: number = 1) => {
    const { onCallsSuccess } = this.props;
    const actualPageSize = pageSize || this.state.pageSize;

    this.setState({
      pages: this.calculatePages(totalCount, actualPageSize, current),
      totalItems: totalCount,
    });

    onCallsSuccess(calls);
  };

  private onPageChange = (nextPage: IPaginationItem) => {
    const { pageSize } = this.state;
    const { filter } = this.props;

    this.getCalls({
      userId: filter.userId || undefined,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      phone: (filter as any).phone,
      requiredLimit: pageSize,
      offset: (nextPage.id - 1) * pageSize,
    })
      .then(({ calls, totalCount }) => {
        this.onCallsSuccess(calls, totalCount, pageSize, nextPage.id);
      })
      .catch((errors: IServerErrorItem[]) => this.setState({ errors }));
  };

  private onEntitiesCountChange = (pageSize: string) => {
    const { filter } = this.props;
    const pageSizeNumber = Number(pageSize);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.getCalls({ userId: filter.userId || undefined, phone: (filter as any).phone, requiredLimit: pageSizeNumber })
      .then(({ calls, totalCount }) => {
        this.onCallsSuccess(calls, totalCount, pageSizeNumber, 1);
        this.setState({ pageSize: pageSizeNumber });
      })
      .catch((errors: IServerErrorItem[]) => this.setState({ errors }));
  };

  private calculatePages = (count: number, step: number = 20, current: number = 1) => {
    return Array.from({ length: Math.max(Math.ceil(count / step), 1) }).map(
      (page, index): IPaginationItem => ({
        id: index + 1,
        isActive: index + 1 === current,
        text: `${index + 1}`,
        url: `page=${index + 1}`,
      }),
    );
  };

  private clearErrors = () => {
    this.setState({ errors: null });
  };
}
