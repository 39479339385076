/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TUnblockUserPhoneModel,
  IUnblockUserPhoneRequest,
  IMappers,
  IUnblockUserPhoneResponse,
  IUnblockUserPhoneResponseError,
  TUnblockUserPhoneResponse,
} from './types';

const defaultConfig: TUnblockUserPhoneModel = {
  apiType: 'public',
  assertStatusCodes: [400, 200, 204],
  method: 'POST',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/unblock-user-phone/',
  hostType: 'api',
} as TUnblockUserPhoneModel;

function createUnblockUserPhoneModel(parameters: IUnblockUserPhoneRequest): TUnblockUserPhoneModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IUnblockUserPhoneOptions<TResponse400, TResponse200, TResponse204> {
  httpApi: IHttpApi;
  parameters: IUnblockUserPhoneRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400, TResponse200, TResponse204>;
}

async function fetchUnblockUserPhone<TResponse400, TResponse200, TResponse204>({
  httpApi,
  parameters,
  config,
  mappers,
}: IUnblockUserPhoneOptions<TResponse400, TResponse200, TResponse204>): Promise<
  TResponse400 | TResponse200 | TResponse204 | TUnblockUserPhoneResponse
> {
  const { response, statusCode } = await httpApi.fetch(createUnblockUserPhoneModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as IUnblockUserPhoneResponseError);
    }

    if (statusCode === 200) {
      return mappers[200](response as IUnblockUserPhoneResponse);
    }

    if (statusCode === 204) {
      return mappers[204]();
    }
  }

  return { response, statusCode } as TUnblockUserPhoneResponse;
}

export { defaultConfig, createUnblockUserPhoneModel, fetchUnblockUserPhone };
