import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../../redux/types';

import { ISearchResultStoreProps, ISearchResultDispatchProps, SearchResultPresenter } from '.';
import {
  TActions,
  blockCalltrackingPhoneAction,
  IThunkExtraArgument,
  activateCalltrackingXmlAction,
  deactivateCalltrackingXmlAction,
} from '../../redux';
import { IModalParams, toggleBlockModal } from '../../redux/modals';
import { setBlockStatus } from '../../redux/userPhone';
import { IUserPhoneParams } from '../../redux/userPhone/types';

export function mapStateToProps(state: IAppState): ISearchResultStoreProps {
  const {
    searchType,
    users,
    offers,
    usersHaveBeenSearched,
    offersHaveBeenSearched,
    showForceCalltrackingCheckbox,
    showOfferForceCalltrackingCheckbox,
  } = state.search;

  return {
    searchType,
    users,
    offers,
    usersHaveBeenSearched,
    offersHaveBeenSearched,
    showForceCalltrackingCheckbox,
    showOfferForceCalltrackingCheckbox,
    blockModal: state.blockModal,
    userPhoneBlockStatus: state.userPhoneBlockStatus,
  };
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>,
): ISearchResultDispatchProps {
  return {
    toggleBlockModal: (params: IModalParams) => dispatch(toggleBlockModal(params)),
    setBlockStatus: (params: IUserPhoneParams) => dispatch(setBlockStatus(params)),
    blockCalltrackingPhone: (calltrackingPhone: string) =>
      dispatch(
        blockCalltrackingPhoneAction({
          calltrackingPhone,
        }),
      ),
    activateCalltrackingXml: (userId: number, userName?: string) =>
      dispatch(activateCalltrackingXmlAction(userId, userName)),
    deactivateCalltrackingXml: (userId: number, userName?: string) =>
      dispatch(deactivateCalltrackingXmlAction(userId, userName)),
  };
}

export const SearchResultPresenterContainer = connect<ISearchResultStoreProps, ISearchResultDispatchProps>(
  mapStateToProps,
  mapDispatchToProps,
)(SearchResultPresenter);
