import { IGetBlockedUserPhoneInfoResponse } from '../../repositories/calltracking-core/v1/get-blocked-user-phone-info';
import { IBlockedUserPhoneInfoSchema } from '../../repositories/calltracking-core/entities/entities/BlockedUserPhoneInfoSchema';

export interface IBlockedUserPhoneInfoParams {
  data?: IBlockedUserPhoneInfoSchema | null;
  isBlocked: boolean;
}

export function mapBlockedUserInfoResponse(response: IGetBlockedUserPhoneInfoResponse): IBlockedUserPhoneInfoParams {
  return {
    data: response.data,
    isBlocked: response.isBlocked,
  };
}
