import * as React from 'react';

import { Button, PhoneField } from '@cian/components';
import { IServerErrorItem } from '../../api/types';
import { ContentWrapper } from '../content_wrapper';

import * as styles from './index.css';

export interface INoPhoneForHistoryState {
  phone: string;
  errors: IServerErrorItem[] | null;
}

export class NoPhoneForHistory extends React.Component<{}, INoPhoneForHistoryState> {
  public constructor(props: {}) {
    super(props);

    this.state = {
      errors: null,
      phone: '',
    };
  }

  public render() {
    const { phone, errors } = this.state;

    const isButtonDisabled = phone ? phone.length < 11 : true;

    return (
      <ContentWrapper title="История подменного номера" errors={errors} clearErrors={this.clearErrors}>
        <div className={styles['container']}>
          <div className={styles['phone-field']}>
            <div className={styles['phone-field_label']}>Номер</div>
            <div className={styles['phone-field_input']}>
              <PhoneField mobile={true} value={phone || ''} onValueChange={this.onPhoneChange} />
            </div>
          </div>

          <div className={styles['apply-button']}>
            <Button theme="button/solid" onClick={this.handlePhoneClick} disabled={isButtonDisabled}>
              Показать
            </Button>
          </div>
        </div>
      </ContentWrapper>
    );
  }

  private onPhoneChange = (phone: string) => {
    this.setState({
      phone,
    });
  };

  private handlePhoneClick = () => {
    window.location.href = `/replaced_phone_history/?phone=%2B${this.state.phone}`;
  };

  private clearErrors = () => {
    this.setState({ errors: null });
  };
}
