import { callsReducer } from './calls';
import { calltrackingUsersReducer } from './calltracking_users';
import { replacedPhoneHistoryReducer } from './replaced_phones';
import { searchReducer } from './search';
import { phonesReducer } from './phones';
import { IAppStore } from './store';
import { toggleRegionReducer } from './toggle_region';
import { userPhoneReducer } from './userPhone';
import { modalsReducer } from './modals';

export function attachReducers(store: IAppStore) {
  store.addReducer(callsReducer);
  store.addReducer(calltrackingUsersReducer);
  store.addReducer(replacedPhoneHistoryReducer);
  store.addReducer(searchReducer);
  store.addReducer(phonesReducer);
  store.addReducer(toggleRegionReducer);
  store.addReducer(userPhoneReducer);
  store.addReducer(modalsReducer);
}
