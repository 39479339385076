import { ThunkAction } from 'redux-thunk';
import { IAppState, IThunkExtraArgument } from '../types';
import { hideUserPhoneData, setBlockStatus, setError, setUnblockStatus, setUserPhoneData } from './actions';
import { fetchBlockUserPhone } from '../../repositories/calltracking-core/v1/block-user-phone';
import { IResponse400, mappers } from './mappers';
import { fetchUnblockUserPhone } from '../../repositories/calltracking-core/v1/unblock-user-phone';
import { toggleBlockModal, toggleUnblockModal } from '../modals';
import { TActions } from '../actions';
import {
  createGetBlockedUserPhoneInfoModel,
  IGetBlockedUserPhoneInfoResponse,
} from '../../repositories/calltracking-core/v1/get-blocked-user-phone-info';
import { IUserPhoneParams } from './types';
import { mapBlockedUserInfoResponse } from '../../mappers/transformBlockedUserPhoneInfoResponse';

export function blockUserPhone(
  reason: string,
  userPhone: string,
): ThunkAction<void, IAppState, IThunkExtraArgument, TActions> {
  return async (dispatch, getState, { httpApi }: IThunkExtraArgument) => {
    try {
      const response = await fetchBlockUserPhone<IResponse400, {}, {}>({
        httpApi,
        mappers: mappers(),
        parameters: { reason, userPhone },
      });
      dispatch(setBlockStatus(response as IUserPhoneParams));
      dispatch(toggleBlockModal({ show: false, phone: userPhone }));
    } catch (e) {
      dispatch(toggleBlockModal({ show: false, phone: userPhone }));
      dispatch(setBlockStatus({ error: 'Не удалось заблокировать номер клиента' }));
    }
  };
}

export function unblockUserPhone(
  reason: string,
  userPhone: string,
): ThunkAction<void, IAppState, IThunkExtraArgument, TActions> {
  return async (dispatch, getState, { httpApi }: IThunkExtraArgument) => {
    try {
      const response = await fetchUnblockUserPhone<IResponse400, {}, {}>({
        httpApi,
        mappers: mappers(),
        parameters: { reason, userPhone },
      });

      dispatch(toggleUnblockModal({ show: false, phone: userPhone }));
      dispatch(setUnblockStatus(response as IUserPhoneParams));
    } catch (e) {
      dispatch(toggleUnblockModal({ show: false, phone: userPhone }));
      dispatch(setUnblockStatus({ error: 'Не удалось разблокировать номер клиента' }));
    }
  };
}

export function checkUserPhone(userPhone: string): ThunkAction<void, IAppState, IThunkExtraArgument, TActions> {
  return async (dispatch, getState, { httpApi }: IThunkExtraArgument) => {
    const errorMessage = 'Что-то пошло не так';
    try {
      dispatch(hideUserPhoneData());
      const { response, statusCode } = await httpApi.fetch(createGetBlockedUserPhoneInfoModel({ userPhone }));

      if (statusCode === 400) {
        dispatch(setError(errorMessage));

        return;
      }

      const data = mapBlockedUserInfoResponse(response as IGetBlockedUserPhoneInfoResponse);
      dispatch(setUserPhoneData(data));
    } catch (e) {
      dispatch(setError(errorMessage));
    }
  };
}
