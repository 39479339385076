import * as React from 'react';

import { DropdownField, IDropdownValue } from '@cian/components';

import * as styles from './index.css';

export interface IEntitiesCountSelectorProps {
  count: string;
  steps: IDropdownValue[];
  onCountChange(count: string): void;
}

export class EntitiesCountSelector extends React.Component<IEntitiesCountSelectorProps, {}> {
  public render() {
    return (
      <div className={styles['container']}>
        <span className={styles['label']}>Показывать: </span>
        <DropdownField value={this.props.count} values={this.props.steps} onValueChange={this.props.onCountChange} />
      </div>
    );
  }
}
