export interface IToggleRegionState {
  regionId: number | null;
  result: string | null;
}

export enum EToggleRegionActionType {
  setRegionId = 'toggle_region/SET_REGION_ID',
  showResult = 'toggle_region/SHOW_RESULT',
}

export interface ISetRegionIdAction {
  type: EToggleRegionActionType.setRegionId;
  regionId: number | null;
}

export interface IShowResultAction {
  type: EToggleRegionActionType.showResult;
  result: string | null;
}

export type TToggleRegionAction = ISetRegionIdAction | IShowResultAction;
