import { applyMiddleware, compose, createStore, Reducer, Store } from 'redux';

import thunk from 'redux-thunk';

import { IHttpApi } from '@cian/http-api/shared/http';
import { ILogger } from '@cian/logger';

import { TActions } from './actions';
import { IAppState, ISerializableAppState } from './types';
import { IConfig } from '@cian/config/shared';

interface ICustomReducer extends Reducer<IAppState> {
  addReducer(reducer: Reducer<IAppState>): void;
}

export function createCustomReducer(): ICustomReducer {
  const subReducers: Reducer<IAppState>[] = [];

  const reducer = ((state: IAppState, action: TActions) => {
    return subReducers.reduce((acc, subReducer) => {
      return subReducer(acc, action);
    }, state);
  }) as ICustomReducer;

  reducer.addReducer = (subReducer: Reducer<IAppState>) => {
    subReducers.push(subReducer);
  };

  return reducer;
}

export interface IAppStore extends Store<IAppState> {
  addReducer(reducer: Reducer<IAppState>): void;
  serialize(): ISerializableAppState;
}

export const createAppStore = (
  httpApi: IHttpApi,
  logger: ILogger,
  config: IConfig,
  initialState: ISerializableAppState,
): IAppStore => {
  const state = Object.assign({}, initialState, {
    config,
    httpApi,
    logger,
  });

  const customReducer = createCustomReducer();

  // tslint:disable-next-line:strict-type-predicates TSLint думает, что window всегда объект
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      : compose;

  const store = createStore(
    customReducer,
    state,
    composeEnhancers(applyMiddleware(thunk.withExtraArgument({ httpApi, logger }))),
  );

  const addReducer = (reducer: Reducer<IAppState>) => {
    customReducer.addReducer(reducer);
  };

  const serialize = (): ISerializableAppState => {
    const currentState = Object.assign({}, store.getState());
    delete currentState.httpApi;
    delete currentState.logger;
    delete currentState.config;

    return currentState;
  };

  return { ...store, addReducer, serialize };
};
