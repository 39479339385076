import { ThunkAction } from 'redux-thunk';
import { IAppState, IThunkExtraArgument } from '../types';
import { TToggleRegionAction } from './types';
import { showResult } from './actions';
import { fetchDisableRegion, IDisableRegionRequest } from '../../repositories/calltracking-core/v1/disable-region';
import { fetchEnableRegion, IEnableRegionRequest } from '../../repositories/calltracking-core/v1/enable-region';

export function disableRegion(): ThunkAction<void, IAppState, IThunkExtraArgument, TToggleRegionAction> {
  return async (dispatch, getState, { httpApi }) => {
    const {
      toggleRegion: { regionId },
    } = getState();
    if (regionId === null) {
      throw 'regionId must be number';
    }
    const parameters: IDisableRegionRequest = { regionId };
    try {
      const { response } = await fetchDisableRegion({ httpApi, parameters });
      dispatch(
        showResult(
          (() => {
            switch (response.status) {
              case 'ok':
                return 'Регион выключен!';
              case 'alreadyDisabled':
                return 'Регион уже выключен!';
              case 'roleRequired':
                return 'Для выполнения этого действия обратитесь к разработчикам Вторичного направления недвижимости.';
              default:
                return 'Неизвестный овтет!';
            }
          })(),
        ),
      );
    } catch (error) {
      dispatch(showResult('Что-то пошло не так'));
    }
  };
}

export function enableRegion(): ThunkAction<void, IAppState, IThunkExtraArgument, TToggleRegionAction> {
  return async (dispatch, getState, { httpApi }) => {
    const {
      toggleRegion: { regionId },
    } = getState();
    if (!regionId || !Number.isFinite(regionId)) {
      throw 'regionId must be number';
    }
    const parameters: IEnableRegionRequest = { regionId };
    try {
      const { response } = await fetchEnableRegion({ httpApi, parameters });
      dispatch(
        showResult(
          (() => {
            switch (response.status) {
              case 'ok':
                return 'Регион включен!';
              case 'notDisabled':
                return 'Регион не выключен!';
              case 'roleRequired':
                return 'Для выполнения этого действия обратитесь к разработчикам Вторичного направления недвижимости.';
              default:
                return 'Неизвестный овтет!';
            }
          })(),
        ),
      );
    } catch (errors) {
      dispatch(showResult('Что-то пошло не так'));
    }
  };
}
