import * as React from 'react';

import { Preloader } from '@cian/preloader-component';

import { formatNumber } from '../../utils/number';

import * as globalStyles from '../../index.css';
import * as styles from './CheckPhoneTool.css';

export interface ICheckPhoneToolProps {
  id: number;
  calltrackingPhone: string;
  isProviderRedirectPhoneRequested?: boolean;
  isProviderRedirectPhoneFetching?: boolean;
  isValid?: boolean;
  actualPhone?: string;

  onClick(id: number, calltrackingPhone: string): void;
}

export class CheckPhoneTool extends React.Component<ICheckPhoneToolProps> {
  public render() {
    const {
      id,
      calltrackingPhone,
      isProviderRedirectPhoneRequested,
      isProviderRedirectPhoneFetching,
      isValid,
      actualPhone,
      onClick,
    } = this.props;

    if (isProviderRedirectPhoneRequested) {
      if (isProviderRedirectPhoneFetching) {
        return <Preloader />;
      }

      return (
        <span className={isValid ? styles['phone-valid'] : styles['phone-invalid']}>
          {formatNumber(actualPhone || '')}
        </span>
      );
    }

    return (
      <span
        className={globalStyles['link']}
        onClick={() => {
          onClick(id, calltrackingPhone);
          this.forceUpdate();
        }}
      >
        Проверить
      </span>
    );
  }
}
