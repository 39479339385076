import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState, IThunkExtraArgument, TActions, fetchProviderPhoneRedirect } from '../redux';
import { Phones, IPhonesProps } from '../components/phones/Phones';
import { IPhoneLinkSchema } from '../repositories/calltracking-core/entities/get_user_phone_links/PhoneLinkSchema';

export interface IStateProps {
  phones: IPhoneLinkSchema[];
}

export interface IDispatchProps {
  fetchProviderPhoneRedirect(id: number, calltrackingPhone: string): void;
}

export function mapStateToProps(state: IAppState): IStateProps {
  const { phones } = state.phones;

  return { phones: phones as IPhoneLinkSchema[] };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>): IDispatchProps {
  return {
    fetchProviderPhoneRedirect: (id: number, calltrackingPhone: string) =>
      dispatch(fetchProviderPhoneRedirect(id, calltrackingPhone)),
  };
}

export function mergeProps(stateProps: IStateProps, dispatchProps: IDispatchProps): IPhonesProps {
  const { phones } = stateProps;
  const { fetchProviderPhoneRedirect } = dispatchProps;

  return {
    phones,
    onCheckPhoneClick: (id: number, calltrackingPhone: string) => {
      fetchProviderPhoneRedirect(id, calltrackingPhone);
    },
  };
}

export const PhonesContainer = connect<IStateProps, IDispatchProps, {}, IPhonesProps>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(Phones);
