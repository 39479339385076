import * as React from 'react';

import { Button, NumberField, PhoneField } from '@cian/components';
import { IHttpApi } from '@cian/http-api/shared/http';

import { IServerErrorItem } from '../../api/types';
import { IGetUserCallsRequest, IGetUserCallsResponse } from '../../repositories/calltracking-core/v1/get-user-calls';
import { ICallSchema } from '../../repositories/calltracking-core/entities/entities/CallSchema';

import * as styles from './index.css';

export const CALLS_STATUSES = [
  { value: 'busy', label: 'Занято' },
  { value: 'hgup', label: 'Сбросили' },
  { value: 'hgup_ab', label: 'Вызываемый абонент сбросил' },
  { value: 'hgup_op', label: 'Звонящий положил трубку' },
  { value: 'unknown', label: 'Неизвестно' },
  { value: 'success', label: 'Успешный' },
  { value: 'no_answer', label: 'Без ответа' },
  { value: 'turned_off', label: 'Выключен' },
  { value: 'tech_problem', label: 'Техническая ошибка' },
];

export interface ICallsFilterStoreProps {
  httpApi: IHttpApi;
  userId?: number;
  phone?: string;
  isPhoneEnabled?: boolean;
}

export interface ICallsFilterDispatchProps {
  onFilterApply(filter: IGetUserCallsRequest): void;
}

export interface IGetCallsParams {
  userId?: number;
  phone?: string;
  limit?: number;
  offset?: number;
}

export interface ICallsFilterFilterOwnProps {
  getCalls(params: IGetCallsParams): Promise<IGetUserCallsResponse>;
  onFilterError(errors: IServerErrorItem[]): void;
  onCallsSuccess(calls: ICallSchema[], totalCount: number, pageSize?: number, current?: number): void;
}

export interface ICallsFilterState {
  userId?: number;
  phone?: string;
}

export type ICallsProps = IGetUserCallsRequest &
  ICallsFilterStoreProps &
  ICallsFilterDispatchProps &
  ICallsFilterFilterOwnProps;

export class CallsFilter extends React.Component<ICallsProps, ICallsFilterState> {
  public constructor(props: ICallsProps) {
    super(props);

    const { userId, phone, isPhoneEnabled } = props;

    this.state = {
      userId,
      phone: isPhoneEnabled ? phone : undefined,
    };
  }

  public componentDidMount() {
    const { userId, phone, isPhoneEnabled, getCalls, onCallsSuccess, onFilterApply } = this.props;

    if (userId || phone) {
      onFilterApply({
        userId,
        phone: isPhoneEnabled ? phone : undefined,
      } as IGetUserCallsRequest);

      getCalls({ userId, phone: isPhoneEnabled ? phone : undefined }).then(({ calls, totalCount }) =>
        onCallsSuccess(calls, totalCount),
      );
    }
  }

  public render() {
    const { userId, phone } = this.state;
    const { isPhoneEnabled } = this.props;

    return (
      <div className={styles['container']}>
        <div className={styles['filter-param']}>
          <div className={styles['filter-label']}>ID</div>
          <div className={styles['filter-input']}>
            <NumberField disablePrettyFormating value={userId} onValueChange={this.onIdChange} />
          </div>
        </div>
        {isPhoneEnabled && (
          <div className={styles['filter-param']}>
            <div className={styles['filter-label']}>Номер телефона</div>
            <div className={styles['filter-input']}>
              <PhoneField mobile={true} value={phone || ''} onValueChange={this.onPhoneChange} />
            </div>
          </div>
        )}

        <div className={styles['apply-filters-button']}>
          <Button theme="button/solid" onClick={this.onApplyButtonClick}>
            Показать
          </Button>
        </div>
      </div>
    );
  }

  private onIdChange = (userId: number) => {
    this.setState({
      userId,
    });
  };

  private onPhoneChange = (phone: string) => {
    this.setState({
      phone,
    });
  };

  private onApplyButtonClick = () => {
    const { userId, phone } = this.state;
    const { isPhoneEnabled, onFilterApply, getCalls, onCallsSuccess } = this.props;

    onFilterApply({
      userId,
      phone: isPhoneEnabled ? phone : undefined,
    } as IGetUserCallsRequest);

    getCalls({ userId, phone: isPhoneEnabled ? phone : undefined })
      .then(({ calls, totalCount }) => onCallsSuccess(calls, totalCount))
      .catch((errors: IServerErrorItem[]) => this.props.onFilterError(errors));
  };
}
