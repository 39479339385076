import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { CallsFilter, ICallsFilterDispatchProps, ICallsFilterFilterOwnProps, ICallsFilterStoreProps } from '.';
import { setCallsFilters, TActions } from '../../redux/actions';
import { IAppState } from '../../redux/types';
import { IGetUserCallsRequest } from '../../repositories/calltracking-core/v1/get-user-calls';

export function mapStateToProps(state: IAppState): ICallsFilterStoreProps {
  return {
    httpApi: state.httpApi,
    userId: (state.calls && state.calls.filter.userId) || undefined,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    phone: (state.calls && (state.calls.filter as any).phone) || undefined,
    isPhoneEnabled: state.config.get('isPhoneFilterInCallsEnabled') || false,
  };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, object, TActions>): ICallsFilterDispatchProps {
  return {
    onFilterApply: (filter: IGetUserCallsRequest) => dispatch(setCallsFilters(filter)),
  };
}

export const CallsFilterContainer = connect<
  ICallsFilterStoreProps,
  ICallsFilterDispatchProps,
  ICallsFilterFilterOwnProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(CallsFilter);
