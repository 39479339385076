import * as React from 'react';

import { Link } from '@cian/link-component';
import { ModalWrapper, Modal } from '@cian/modal-component';
import { Button, ButtonTheme } from '@cian/button-component';

import { CalltrackingXmlToggledModalContainer } from '../../containers/calltrackingXmlToggledModalContainer';

import { ISearchUserSchema } from '../../redux/search';
import { ForceCalltrackingCheckbox } from '../force_calltracking_checkbox';
import { ECalltrackingXmlStatus } from '../../repositories/calltracking-core/entities/entities/UserSchema';

import * as globalStyles from '../../index.css';
import * as tableStyles from '../data_table/index.css';
import * as styles from './SearchUsersResultPresenter.css';

const CALLTRACKING_XML_STATUS_TEXT_MAP: { [index: string]: string } = {
  active: 'Включен',
  inactive: 'Выключен',
  unavailable: 'Недоступен',
};

export interface ISearchUsersResultPresenterProps {
  showForceCalltrackingCheckbox: boolean;
  users: ISearchUserSchema[];
  onActivateCalltrackingXmlClick(userId: number, userName?: string): void;
  onDeactivateCalltrackingXmlClick(userId: number, userName?: string): void;
}

export interface ISearchUsersResultPresenterState {
  isCalltrackingXmlModalOpen: boolean;
  calltrackingXmlUserId?: number | undefined;
  calltrackingXmlUserName?: string;
  calltrackingXmlStatus?: ECalltrackingXmlStatus;
}

export class SearchUsersResultPresenter extends React.Component<
  ISearchUsersResultPresenterProps,
  ISearchUsersResultPresenterState
> {
  public constructor(props: ISearchUsersResultPresenterProps) {
    super(props);

    this.state = {
      isCalltrackingXmlModalOpen: false,
      calltrackingXmlUserId: undefined,
      calltrackingXmlUserName: undefined,
      calltrackingXmlStatus: undefined,
    };
  }

  public render() {
    const { users, showForceCalltrackingCheckbox } = this.props;

    return (
      <div>
        {<CalltrackingXmlToggledModalContainer />}
        {this.renderCalltrackingXmlConfirmModal()}
        <div className={tableStyles['table']}>
          <div className={tableStyles['th']}>
            <div>Клиент ID</div>
            <div>Клиент</div>
            <div>КТ в XML</div>
            <div>Телефоны</div>
            {showForceCalltrackingCheckbox && <div>Принудительно</div>}
          </div>

          {users.map(
            ({
              cianUserId,
              userId,
              calltrackingXmlStatus,
              calltrackingXmlStatusCanBeChanged,
              agencyName,
              phoneCount,
              forceCalltrackingSession,
            }) => (
              <div className={tableStyles['tr']} key={`${userId}`}>
                <div>{this.renderUserCallsLink(cianUserId)}</div>
                <div>{this.renderUserLink(cianUserId, agencyName as string | undefined)}</div>
                <div>
                  {this.renderCalltrackingXmlStatus(
                    userId,
                    calltrackingXmlStatusCanBeChanged,
                    agencyName as string | undefined,
                    calltrackingXmlStatus as ECalltrackingXmlStatus | undefined,
                  )}
                </div>
                <div>
                  <Link href={`/phones?userId=${cianUserId}`} target="_blank">
                    {phoneCount}
                  </Link>
                </div>
                {showForceCalltrackingCheckbox && (
                  <ForceCalltrackingCheckbox forceCalltrackingSession={forceCalltrackingSession} />
                )}
              </div>
            ),
          )}
        </div>
      </div>
    );
  }

  private renderCalltrackingXmlConfirmModal = () => {
    const {
      isCalltrackingXmlModalOpen,
      calltrackingXmlStatus,
      calltrackingXmlUserId,
      calltrackingXmlUserName,
    } = this.state;

    if (!isCalltrackingXmlModalOpen) {
      return null;
    }

    const actionText = calltrackingXmlStatus === 'active' ? 'Отключить' : 'Подключить';
    const onClickHandler =
      calltrackingXmlStatus === 'active'
        ? this.handleDeactivateCalltrackingXmlClick
        : this.handleActivateCalltrackingXmlClick;

    return (
      <ModalWrapper onClose={this.handleModalClose}>
        <Modal minHeight={220} minWidth={500}>
          <div className={styles['modal-header']}>
            {actionText} коллтрекинг в XML для клиента {calltrackingXmlUserName || calltrackingXmlUserId} ?
          </div>
          <div className={styles['modal-buttons']}>
            <Button className={styles['confirm-button']} onClick={onClickHandler}>
              {actionText}
            </Button>
            <Button theme={ButtonTheme.Ghost} onClick={this.handleModalClose}>
              Отменить
            </Button>
          </div>
        </Modal>
      </ModalWrapper>
    );
  };

  private handleActivateCalltrackingXmlClick = () => {
    const { onActivateCalltrackingXmlClick } = this.props;
    const { calltrackingXmlUserId, calltrackingXmlUserName } = this.state;

    if (calltrackingXmlUserId) {
      onActivateCalltrackingXmlClick(calltrackingXmlUserId, calltrackingXmlUserName);
    }

    this.handleModalClose();
  };

  private handleDeactivateCalltrackingXmlClick = () => {
    const { onDeactivateCalltrackingXmlClick } = this.props;
    const { calltrackingXmlUserId, calltrackingXmlUserName } = this.state;

    if (calltrackingXmlUserId) {
      onDeactivateCalltrackingXmlClick(calltrackingXmlUserId, calltrackingXmlUserName);
    }

    this.handleModalClose();
  };

  private handleModalClose = () => {
    this.setState({ isCalltrackingXmlModalOpen: false });
  };

  private renderCalltrackingXmlStatus = (
    userId: number,
    calltrackingXmlStatusCanBeChanged: boolean,
    userName?: string,
    calltrackingXmlStatus?: ECalltrackingXmlStatus,
  ) => {
    if (calltrackingXmlStatusCanBeChanged) {
      return (
        <span
          className={globalStyles['link']}
          onClick={() => {
            this.handleCalltrackingXmlToggle(userId, userName, calltrackingXmlStatus);
          }}
        >
          {calltrackingXmlStatus && this.getCalltrackingXmlStatusText(calltrackingXmlStatus)}
        </span>
      );
    }

    return <span>{this.getCalltrackingXmlStatusText('unavailable')}</span>;
  };

  private handleCalltrackingXmlToggle = (userId: number, userName?: string, status?: ECalltrackingXmlStatus) => {
    this.setState({
      isCalltrackingXmlModalOpen: true,
      calltrackingXmlUserId: userId,
      calltrackingXmlStatus: status,
    });
  };

  private getCalltrackingXmlStatusText = (status: string) => {
    return CALLTRACKING_XML_STATUS_TEXT_MAP[status];
  };

  private renderUserCallsLink = (userId: number) => {
    return (
      <span>
        <Link href={`/calls?userId=${userId}`} target="_blank">
          {userId}
        </Link>
      </span>
    );
  };

  private renderUserLink = (userId: number, userName?: string) => {
    return (
      <span>
        <Link href={`http://service.cian.ru/customers/view/${userId}`} target="_blank">
          {userName || userId}
        </Link>
      </span>
    );
  };
}
