/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TGetUserPhonesLinksModel,
  IGetUserPhonesLinksRequest,
  IMappers,
  IGetUserPhonesLinksResponse,
  IGetUserPhonesLinksResponseError,
  TGetUserPhonesLinksResponse,
} from './types';

const defaultConfig: TGetUserPhonesLinksModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/get-user-phones-links/',
  hostType: 'api',
} as TGetUserPhonesLinksModel;

function createGetUserPhonesLinksModel(parameters: IGetUserPhonesLinksRequest): TGetUserPhonesLinksModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetUserPhonesLinksOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetUserPhonesLinksRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetUserPhonesLinks<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetUserPhonesLinksOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetUserPhonesLinksResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetUserPhonesLinksModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetUserPhonesLinksResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetUserPhonesLinksResponseError);
    }
  }

  return { response, statusCode } as TGetUserPhonesLinksResponse;
}

export { defaultConfig, createGetUserPhonesLinksModel, fetchGetUserPhonesLinks };
