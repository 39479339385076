export const BLOCK_USER_PHONE = 'MODALS/block_phone';
export const UNBLOCK_USER_PHONE = 'MODALS/unblock_phone';

export interface IModalParams {
  phone?: string;
  userId?: number;
  show: boolean;
}

interface IBlockModalToggleAction {
  type: 'MODALS/block_phone';
  payload: IModalParams;
}

export function toggleBlockModal(payload: IModalParams): IBlockModalToggleAction {
  return { type: BLOCK_USER_PHONE, payload };
}

interface IUnblockModalToggleAction {
  type: 'MODALS/unblock_phone';
  payload: IModalParams;
}

export function toggleUnblockModal(payload: IModalParams): IUnblockModalToggleAction {
  return { type: UNBLOCK_USER_PHONE, payload };
}

export type TModalsActions = IBlockModalToggleAction | IUnblockModalToggleAction;
