// tslint:disable:max-line-length
import * as React from 'react';

export const CianLogo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="80" height="29">
    <g fill="none" fillRule="evenodd">
      <path
        fill="#13334B"
        d="M60.15 13.59H60l-6.67 12.45h3.4l.84-1.7h5l.83 1.7h3.4c-2.07-4.1-4.3-8.45-6.65-12.45zm-1.47 8.3l1.4-3.06 1.41 3.06h-2.8zM38.21 29h-2.95v-2.96h-10.1V13.92h3.15v9.47H33v-9.47h3.15v9.47h2.06zm13.4-2.96h-3.14v-6.62l-7.64 6.86h-.25V13.92h3.14v6.5l7.59-6.8h.3zm24.92 0v-4.6h-4.71v4.6h-3.15V13.92h3.15v4.87h4.7v-4.87h3.16v12.12z"
      />
      <path
        fill="#458BCA"
        d="M10.6 6.21l.06.1 3.63 6.54 3.64-6.55.05-.1a4.29 4.29 0 0 0 .5-1.94A4.26 4.26 0 0 0 15.81.28a4.1 4.1 0 0 0-4.6 1.14 4.34 4.34 0 0 0-.6 4.79zm3.72-3.82c.75 0 1.44.47 1.72 1.19a1.94 1.94 0 0 1-.42 2.08c-.54.55-1.34.7-2.04.4a1.91 1.91 0 0 1-1.13-1.8 1.89 1.89 0 0 1 1.87-1.87zm1.57 17.83l2.67 2.73-3.32 3.4-5.84-5.99-5.84 5.99-3.32-3.4L9.4 13.6 12.75 17v-3.09h3.14v6.3z"
      />
    </g>
  </svg>
);
