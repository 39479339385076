/* eslint-disable */

//tslint:disable

import { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api/shared/http';
import {
  TDisableRegionModel,
  IDisableRegionRequest,
  IMappers,
  IDisableRegionResponse,
  IDisableRegionResponseError,
  TDisableRegionResponse,
} from './types';

const defaultConfig: TDisableRegionModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'calltracking-core',
  pathApi: '/v1/disable-region/',
  hostType: 'api',
} as TDisableRegionModel;

function createDisableRegionModel(parameters: IDisableRegionRequest): TDisableRegionModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IDisableRegionOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IDisableRegionRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchDisableRegion<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IDisableRegionOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TDisableRegionResponse> {
  const { response, statusCode } = await httpApi.fetch(createDisableRegionModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IDisableRegionResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IDisableRegionResponseError);
    }
  }

  return { response, statusCode } as TDisableRegionResponse;
}

export { defaultConfig, createDisableRegionModel, fetchDisableRegion };
