import { IHttpApi } from '@cian/http-api/shared/http';
import {
  fetchSearchForceCalltrackingSessionObjects,
  ISearchForceCalltrackingSessionObjectsRequest,
  ISearchForceCalltrackingSessionObjectsResponse,
} from '../repositories/moderation/v1/search-force-calltracking-session-objects';

export async function searchForceCalltrackingSessionObjects(
  httpApi: IHttpApi,
  parameters: ISearchForceCalltrackingSessionObjectsRequest,
): Promise<ISearchForceCalltrackingSessionObjectsResponse> {
  const headers: [string, string][] = [['Content-Type', 'application/json']];
  const { response, statusCode } = await fetchSearchForceCalltrackingSessionObjects({
    httpApi,
    parameters,
    config: {
      requestConfig: {
        headers,
        withCredentials: true,
      },
    },
  });

  if (statusCode !== 200 || 'errors' in response) {
    throw response;
  }

  return response;
}
