import * as React from 'react';
import * as styles from './Tab.css';
import * as globalStyles from '../../index.css';
import { mergeStyles } from '@cian/utils';

interface IProps {
  isActive: boolean;
  onClick(): void;
}

export const Tab: React.FC<IProps> = ({ children, isActive, onClick }) => {
  return (
    <div {...mergeStyles(styles['tab'], globalStyles['link'], isActive && styles['tab-active'])} onClick={onClick}>
      {children}
    </div>
  );
};
