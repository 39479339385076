import * as React from 'react';
import * as tableStyles from '../data_table/index.css';
import { CheckboxField } from '@cian/components';

export interface IForceCalltrackingCheckboxProps {
  forceCalltrackingSession: boolean;
}

export const ForceCalltrackingCheckbox: React.StatelessComponent<IForceCalltrackingCheckboxProps> = ({
  forceCalltrackingSession,
}) => (
  <div className={tableStyles['center-aligned-tr']}>
    <CheckboxField disabled={true} label="" value={forceCalltrackingSession} onValueChange={() => {}} />
  </div>
);
