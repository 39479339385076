import * as React from 'react';

import * as styles from './index.css';

export interface IMetaLabelProps {
  label: string;
  value: string | number | React.ReactElement;
}

export const MetaLabel: React.StatelessComponent<IMetaLabelProps> = props => {
  return (
    <div className={styles['container']}>
      <span className={styles['label']}>{props.label}</span>:&nbsp;
      <span className={styles['value']}>{props.value}</span>
    </div>
  );
};
