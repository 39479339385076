import { IAppState } from '../redux';
import { connect } from 'react-redux';
import { PhoneUnblockPage } from '../components/PhoneUnblock/PhoneUnblock';
import { Dispatch } from 'redux';
import { IModalParams, toggleUnblockModal } from '../redux/modals';
import { setUnblockStatus } from '../redux/userPhone';
import { IUserPhoneParams } from '../redux/userPhone/types';

export function mapStateToProps(state: IAppState) {
  return {
    httpApi: state.httpApi,
    unblockModal: state.unblockModal,
    userPhoneUnblockStatus: state.userPhoneUnblockStatus,
  };
}

export function mapDispatchToProps(dispatch: Dispatch) {
  return {
    toggleUnblockModal: (params: IModalParams) => dispatch(toggleUnblockModal(params)),
    setUnblockStatus: (params: IUserPhoneParams) => dispatch(setUnblockStatus(params)),
  };
}

export const PhoneUnblockContainer = connect(mapStateToProps, mapDispatchToProps)(PhoneUnblockPage);
