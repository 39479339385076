import * as React from 'react';
import { connect } from 'react-redux';

import { mergeStyles } from '@cian/utils/lib/shared/style';

import { IAppState, IUser } from '../../redux/types';

import { CianLogo } from './logo';

import * as styles from './index.css';

export interface IHeaderProps {
  user: IUser;
}

export type IHeaderContainerState = IHeaderProps;

export const Header = (props: IHeaderProps) => {
  const {
    user: { email },
  } = props;

  return (
    <div className={styles['nav']}>
      <div className={styles['logo']}>
        <a href="/" className={styles['link']}>
          <CianLogo />
        </a>
      </div>
      <div className={styles['item']}>
        <div {...mergeStyles(styles['link'], styles['active'])}>
          Меню
          <ul className={styles['menu']}>
            <li>
              <a href="/">Поиск</a>
            </li>
            <li>
              <a href="/calltracking_users">Управление коллтрекингом</a>
            </li>
            <li>
              <a href="/pool">Пул номеров</a>
            </li>
            <li>
              <a href="/calls">История звонков</a>
            </li>
            <li>
              <a href="/replaced_phone_history">История подменного номера</a>
            </li>
            <li>
              <a href="/phone_actions">Блокировка/Разблокировка номера</a>
            </li>
            <li>
              <a href="/toggle_region">Выключить коллтрекинг в регионе</a>
            </li>
          </ul>
        </div>
      </div>

      <div {...mergeStyles(styles['item'], styles['right'])}>
        <div className={styles['link']}>
          {email}

          <ul className={styles['menu']}>
            <li>
              <a href="http://service.cian.ru/users/logoff">Выход</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: IAppState) => ({
  user: state.user,
});

export const HeaderContainer = connect<IHeaderContainerState, {}, {}>(mapStateToProps)(Header);
