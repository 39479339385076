import * as React from 'react';
import {
  Button,
  DropdownField,
  IDropdownValue,
  Link,
  Modal,
  ModalWrapper,
} from '../../../../node_modules/@cian/components';

import { getRegions } from '../../api/regions';
import { IServerErrorItem } from '../../api/types';
import { IRegion } from '../../redux/types';
import { Close } from '../close';
import { ContentWrapper } from '../content_wrapper';

import { IHttpApi } from '@cian/http-api/shared/http';

import * as styles from './index.css';

export interface IPoolPageProps {
  httpApi: IHttpApi;
  regions: IRegion[];
  host: string;
}

export interface IPoolPageState {
  link?: string;
  selectedRegionId: number;
  isButtonDisabled: boolean;
  isSuccessSend: boolean;
  isErrorSend: boolean;
  errors: IServerErrorItem[] | null;
  regions: IRegion[];
}

export class PoolPage extends React.Component<IPoolPageProps, IPoolPageState> {
  public constructor(props: IPoolPageProps) {
    super(props);

    this.state = {
      errors: null,
      isButtonDisabled: true,
      isErrorSend: false,
      isSuccessSend: false,
      regions: props.regions,
      selectedRegionId: -1,
    };
  }

  public componentDidMount() {
    const { httpApi } = this.props;

    getRegions(httpApi, {})
      .then(({ regions }) => this.setState({ regions }))
      .catch((errors: IServerErrorItem[]) => this.setState({ errors }));
  }

  public render() {
    const { selectedRegionId, isButtonDisabled, isErrorSend, isSuccessSend, link, errors, regions } = this.state;

    const closeBtn = (
      <div onClick={this.handleModalClose}>
        <Close />
      </div>
    );

    return (
      <ContentWrapper title="Пул номеров" errors={errors} clearErrors={this.clearErrors}>
        {(isSuccessSend || isErrorSend) && (
          <ModalWrapper onClose={this.handleModalClose}>
            <Modal closeBtn={closeBtn} minHeight={180} minWidth={470}>
              <div className={styles['modal-content']}>
                {isSuccessSend && link && (
                  <div>
                    <div className={styles['modal-success-message']}>Загрузите файл по ссылке</div>
                    <Link href={link}>Скачать Excel файл</Link>
                  </div>
                )}
                {isErrorSend && 'Ошибка получения ссылки'}
              </div>
            </Modal>
          </ModalWrapper>
        )}
        <div className={styles['container']}>
          <div className={styles['region-field']}>
            <div className={styles['region-field_label']}>Регион</div>
            <div className={styles['region-field_input']}>
              <DropdownField
                placeholder="Выберите регион"
                buttonStyle={styles['dropdown-button']}
                value={selectedRegionId}
                values={regions.map(({ id, name }) => ({ value: id, label: name } as IDropdownValue))}
                onValueChange={this.onRegionChange}
              />
            </div>
          </div>
          <div className={styles['apply-button']}>
            <Button theme="button/solid" onClick={this.handleSendPoolClick} disabled={isButtonDisabled}>
              Загрузить
            </Button>
          </div>
        </div>
      </ContentWrapper>
    );
  }

  private onRegionChange = (selectedRegionId: number) => {
    this.setState({
      isButtonDisabled: !selectedRegionId,
      selectedRegionId,
    });
  };

  private handleSendPoolClick = () => {
    const { host } = this.props;
    const { selectedRegionId } = this.state;
    this.setState({
      isSuccessSend: true,
      link: `${host}/v1/get-pool-phones-report/?regionId=${selectedRegionId}`,
    });
  };

  private handleModalClose = () => {
    this.setState({
      isErrorSend: false,
      isSuccessSend: false,
    });
  };

  private clearErrors = () => {
    this.setState({ errors: null });
  };
}
