import { IHttpApi } from '@cian/http-api/shared/http';
import {
  fetchGetPhonesLinkHistory,
  IGetPhonesLinkHistoryRequest,
  IGetPhonesLinkHistoryResponse,
} from '../repositories/calltracking-core/v1/get-phones-link-history';

import { isResponseContainsKey } from '../utils/response';
import { IServerError } from './types';

export async function getReplacedPhonesHistory(
  httpApi: IHttpApi,
  parameters: IGetPhonesLinkHistoryRequest,
  cookies?: string,
) {
  try {
    const headers: [string, string][] = [['Content-Type', 'application/json']];

    if (cookies) {
      headers.push(['Cookie', cookies]);
    }

    let { response } = await fetchGetPhonesLinkHistory({ httpApi, parameters, config: { requestConfig: { headers } } });

    // Иногда с сервера приходит json с заголовком Content-Type: text/html
    if (typeof response === 'string') {
      try {
        response = JSON.parse(response || '[]'); // Если в ответ пришел не json, выведем ответ как ошибку
      } catch (e) {
        throw e;
      }
    }

    if (isResponseContainsKey<IServerError, IGetPhonesLinkHistoryResponse>(response, 'errors')) {
      throw response.message;
    }

    // TS 2.5.3 не учитывает проверку выше и непрваильно определяет тип
    return response as IGetPhonesLinkHistoryResponse;
  } catch (error) {
    throw new Error(error);
  }
}
