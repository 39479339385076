import { init as initConfig } from '@cian/config/browser';
import { init as initLogger } from '@cian/logger/browser';
import { init as initTelemetry } from '@cian/telemetry/browser';
import { init as initHttpApi } from '@cian/http-api/browser';

export function initDependencies() {
  initConfig();
  initLogger();
  initTelemetry();
  initHttpApi();
}
