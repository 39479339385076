import { IGetBlockedUserPhoneInfoResponse } from '../../repositories/calltracking-core/v1/get-blocked-user-phone-info';

export enum EUserPhoneActionType {
  Block = 'USER_PHONE :: BLOCK',
  Unblock = 'USER_PHONE :: UNBLOCK',
  SetData = 'USER_PHONE :: SET_DATA',
  SetError = 'USER_PHONE :: SET_ERROR',
  HideData = 'USER_PHONE :: HIDE_DATA',
}

export interface IUserPhoneParams {
  error?: string;
}

export interface IBlockUserPhoneAction {
  type: EUserPhoneActionType.Block;
  payload: IUserPhoneParams;
}

export interface IUnblockUserPhoneAction {
  type: EUserPhoneActionType.Unblock;
  payload: IUserPhoneParams;
}

export interface ISetBlockedUserPhoneDataAction {
  type: EUserPhoneActionType.SetData;
  payload: IGetBlockedUserPhoneInfoResponse;
}

export interface ISetErrorAction {
  type: EUserPhoneActionType.SetError;
  message: string;
}

export interface IHideUserPhoneDataAction {
  type: EUserPhoneActionType.HideData;
}
