import * as React from 'react';

import { Button, ButtonTheme, Modal, ModalWrapper } from '@cian/components';
import { IHttpApi } from '@cian/http-api/shared/http';
import { removeUserPhone } from '../../api/phone_block';
import { IServerErrorItem } from '../../api/types';
import { Close } from '../close';

import * as styles from './styles.css';
import { IModalParams } from '../../redux/modals';
import { BlockPhoneModalContainer } from '../../containers/blockPhoneModalContainer';
import { PhoneResultModal } from '../phone_result_modal/PhoneResultModal';
import { PhoneInputBlock } from '../PhoneInputBlock/PhoneInputBlock';
import { IUserPhoneParams } from '../../redux/userPhone/types';

export interface IPhoneBlockProps {
  httpApi: IHttpApi;
  blockModal: IModalParams;
  userPhoneBlockStatus: IUserPhoneParams;
  toggleBlockModal(params: IModalParams): void;
  setBlockStatus(params: IUserPhoneParams): void;
  onError(errors: IServerErrorItem[] | null): void;
}

export interface IPhoneBlockPageState {
  phone: string;
  userPhone: string;
  isModalVisible: boolean;
  isResponseModalVisible: boolean;
}

export class PhoneBlockPage extends React.Component<IPhoneBlockProps, IPhoneBlockPageState> {
  public constructor(props: IPhoneBlockProps) {
    super(props);

    this.state = {
      isModalVisible: false,
      isResponseModalVisible: false,
      phone: '',
      userPhone: '',
    };
  }

  public render() {
    const { phone, userPhone, isModalVisible, isResponseModalVisible } = this.state;
    const { blockModal, userPhoneBlockStatus } = this.props;
    const { show } = blockModal;
    const { error } = userPhoneBlockStatus;

    const closeResponseBtn = (
      <div onClick={this.handleResponseModalClose}>
        <Close />
      </div>
    );

    const isButtonDisabled = phone ? phone.length < 11 : true;
    const isUserButtonDisabled = userPhone ? userPhone.length < 11 : true;

    return (
      <React.Fragment>
        {isModalVisible && (
          <ModalWrapper>
            <Modal minHeight={220} minWidth={500}>
              <div className={styles['modal-header']}>Заблокировать подменный номер +{phone} ?</div>
              <div className={styles['modal-text']}>
                Этот подменный номер никогда больше не будет выдан никакому клиенту.
                <br />
                Вместо +{phone} будет назначен новый подменный номер.
              </div>
              <div className={styles['modal-buttons']}>
                <Button
                  type="button"
                  buttonStyle={styles['success-button']}
                  theme="button/solid"
                  onClick={this.handleRemovePhoneModalClick}
                >
                  Заблокировать
                </Button>
                <Button type="button" theme={ButtonTheme.BLUE} onClick={this.handleModalClose}>
                  Отменить
                </Button>
              </div>
            </Modal>
          </ModalWrapper>
        )}
        {isResponseModalVisible && (
          <ModalWrapper onClose={this.handleResponseModalClose}>
            <Modal closeBtn={closeResponseBtn} minHeight={220} minWidth={500}>
              <div className={styles['modal-header']}>Номер успешно удален из списка</div>
              <div className={styles['modal-buttons']}>
                <Button
                  type="button"
                  buttonStyle={styles['success-button']}
                  theme="button/solid"
                  onClick={this.handleResponseModalClose}
                >
                  Ок
                </Button>
              </div>
            </Modal>
          </ModalWrapper>
        )}
        {show && <BlockPhoneModalContainer />}
        {!show && blockModal.phone && (
          <PhoneResultModal
            text={error || 'Номер успешно заблокирован'}
            onButtonClick={this.hideUserBlockConfirmation}
          />
        )}
        <PhoneInputBlock
          phone={phone || ''}
          text="Заблокировать подменный номер"
          btnText="Убрать"
          onChange={this.onPhoneChange}
          onClick={this.handleRemovePhoneClick}
          disabled={isButtonDisabled}
        />
        <PhoneInputBlock
          phone={userPhone || ''}
          text="Отключить коллтрекинг на оригинальном номере"
          btnText="Убрать"
          onChange={this.onUserPhoneChange}
          onClick={this.toggleBlockModal}
          disabled={isUserButtonDisabled}
        />
      </React.Fragment>
    );
  }

  private toggleBlockModal = () => {
    const { userPhone } = this.state;
    this.props.toggleBlockModal({ show: true, phone: `+${userPhone}` });
  };

  private hideUserBlockConfirmation = () => {
    if (!this.props.userPhoneBlockStatus.error) {
      this.setState({ userPhone: '' });
    }
    this.props.setBlockStatus({ error: '' });
    this.props.toggleBlockModal({ show: false });
  };

  private onPhoneChange = (phone: string) => {
    this.setState({
      phone,
    });
  };

  private onUserPhoneChange = (phone: string) => {
    this.setState({
      userPhone: phone,
    });
  };

  private handleRemovePhoneClick = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  private handleResponseModalClose = () => {
    this.setState({
      isResponseModalVisible: false,
    });
  };

  private handleModalClose = () => {
    this.setState({
      isModalVisible: false,
    });
  };

  private handleRemovePhoneModalClick = () => {
    removeUserPhone(this.props.httpApi, { calltrackingPhone: `+${this.state.phone}` })
      .then(() => {
        this.setState({
          isResponseModalVisible: true,
          phone: '',
        });
      })
      .catch((errors: IServerErrorItem[]) => this.props.onError(errors));

    this.setState({
      isModalVisible: false,
    });
  };
}
