import { IAppState } from '../types';
import { TToggleRegionAction, EToggleRegionActionType } from './index';

export function toggleRegionReducer(state: IAppState, action: TToggleRegionAction): IAppState {
  switch (action.type) {
    case EToggleRegionActionType.setRegionId:
      return {
        ...state,
        toggleRegion: {
          ...state.toggleRegion,
          regionId: action.regionId,
          result: null,
        },
      };
    case EToggleRegionActionType.showResult:
      return {
        ...state,
        toggleRegion: {
          ...state.toggleRegion,
          result: action.result,
        },
      };
    default:
      return state;
  }
}
