import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IAppState } from '../redux/types';

import {
  TActions,
  IThunkExtraArgument,
  clearCalltrackingXmlStatusAction,
  ISearchFilterParams,
  searchUsersAction,
} from '../redux';
import {
  CalltrackingXmlToggledModal,
  ICalltrackingXmlToggledModalProps,
} from '../components/search_users_result_presenter/CalltrackingXmlToggledModal';
import { makePhone } from '../utils/search';
import { ECalltrackingXmlStatus } from '../repositories/calltracking-core/entities/entities/UserSchema';

export interface ICalltrackingXmlToggledModalStateProps {
  usersFilterParams: ISearchFilterParams;
  calltrackingXmlUser: string | number | null;
  calltrackingXmlStatus: ECalltrackingXmlStatus | null;
}

export interface ICalltrackingXmlToggledModalDispatchProps {
  searchUsers(params: ISearchFilterParams): void;
  clearCalltrackingXmlStatus(): void;
}

export function mapStateToProps(state: IAppState): ICalltrackingXmlToggledModalStateProps {
  const { usersFilterParams, calltrackingXmlUser, calltrackingXmlStatus } = state.search;

  return { usersFilterParams, calltrackingXmlUser, calltrackingXmlStatus };
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>,
): ICalltrackingXmlToggledModalDispatchProps {
  return {
    clearCalltrackingXmlStatus: () => dispatch(clearCalltrackingXmlStatusAction()),
    searchUsers: (params: ISearchFilterParams) => dispatch(searchUsersAction(params)),
  };
}

export function mergeProps(
  stateProps: ICalltrackingXmlToggledModalStateProps,
  dispatchProps: ICalltrackingXmlToggledModalDispatchProps,
): ICalltrackingXmlToggledModalProps {
  const { usersFilterParams, calltrackingXmlUser, calltrackingXmlStatus } = stateProps;
  const { clearCalltrackingXmlStatus, searchUsers } = dispatchProps;

  return {
    calltrackingXmlUser,
    calltrackingXmlStatus,
    onModalClose: () => {
      clearCalltrackingXmlStatus();
      searchUsers({
        userId: usersFilterParams.userId || undefined,
        offerId: usersFilterParams.offerId || undefined,
        userPhone: makePhone(usersFilterParams.userPhone),
        calltrackingPhone: makePhone(usersFilterParams.calltrackingPhone),
      });
    },
  };
}

export const CalltrackingXmlToggledModalContainer = connect<
  ICalltrackingXmlToggledModalStateProps,
  ICalltrackingXmlToggledModalDispatchProps,
  {},
  ICalltrackingXmlToggledModalProps
>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(CalltrackingXmlToggledModal);
