import { IHttpApi } from '@cian/http-api/shared/http';
import {
  fetchGetCalltrackingRegions,
  IGetCalltrackingRegionsRequest,
  IGetCalltrackingRegionsResponse,
} from '../repositories/calltracking-core/v1/get-calltracking-regions';

import { isResponseContainsKey } from '../utils/response';
import { IServerError } from './types';

export async function getRegions(httpApi: IHttpApi, parameters: IGetCalltrackingRegionsRequest) {
  try {
    let { response } = await fetchGetCalltrackingRegions({ httpApi, parameters });

    // Иногда с сервера приходит json с заголовком Content-Type: text/html
    if (typeof response === 'string') {
      try {
        response = JSON.parse(response || '[]'); // Если в ответ пришел не json, выведем ответ как ошибку
      } catch (e) {
        throw [{ code: 'notJson', key: '', message: ((response as unknown) as string).substring(0, 300) }];
      }
    }

    if (isResponseContainsKey<IServerError, IGetCalltrackingRegionsResponse>(response, 'errors')) {
      throw response.errors;
    }

    // TS 2.5.3 не учитывает проверку выше и непрваильно определяет тип
    return response as IGetCalltrackingRegionsResponse;
  } catch (error) {
    throw Array.isArray(error) ? error : [{ code: 'exception', key: '', message: error }];
  }
}
