import * as React from 'react';
import { ContentWrapper } from '../content_wrapper';
import { NumberField, Button, Label } from '@cian/components';

import * as styles from './ToggleRegionPage.css';

export interface IToggleRegionPageProps {
  regionId: number | null;
  setRegionId(regionId?: number): void;
  disableRegion(): void;
  enableRegion(): void;
  result: string | null;
}

export class ToggleRegionPage extends React.Component<IToggleRegionPageProps, {}> {
  public render() {
    const { regionId, setRegionId, disableRegion, enableRegion, result } = this.props;

    return (
      <ContentWrapper title="Выключить коллтрекинг в регионе" errors={null}>
        <Label label="ID региона:">
          <NumberField
            inputStyle={styles['region-input']}
            disablePrettyFormating
            value={regionId !== null ? regionId : undefined}
            onValueChange={setRegionId}
          />
        </Label>
        <Button buttonStyle={styles['enable-button']} disabled={regionId === null} onClick={enableRegion}>
          Включить
        </Button>
        <Button theme="button/orange" disabled={regionId === null} onClick={disableRegion}>
          Выключить
        </Button>
        {result && <div className={styles['result']}>{result}</div>}
      </ContentWrapper>
    );
  }
}
