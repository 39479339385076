import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { IPaginationItem } from '@cian/frontend-pagination-widget';

import { PhonesPage, IPhonesPageProps } from '../components/phones/PhonesPage';
import { IAppState, IThunkExtraArgument, TActions } from '../redux';
import { IFilterParams, fetchPhones, clearErrors, IPhoneSchema, changePageSize, changePage } from '../redux/phones';
import { IServerErrorItem } from '../api/types';

export interface IStateProps {
  total: number;
  phones: IPhoneSchema[];
  errors: IServerErrorItem[] | null;
  filterParams: IFilterParams;
  isFetching: boolean;
}

export interface IDispatchProps {
  fetchPhones(params: IFilterParams): void;
  changePageSize(pageSize: number): void;
  changePage(page: number): void;
  clearErrors(): void;
}

export function mapStateToProps(state: IAppState): IStateProps {
  const { total, phones, errors, filterParams, isFetching } = state.phones;

  return {
    total,
    phones,
    errors,
    filterParams,
    isFetching,
  };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>): IDispatchProps {
  return {
    fetchPhones: (params: IFilterParams) => dispatch(fetchPhones(params)),
    changePageSize: (pageSize: number) => dispatch(changePageSize(pageSize)),
    changePage: (page: number) => dispatch(changePage(page)),
    clearErrors: () => dispatch(clearErrors()),
  };
}

export function mergeProps(stateProps: IStateProps, dispatchProps: IDispatchProps): IPhonesPageProps {
  const { filterParams, total, phones, errors, isFetching } = stateProps;
  const { fetchPhones, changePageSize, changePage, clearErrors } = dispatchProps;

  return {
    total,
    filterParams,
    phones,
    errors,
    clearErrors,
    isFetching,
    onComponentDidMount: () => {
      fetchPhones(filterParams);
    },
    onPageSizeChange: (pageSize: number) => {
      if (pageSize === filterParams.pageSize) {
        return;
      }
      changePageSize(pageSize);
      fetchPhones({
        ...filterParams,
        pageSize,
      });
      window.history.pushState(
        '',
        '',
        `/phones?userId=${filterParams.userId}&pageSize=${pageSize}&page=${filterParams.page}`,
      );
    },
    onPageChange: (page: IPaginationItem) => {
      if (page.id === filterParams.page) {
        return;
      }
      changePage(page.id);
      fetchPhones({
        ...filterParams,
        page: page.id,
      });
      window.history.pushState(
        '',
        '',
        `/phones?userId=${filterParams.userId}&pageSize=${filterParams.pageSize}&page=${page.id}`,
      );
    },
  };
}

export const PhonesPageContainer = connect<IStateProps, IDispatchProps, {}, IPhonesPageProps>(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(PhonesPage);
