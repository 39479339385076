import { getConfig } from '@cian/config/browser';
import { getHttpApi } from '@cian/http-api/browser';
import { getLogger } from '@cian/logger/browser';

import { ISerializableAppState, createAppStore } from '../../../shared/redux';
import { attachReducers } from '../../../shared/redux/attach_reducers';

export function createApplicationState() {
  const config = getConfig();
  const httpApi = getHttpApi();
  const logger = getLogger();

  const initialState = config.get<ISerializableAppState>('initialState');

  const store = createAppStore(httpApi, logger, config, initialState as ISerializableAppState);

  attachReducers(store);

  return store;
}
