import { IAppState } from '../types';

import { IGetUserCallsRequest } from '../../repositories/calltracking-core/v1/get-user-calls';
import { ICallSchema } from '../../repositories/calltracking-core/entities/entities/CallSchema';

export interface ICallsPageMeta {
  count: number;
}

export interface ICallsState {
  list: ICallSchema[];
  meta: ICallsPageMeta;
  filter: IGetUserCallsRequest;
}

export interface ISetCallsAction {
  type: 'calls/SET_CALLS';
  calls: ICallSchema[];
}

export interface ISetCallsFilterAction {
  type: 'calls/SET_CALLS_FILTER';
  filter: IGetUserCallsRequest;
}

export function setCalls(calls: ICallSchema[]): ISetCallsAction {
  return {
    calls,
    type: 'calls/SET_CALLS',
  };
}

export function setCallsFilters(filter: IGetUserCallsRequest): ISetCallsFilterAction {
  return {
    filter,
    type: 'calls/SET_CALLS_FILTER',
  };
}

export function callsReducer(state: IAppState, action: ISetCallsFilterAction | ISetCallsAction) {
  switch (action.type) {
    case 'calls/SET_CALLS':
      return {
        ...state,
        calls: state.calls && {
          ...state.calls,
          list: action.calls,
        },
      };
    case 'calls/SET_CALLS_FILTER':
      return {
        ...state,
        calls: state.calls && {
          ...state.calls,
          filter: action.filter,
        },
      };

    default:
      return state || {};
  }
}
