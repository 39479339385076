import { IAppState } from '../types';
import { BLOCK_USER_PHONE, TModalsActions, UNBLOCK_USER_PHONE } from './index';

export function modalsReducer(state: IAppState, action: TModalsActions) {
  switch (action.type) {
    case BLOCK_USER_PHONE:
      return { ...state, blockModal: action.payload };
    case UNBLOCK_USER_PHONE:
      return { ...state, unblockModal: action.payload };
    default:
      return state;
  }
}
