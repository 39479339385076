import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';

import { CalltrackingUsersPage, ICalltrackingUsersPageDispatchProps, ICalltrackingUsersPageStoreProps } from '.';
import { setCalltrackingUsers, TActions } from '../../redux';
import { IAppState } from '../../redux/types';
import { IUserSchema } from '../../repositories/calltracking-core/entities/entities/UserSchema';

export function mapStateToProps(state: IAppState): ICalltrackingUsersPageStoreProps {
  const { httpApi, calltrackingUsers } = state;

  return {
    calltrackingUsers: calltrackingUsers ? calltrackingUsers.list : [],
    filter: calltrackingUsers ? calltrackingUsers.filter : {},
    httpApi,
  };
}

export function mapDispatchToProps(
  dispatch: ThunkDispatch<IAppState, object, TActions>,
): ICalltrackingUsersPageDispatchProps {
  return {
    onLoadUsers: (users: IUserSchema[]) => {
      dispatch(setCalltrackingUsers(users));
    },
  };
}

export const CalltrackingUsersPageContainer = connect<
  ICalltrackingUsersPageStoreProps,
  ICalltrackingUsersPageDispatchProps,
  {}
>(
  mapStateToProps,
  mapDispatchToProps,
)(CalltrackingUsersPage);
