import { ISetCallsAction, ISetCallsFilterAction } from './calls';
import {
  ISetCalltrackingUsersAction,
  ISetCalltrackingUsersEntitiesCountAction,
  ISetCalltrackingUsersFilterAction,
} from './calltracking_users';
import {
  ISetReplacedPhoneFilterAction,
  ISetReplacedPhoneMetaAction,
  ISetReplacedPhonesActionsAction,
} from './replaced_phones';
import { TSearchAction } from './search';
import { TPhonesAction } from './phones';
import { TToggleRegionAction } from './toggle_region';
import { TModalsActions } from './modals';
import { ISetBlockedUserPhoneDataAction } from './userPhone/types';
import { TUserPhoneActions } from './userPhone';

export * from './calls';
export * from './calltracking_users';
export * from './search';

export type TActions =
  | ISetCallsFilterAction
  | ISetCallsAction
  | ISetCalltrackingUsersAction
  | ISetCalltrackingUsersFilterAction
  | ISetCalltrackingUsersEntitiesCountAction
  | ISetReplacedPhonesActionsAction
  | ISetReplacedPhoneFilterAction
  | ISetReplacedPhoneMetaAction
  | ISetBlockedUserPhoneDataAction
  | TUserPhoneActions
  | TSearchAction
  | TPhonesAction
  | TModalsActions
  | TToggleRegionAction;
