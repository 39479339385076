import * as React from 'react';
import { PhoneBlockContainer } from './phoneBlockPageContainer';
import { PhoneUnblockContainer } from './phoneUnblockPageContainer';
import { PhoneCheckPageContainer } from './PhoneCheckPageContainer';
import { Tabs } from '../components/Tabs/Tabs';
import { ContentWrapper } from '../components/content_wrapper';
import { Tab } from '../components/Tabs/Tab';
import { IServerErrorItem } from '../api/types';

export const PhoneActionsContainer = () => {
  const [activeTabIndex, setActiveTabIndex] = React.useState(0);
  const [errors, setErrors] = React.useState<IServerErrorItem[] | null>(null);
  const tabs = [
    {
      text: 'Блокировка',
      content: <PhoneBlockContainer onError={handleErrors} />,
    },
    {
      text: 'Разблокировка',
      content: <PhoneUnblockContainer />,
    },
    {
      text: 'Проверка',
      content: <PhoneCheckPageContainer />,
    },
  ];

  function handleErrors(errors: IServerErrorItem[] | null) {
    setErrors(errors);
  }

  return (
    <React.Fragment>
      <ContentWrapper title="Блокировка/Разблокировка" errors={errors} clearErrors={() => setErrors(null)}>
        <Tabs>
          {tabs.map((tab, index) => (
            <Tab key={index} isActive={activeTabIndex === index} onClick={() => setActiveTabIndex(index)}>
              {tab.text}
            </Tab>
          ))}
        </Tabs>
        {tabs[activeTabIndex].content}
      </ContentWrapper>
    </React.Fragment>
  );
};
