import * as React from 'react';
import { PhoneInputBlock } from '../PhoneInputBlock/PhoneInputBlock';

export interface IProps {
  checkPhone(phone: string): void;
  hideUserPhoneData(): void;
  blockerData: React.ReactNode;
  errorMessage: React.ReactNode;
}

export interface IPhoneUnblockState {
  userPhone: string;
}

export class PhoneCheckPage extends React.Component<IProps, IPhoneUnblockState> {
  public constructor(props: IProps) {
    super(props);
    this.state = {
      userPhone: '',
    };
  }

  public render() {
    const { userPhone } = this.state;
    const { blockerData, errorMessage } = this.props;
    const isUserButtonDisabled = userPhone ? userPhone.length < 11 : true;

    return (
      <React.Fragment>
        <PhoneInputBlock
          phone={userPhone}
          text="Оригинальный номер"
          btnText="Проверить"
          onChange={this.onUserPhoneChange}
          onClick={this.onHandleCheckPhone}
          disabled={isUserButtonDisabled}
        />
        {errorMessage}
        {blockerData}
      </React.Fragment>
    );
  }

  private onUserPhoneChange = (phone: string) => {
    this.props.hideUserPhoneData();
    this.setState({
      userPhone: phone,
    });
  };

  private onHandleCheckPhone = () => {
    const { userPhone } = this.state;
    this.props.checkPhone('+' + userPhone);
  };
}
