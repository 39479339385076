import * as React from 'react';
import { ConfirmModal } from './ConfirmModal';
import { MultilineTextField } from '@cian/multiline-text-field-component';

export interface IConfirmModalWithInputProps {
  onConfirmClick(inputText: string): void;

  onCancelClick(): void;

  title: string;
  text: string;
  btnConfirmText: string;
  inputLabel: string;
}

interface IConfirmModalWithInputState {
  inputText: string;
}

export class ConfirmModalWithInput extends React.Component<IConfirmModalWithInputProps, IConfirmModalWithInputState> {
  public state: IConfirmModalWithInputState = { inputText: '' };

  private handleTextChange = (inputText: string) => {
    this.setState({ inputText });
  };

  private handleConfirmClick = () => {
    const { inputText } = this.state;
    this.props.onConfirmClick(inputText);
  };

  public render() {
    const { title, text, btnConfirmText, inputLabel } = this.props;
    const { inputText } = this.state;
    const isConfirmDisabled = inputText.length > 2000 || !inputText;

    return (
      <ConfirmModal
        onConfirmClick={this.handleConfirmClick}
        onCancelClick={this.props.onCancelClick}
        title={title}
        text={text}
        isConfirmDisabled={isConfirmDisabled}
        btnConfirmText={btnConfirmText}
      >
        <MultilineTextField
          minRows={5}
          maxLength={2000}
          name={inputLabel}
          value={inputText}
          onValueChange={this.handleTextChange}
        />
      </ConfirmModal>
    );
  }
}
