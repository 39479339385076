import { IResponseItemSchemaArrayWrapper } from '../../repositories/calltracking-core/entities/get_phones_link_history/ResponseItemSchemaArrayWrapper';
import { IAppState } from '../types';

export interface IReplacedPhoneHistoryFilterParams {
  calltrackingPhone: string;
  page: number;
  pageSize: number;
}

export interface IReplacedPhoneHistoryMeta {
  totalItems: number;
  totalPages: number;
}

export interface IReplacedPhonesHistoryState {
  list: IResponseItemSchemaArrayWrapper[][];
  meta: IReplacedPhoneHistoryMeta;
  filter: IReplacedPhoneHistoryFilterParams;
}

export interface ISetReplacedPhonesActionsAction {
  actions: IResponseItemSchemaArrayWrapper[][];
  type: 'replaced_phone_history/SET_REPLACED_PHONE_ACTIONS';
}

export interface ISetReplacedPhoneFilterAction {
  filter: IReplacedPhoneHistoryFilterParams;
  type: 'replaced_phone_history/SET_REPLACED_PHONE_FILTER';
}

export interface ISetReplacedPhoneMetaAction {
  meta: IReplacedPhoneHistoryMeta;
  type: 'replaced_phone_history/SET_REPLACED_PHONE_META';
}

export function setReplacedPhoneActions(actions: IResponseItemSchemaArrayWrapper[][]): ISetReplacedPhonesActionsAction {
  return {
    actions,
    type: 'replaced_phone_history/SET_REPLACED_PHONE_ACTIONS',
  };
}

export function setReplacedPhoneHistoryFilter(
  filter: IReplacedPhoneHistoryFilterParams,
): ISetReplacedPhoneFilterAction {
  return {
    filter,
    type: 'replaced_phone_history/SET_REPLACED_PHONE_FILTER',
  };
}

export function setReplacedPhoneMeta(meta: IReplacedPhoneHistoryMeta): ISetReplacedPhoneMetaAction {
  return {
    meta,
    type: 'replaced_phone_history/SET_REPLACED_PHONE_META',
  };
}

export type TReplacedPhoneHistoryActions =
  | ISetReplacedPhonesActionsAction
  | ISetReplacedPhoneFilterAction
  | ISetReplacedPhoneMetaAction;

export function replacedPhoneHistoryReducer(state: IAppState, action: TReplacedPhoneHistoryActions) {
  switch (action.type) {
    case 'replaced_phone_history/SET_REPLACED_PHONE_ACTIONS':
      return {
        ...state,
        replacedPhoneActions: state.replacedPhoneActions && {
          ...state.replacedPhoneActions,
          list: action.actions,
        },
      };
    case 'replaced_phone_history/SET_REPLACED_PHONE_FILTER':
      return {
        ...state,
        replacedPhoneActions: state.replacedPhoneActions && {
          ...state.replacedPhoneActions,
          filter: action.filter,
        },
      };
    case 'replaced_phone_history/SET_REPLACED_PHONE_META':
      return {
        ...state,
        replacedPhoneActions: state.replacedPhoneActions && {
          ...state.replacedPhoneActions,
          meta: action.meta,
        },
      };
    default:
      return state || {};
  }
}
