import { IAppState, IThunkExtraArgument, TActions } from '../redux';
import { unblockUserPhone } from '../redux/userPhone';
import { toggleUnblockModal } from '../redux/modals';
import { connect } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { ConfirmModalWithInput } from '../components/confirm_modal/ConfirmModalWithInput';

interface IStateProps {
  phone?: string;
  show: boolean;
}

interface IDispatchProps {
  onConfirmClick(reason: string, phone: string): void;

  onCancelClick(): void;
}

export function mapStateToProps(state: IAppState): IStateProps {
  const { phone, show } = state.unblockModal;

  return {
    phone,
    show,
  };
}

export function mapDispatchToProps(dispatch: ThunkDispatch<IAppState, IThunkExtraArgument, TActions>): IDispatchProps {
  return {
    onConfirmClick: (reason: string, phone: string) => dispatch(unblockUserPhone(reason, phone)),
    onCancelClick: () => dispatch(toggleUnblockModal({ show: false })),
  };
}

export function mergeProps(stateProps: IStateProps, dispatchProps: IDispatchProps) {
  const { phone } = stateProps;
  const { onConfirmClick, onCancelClick } = dispatchProps;

  return {
    title: `Вернуть номер ${phone} в коллтрекинг?`,
    text: 'Вы снимаете блокировку оригинального номера клиента. На этот номер будет выдаваться подменный номер',
    inputLabel: 'Причина',
    btnConfirmText: 'Разблокировать',
    onConfirmClick: (reason: string) => {
      if (phone) {
        onConfirmClick(reason, phone);
      }
    },
    onCancelClick,
  };
}

export const UnblockPhoneModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(ConfirmModalWithInput);
