import { IAppState } from '../types';
import { TUserPhoneActions } from './index';
import { EUserPhoneActionType } from './types';

export function userPhoneReducer(state: IAppState, action: TUserPhoneActions) {
  switch (action.type) {
    case EUserPhoneActionType.Block:
      return { ...state, userPhoneBlockStatus: action.payload };

    case EUserPhoneActionType.Unblock:
      return { ...state, userPhoneBlockStatus: action.payload };

    case EUserPhoneActionType.SetData:
      return { ...state, userPhoneData: action.payload, showUserPhoneData: true, errorMessage: '' };

    case EUserPhoneActionType.SetError:
      return { ...state, errorMessage: action.message };

    case EUserPhoneActionType.HideData:
      return { ...state, showUserPhoneData: false, errorMessage: '' };

    default:
      return state;
  }
}
