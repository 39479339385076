import * as React from 'react';

export const TargetIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <g fill="#2B87DB" fillRule="evenodd">
      <path fillRule="nonzero" d="M9 14A5 5 0 1 0 9 4a5 5 0 0 0 0 10zm0 2A7 7 0 1 1 9 2a7 7 0 0 1 0 14z" />
      <path d="M8 0h2v6H8zM8 12h2v6H8zM18 8v2h-6V8zM6 8v2H0V8z" />
    </g>
  </svg>
);
