import * as React from 'react';
import { Close } from '../close';

import { Modal, ModalWrapper } from '@cian/components';
import { IServerErrorItem } from '../../api/types';

import { TargetIcon } from './icons/target';

import * as styles from './index.css';

export interface IContentWrapperProps {
  title: string;
  showCheckButton?: boolean;
  onCheckButtonClick?(): void;
  errors: IServerErrorItem[] | null;
  clearErrors?(): void;
}

export class ContentWrapper extends React.Component<IContentWrapperProps> {
  public render() {
    const { title, children, showCheckButton, onCheckButtonClick, errors } = this.props;

    const closeBtn = (
      <div onClick={this.onCloseClick}>
        <Close />
      </div>
    );

    return (
      <div className={styles['content-wrapper']}>
        {errors && (
          <ModalWrapper onClose={this.onCloseClick}>
            <Modal closeBtn={closeBtn} minHeight={150} minWidth={400}>
              <div className={styles['modal-header']}>{this.getErrorMessage(errors)}</div>
            </Modal>
          </ModalWrapper>
        )}
        <div className={styles['title']}>
          <h1>{title}</h1>
          {showCheckButton && (
            <div className={styles['target']} title="Проверить у оператора" onClick={onCheckButtonClick}>
              {TargetIcon()}
            </div>
          )}
        </div>

        <div className={styles['content']}>{children}</div>
      </div>
    );
  }

  private getErrorMessage = (errors: IServerErrorItem[]): string => {
    const messages: { [key: string]: string } = {
      accessDenied: 'Нет доступа',
      authenticationRequired: 'Требуется авторизация на service.cian.ru',
      exception: 'Произошла ошибка, попробуйте повторить снова', // Если произошел exception во время http запроса
      notJson: 'Некорректный ответа сервера',
      required: 'Отсутствует значение для обязательного поля',
    };

    return errors.map(item => messages[item.code] || item.message || 'Неизвестная ошибка').join('\n');
  };

  private onCloseClick = () => {
    const { clearErrors } = this.props;

    if (clearErrors) {
      clearErrors();
    }
  };
}
